import React, { Component } from 'react'
import Card from '../../components/Card'
import Renderer from '../../components/Renderer'
import IndividualView from '../../views/IndividualView'
import { cvToHex, hexToCV, uintCV, UIntCV, ClarityValue, cvToJSON } from "@stacks/transactions";

import makeEndpoint from '../../endpoints'

type IndividualNftProps = {
    nft: string
}

type IndividualNftState = {
    raw: Array<any>
    data: Record<string, any>
    owner: string
}

class IndividualNft extends Component<IndividualNftProps, IndividualNftState> {
    constructor(props: IndividualNftProps) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.fetchNft = this.fetchNft.bind(this);
    }
    state = {
        raw: [],
        data: {},
        owner: "loading..."
    }

    componentDidMount() {
        try {
          // console.log(localStorage.getItem('userData'));
        } catch {}
    
        this.fetchNft();
    }

    fetchData(nft: any) {
        var params = '';
        params += 'address=' + nft.contractAddress;
        params += '&name=' + nft.contractName;
        params += '&id=' + nft.tokenID;
        const nftId = nft.contractAddress + '.' + nft.contractName + ':' + nft.tokenID;
        fetch(makeEndpoint('/api/nft?' + params))
        .then(res => res.json())
        .then((data) => {
            if (data.name) {
            const fullData: Record<string, any> = this.state.data;
            fullData[nftId] = data;
            this.setState({ 'data': fullData });
            }
        })
    }

    fetchNftOwner(nft: any) {
        if(nft) {
            const contract_address = nft.contractAddress;
            const contract_name = nft.contractName;
            const baseUri = "https://stacks-node-api.mainnet.stacks.co/v2/contracts/call-read/"
            const function_name = "get-owner"
            const uri = baseUri + contract_address + "/" + contract_name + "/" + function_name;

            const nftIndex: UIntCV = uintCV(nft.tokenID);
            let args: Array<string> = [cvToHex(nftIndex)];
            
            const options = {
                method: "POST",
                body: JSON.stringify({
                    arguments: args,
                    sender: nft.contractAddress
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
                
            }

            fetch(uri, options).then(res => res.json())
            .then((owner: any) => {
                if(owner) {
                    const ownerResult: string = owner.result

                    // serialize hex string to clarity value
                    let ownerResultCV: ClarityValue = hexToCV(ownerResult);

                    // CV to JSON
                    let ownerResultJson: any = cvToJSON(ownerResultCV);

                    this.setState({
                        'owner': ownerResultJson.value.value.value
                    })
                }
            })

        }
    }

    fetchNft() {
        fetch(makeEndpoint('/api/balance?user=' + this.props.nft))
        .then(res => res.json())
        .then((nft) => {
            if(nft){
                this.setState({
                    'raw': nft
                })     
                this.fetchData(nft[0]);
                this.fetchNftOwner(nft[0])
            }
            
        })
    }

  

    render() {

        return (
            <div>
                <IndividualView
                    raw={this.state.raw}
                    data={this.state.data}
                    owner={this.state.owner}
                />
            </div>
        )
    }
}

export default IndividualNft;
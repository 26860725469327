import React, { Component } from 'react'
import styles from './index.module.scss'

import Back from './Back'
import Front from './Front'

type CardProps = {
  attributes?: any
  buy?: number
  bid?: number
  sold?: number
  rarityRank?: number
  bidFunction?: (...args: any[]) => void
  buyFunction?: (...args: any[]) => void
  sendFunction?: (...args: any[]) => void
  sellFunction?: (...args: any[]) => void
  unlistFunction?: (...args: any[]) => void
  bidStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  buyStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  sellStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  sendStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  badges?: Array<'VERIFIED' | 'ERROR' | 'FEATURED'>
  link?: (...args: any[]) => void
  profile?: (...args: any[]) => void
  collection?: string
  name?: string
  renderer?: any
  soldFlag?: boolean
  currency?: 'STX' | 'MIA' | 'BTC'
}

type CardState = {
  flipped: boolean
  showOne: boolean
  showTwo: boolean
}

class Card extends Component<CardProps, CardState> {
  state = {
    flipped: false,
    showOne: true,
    showTwo: false
  }

  changeFlipped()  {
    if (!this.state.flipped) {
      this.setState({
        flipped: !this.state.flipped,
        showOne: true,
        showTwo: true
      })

      setTimeout(() => {
        if (this.state.flipped) {
          this.setState({
            showOne: false
          })
        } else {
          this.setState({
            showTwo: false
          })
        }
      }, 250)
    } else {
      this.setState({
        flipped: !this.state.flipped,
        showOne: false,
        showTwo: true
      })

      setTimeout(() => {
        if (this.state.flipped) {
          this.setState({
            showOne: false,
            showTwo: true
          })
        } else {
          this.setState({
            showOne: true,
            showTwo: false
          })
        }
      }, 250)
    }
  }

  returnClass()  {
    if (this.state.flipped) {
      return `${styles.flip_box_inner} ${styles.flip_box_inner_flipped}`
    } else {
      return `${styles.flip_box_inner}`
    }
  }

  render() {
    return (
      <div className={styles.flip_box}>
        <div className={this.returnClass()}>
          {this.state.showOne &&
            <div
              className={styles.flip_box_front}>
              <Front
                collection={this.props.collection}
                name={this.props.name}
                renderer={this.props.renderer}
                attributes={this.props.attributes}
                badges={this.props.badges}
                bid={this.props.bid}
                bidFunction={this.props.bidFunction}
                buy={this.props.buy}
                buyFunction={this.props.buyFunction}
                link={this.props.link}
                profile={this.props.profile}
                sendFunction={this.props.sendFunction}
                sellFunction={this.props.sellFunction}
                unlistFunction={this.props.unlistFunction}
                bidStyle={this.props.bidStyle}
                buyStyle={this.props.buyStyle}
                sellStyle={this.props.sellStyle}
                sendStyle={this.props.sendStyle}
                attributesAction={this.changeFlipped.bind(this)}
                sold={this.props.sold}
                soldFlag={this.props.soldFlag}
                currency={this.props.currency} />
            </div>
          }
          {this.state.showTwo &&
            <div
              className={styles.flip_box_back}>
              <Back
                sendFunction={this.props.sendFunction}
                sellFunction={this.props.sellFunction}
                rarityRank={this.props.rarityRank}
                attributes={this.props.attributes}
                bidStyle={this.props.bidStyle}
                buy={this.props.buy}
                buyStyle={this.props.buyStyle}
                sellStyle={this.props.sellStyle}
                sendStyle={this.props.sendStyle}
                backAction={this.changeFlipped.bind(this)}
                link={this.props.link}
                sold={this.props.sold} />
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Card;

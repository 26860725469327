import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../../../theme';

type TraitProps = {
  type: string
  collection: string
  rarity?: number
}
type TraitState = {}

class Trait extends Component<TraitProps, TraitState> {
  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <div className={styles.Trait}>
        <div className={styles.TraitTitle}>
          {this.props.type}
        </div>
        <div className={styles.TraitTop}>
          <div className={styles.TraitText}>
            {this.props.collection}
          </div>
          { this.props.rarity ? (
          <div className={styles.TraitRarity}>
            {(this.props.rarity * 100).toFixed(2)}%
          </div>
          ) : '' }
        </div>
        <div className={styles.TraitSpacer}>
        </div>
      </div>
    )
  }
}

export default Trait;

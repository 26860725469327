import React, {Component} from 'react';
import styles from './App.module.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import PageWrapper from './components/PageWrapper'

import Collections from './pages/Collections'
import CreatePage from './pages/CreatePage'
import HomePage from './pages/HomePage'
import Marketplace from './pages/Marketplace'
import MiaSubmit from './pages/MiaSubmit'
import Profile from './pages/Profile'
import IndividualNft from './pages/IndividualNft'

import MemesMovementsMarkets from './pages/MemesMovementsMarkets'
import Badgers from './pages/Badgers'
import Monkeys from './pages/Monkeys'
import Degens from './pages/Degens'
import Stacculents from './pages/Stacculents'
import Arties from './pages/Arties'
import CityPacks from './pages/CityPacks'
import MadPandas from './pages/MadPandas'
import Theopetra from './pages/Theopetra'
import StacksPandas from './pages/StacksPandas'
import Bubo from './pages/Bubo'
import WgMiami2021 from './pages/WgMiami2021'
import CityCollectibles from './pages/CityCollectibles'
import Gallery from './pages/Gallery'
import MagicApe from './pages/MagicApe'
import Roots from './pages/Roots'

import ErrorPage from './pages/ErrorPage'

import ColorPalette from './throaway/ColorPalette'

import { darkmodeListener } from './theme';

type AppProps = {}
type AppState = {
  stats?: any
}

class App extends Component<AppProps, AppState> {
  state = {
    stats: undefined
  }

  setStats(stats: any) {
    this.setState({
      stats: stats
    })
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route
            exact path="/"
            render={(props) =>
              <PageWrapper
                {...props}>
                <HomePage />
              </PageWrapper>
            } />
          <Route
            exact path="/colors"
            render={(props) =>
              <PageWrapper
                {...props}>
                <ColorPalette />
              </PageWrapper>
            } />
          <Route path='/submit' component={() => { 
               window.location.href = 'https://blocksurvey.io/survey/12njyDy2XzzqEyzqgPpMpydNHyNLSG4BoT/973251dd-ea00-4ff4-b4fc-74d911436a0a/r/l'; 
               return null;
          }}/>
          <Route path='/newsletter' component={() => { 
               window.location.href = 'https://www.getrevue.co/profile/stxnft'; 
               return null;
          }}/>
          <Route path='/mia/submit' component={() => { 
               window.location.href = 'https://blocksurvey.io/survey/12njyDy2XzzqEyzqgPpMpydNHyNLSG4BoT/973251dd-ea00-4ff4-b4fc-74d911436a0a/r/l'; 
               return null;
          }}/>
          <Route
            exact path="/marketplace"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Marketplace />
              </PageWrapper>
            } />
          <Route
            exact path="/marketplace/:collection"
            render={(props) =>
              <PageWrapper
                stats={this.state.stats}
                {...props}>
                <Marketplace
                  collection={props.match.params.collection}
                  setStats={this.setStats.bind(this)}/>
              </PageWrapper>
            } />
          <Route
            path="/artist/:name"
            render={(props) =>
              <PageWrapper
                {...props}>
              </PageWrapper>
            } />
          <Route
            path="/collection/Arties"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Arties name={"Arties"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/roots"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Roots name={"roots2"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/nyc-degens"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Degens name={"nyc-degens"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/mad-pandas"
            render={(props) =>
              <PageWrapper
                {...props}>
                <MadPandas name={"mad-pandas"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/bitcoin-whales"
            render={(props) =>
              <PageWrapper
                {...props}>
                <MadPandas name={"bitcoin-whales"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/bitcoin-monkeys"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Monkeys name={"bitcoin-monkeys"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/magic-ape-school"
            render={(props) =>
              <PageWrapper
                {...props}>
                <MagicApe name={"magic-ape-school"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/badgers"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Badgers name={"badgers"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/memes-movements-markets"
            render={(props) =>
              <PageWrapper
                {...props}>
                <MemesMovementsMarkets name={"memes-movements-markets"}/>
              </PageWrapper>
            }/>
          <Route
            path="/collection/pandas"
            render={(props) =>
              <PageWrapper
                {...props}>
                <StacksPandas name={"pandas"}/>
              </PageWrapper>
            }/>
          <Route
            path="/collection/citypacks"
            render={(props) =>
              <PageWrapper
                {...props}>
                <CityPacks name={"citypacks"}/>
              </PageWrapper>
            }/>
          <Route
            path="/collection/city-mia"
            render={(props) =>
              <PageWrapper
                {...props}>
                <CityCollectibles name={"city-mia"}/>
              </PageWrapper>
            }/>
          <Route
            path="/collection/city-nyc"
            render={(props) =>
              <PageWrapper
                {...props}>
                <CityCollectibles name={"city-nyc"}/>
              </PageWrapper>
            }/>
          <Route
            path="/collection/305"
            render={(props) =>
              <PageWrapper
                {...props}>
                <CityCollectibles name={"miami-305"}/>
              </PageWrapper>
            }/>
          <Route
            path="/collection/Stacculents"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Stacculents name={"Stacculents"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/bubo"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Bubo name={"bubo"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/theopetra-pawn"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Theopetra name={"theopetra-pawn"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/theopetra-knight"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Theopetra name={"theopetra-knight"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/theopetra-rook"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Theopetra name={"theopetra-rook"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/theopetra-king"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Theopetra name={"theopetra-king"}/>
              </PageWrapper>
            } />
          <Route
            path="/collection/miamicoinartweek"
            render={(props) =>
              <PageWrapper
                {...props}>
                <Gallery name={"miamicoinartweek"} />
              </PageWrapper>
            } />
          <Route
            path="/collection/:name"
            render={(props) => 
              <PageWrapper
                {...props}>
                <Collections name={props.match.params.name} />
              </PageWrapper>
            } />
          <Route
            path="/nft/:id"
            render={(props) =>
              <PageWrapper
                {...props}>
                <IndividualNft nft={props.match.params.id}/>
              </PageWrapper>
            } />
          <Route
            path="/:user"
            render={(props) => 
              <PageWrapper
                {...props}>
                <Profile user={props.match.params.user}/>
              </PageWrapper>
            } />
          <Route
            path="*"
            render={(props) =>
              <PageWrapper
                {...props}>
                <ErrorPage />
              </PageWrapper>
            }  />
        </Switch>
      </Router>
    );
  }
}

export default App;

// {props.match.params.user}

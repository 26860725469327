import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

import Renderer from '../Renderer'
import Button from '../Button'

type ListProps = {
  onClose?: (...args: any[]) => void
  image?: string
  imageClasses: string
  collection: string
  name: string
  price: string
  error: string
  royalty?: number
  changePrice: (...args: any[]) => void
  onList: (...args: any[]) => void
}
type ListState = {
  value?: number
}

class List extends Component<ListProps, ListState> {
  state = {
    value: 0,
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  changePrice(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value.replace(',', '')
    this.setState({ value: parseFloat(value) });
    this.props.changePrice(value);
  }

  inputCSS() {
    if (this.props.error != "") {
      return `${styles.ListInput} ${styles.ListInputError}`
    } else {
      return `${styles.ListInput}`
    }
  }

  iconCSS() {
    if (this.props.error != "") {
      return `${styles.ListInputIcon} ${styles.ListInputIconError}`
    } else {
      return `${styles.ListInputIcon}`
    }
  }

  render() {
    return (
      <div className={styles.List}>
        <div
          className={styles.ListClose}
          onClick={this.onClose.bind(this)}>
          <svg width={"14px"} height={"14px"} viewBox={"0 0 14 14"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
            <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
              <g transform={"translate(-329.000000, -1101.000000)"} strokeWidth={"2"}>
                <g transform={"translate(41.000000, 1094.000000)"}>
                  <g transform={"translate(289.000000, 8.000000)"}>
                    <line x1={"12"} y1={"0"} x2={"0"} y2={"12"}></line>
                    <line x1={"0"} y1={"0"} x2={"12"} y2={"12"}></line>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={styles.ListImage}>
          <Renderer src={this.props.image} imageClasses={this.props.imageClasses}/>
        </div>
        <div className={styles.ListCollection}>
          {this.props.collection}
        </div>
        <div className={styles.ListDescription}>
          {this.props.name}
        </div>
        <div className={styles.ListHighlightContainter}>
          {this.props.royalty ? (
            <div>
              🎨 <span className={styles.ListHighlight}>{(this.props.royalty /100.0).toFixed(1)}%</span> <span className={styles.ListHelpers}>royalty to the artist</span>
            </div>) : ''
          }
          <div>
            ❤️ <span className={styles.ListHighlight}>1.0%</span> <span className={styles.ListHelpers}>keeps the</span> 💡 <span className={styles.ListHelpers}>on</span>
          </div>
        </div>
        {this.props.error != "" &&
          <div className={styles.ListError}>
            { this.props.error }
          </div>
        }
        <div className={styles.ListInputContainer}>
          <div className={this.iconCSS()}>
            <svg width={"14px"} height={"14px"} viewBox={"0 0 14 14"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
              <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
                <g transform={"translate(-65.000000, -483.000000)"}>
                  <g transform={"translate(41.000000, 18.000000)"}>
                    <g transform={"translate(0.000000, 14.000000)"}>
                      <g transform={"translate(11.000000, 437.000000)"}>
                        <g transform={"translate(14.000000, 15.000000)"}>
                          <g transform={"translate(6.000000, 9.500000) scale(-1, 1) rotate(-180.000000) translate(-6.000000, -9.500000) translate(0.000000, 7.000000)"}>
                            <line x1={"6.70588257"} y1={"4.56521739"} x2={"10.6764709"} y2={"4.56521739"} strokeLinecap={"round"}></line>
                            <line x1={"0"} y1={"4.56521739"} x2={"12"} y2={"4.56521739"}></line>
                            <line x1={"1.41176475"} y1={"0"} x2={"4.72058839"} y2={"4.56521739"} strokeLinejoin={"round"}></line>
                            <line x1={"6.70588257"} y1={"0"} x2={"10.0147062"} y2={"4.56521739"} strokeLinejoin={"round"} transform={"translate(8.360294, 2.282609) scale(-1, 1) translate(-8.360294, -2.282609) "}></line>
                          </g>
                          <g transform={"translate(6.000000, 2.500000) scale(-1, -1) rotate(-180.000000) translate(-6.000000, -2.500000) "}>
                            <line x1={"6.70588257"} y1={"4.56521739"} x2={"10.6764709"} y2={"4.56521739"} strokeLinecap={"round"}></line>
                            <line x1={"0"} y1={"4.56521739"} x2={"12"} y2={"4.56521739"}></line>
                            <line x1={"1.41176475"} y1={"0"} x2={"4.72058839"} y2={"4.56521739"} strokeLinejoin={"round"}></line>
                            <line x1={"6.70588257"} y1={"0"} x2={"10.0147062"} y2={"4.56521739"} strokeLinejoin={"round"} transform={"translate(8.360294, 2.282609) scale(-1, 1) translate(-8.360294, -2.282609) "}></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <input
            type={"text"}
            className={this.inputCSS()}
            placeholder={"Price"}
            value={this.props.price}
            onChange={this.changePrice.bind(this)}>
          </input>
        </div>
        <div style={{'color': '#fff', 'margin': 'auto', 'padding': '10px', fontSize: '10pt'}}>
        { this.props.collection && this.props.collection.includes('Indigo') && this.state && this.state.value ? 
            `You get ${this.state.value.toFixed(2)} STX. Buyer will pay ${(this.state.value * 1.06).toFixed(2)} STX.` : ''
        }
        </div>
        <div className={styles.ListButton}>
          <Button
            text={"LIST"}
            onClick={this.props.onList.bind(this)}/>
        </div>
      </div>
    )
  }
}

export default List

import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../../../theme';

import Badge from '../Badge'

type DetailsProps = {
  attributes?: any
  attributesAction: (...args: any[]) => void
  badges: Array<'VERIFIED' | 'ERROR' | 'FEATURED'>
  collection: string
  name: string
}

type DetailsState = {}

class Details extends Component<DetailsProps, DetailsState> {
  static defaultProps = {
    badges: [],
    collection: "",
    name: "",
    attributesAction: () => {}
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <div className={styles.Details}>
        <div className={styles.NFTInfo}>
          <div className={styles.TopLevel}>
            <div
              className={styles.Collection}
              title={this.props.collection}>
              {this.props.collection}
            </div>
            <div className={styles.BadgeContainer}>
              { this.props.badges.map((x) => <Badge type={x} />) }
            </div>
          </div>
          <div
            className={styles.Name}
            title={this.props.name}>
            {this.props.name}
          </div>
        </div>
        {this.props.attributes &&
          <div
            className={styles.Attributes}
            onClick={this.props.attributesAction}>
            <svg width={"21px"} height={"15px"} viewBox={"0 0 21 15"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
              <g strokeWidth={"1"} fill={"none"} fill-rule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                <g transform={"translate(-304.000000, -1324.000000)"} strokeWidth={"2"}>
                  <g transform={"translate(66.000000, 1297.000000)"}>
                    <g transform={"translate(239.000000, 28.000000)"}>
                      <line x1={"5.495"} y1={"0.5"} x2={"18.495"} y2={"0.5"}></line>
                      <line x1={"5.495"} y1={"6.5"} x2={"18.495"} y2={"6.5"}></line>
                      <line x1={"5.495"} y1={"12.5"} x2={"18.495"} y2={"12.5"}></line>
                      <line x1={"0.495"} y1={"0.5"} x2={"0.505"} y2={"0.5"}></line>
                      <line x1={"0.495"} y1={"6.5"} x2={"0.505"} y2={"6.5"}></line>
                      <line x1={"0.495"} y1={"12.5"} x2={"0.505"} y2={"12.5"}></line>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        }
      </div>
    )
  }
}

export default Details;
import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

type MenuItemProps = {
  type: 'marketplace' | 'profile' | 'logout' | 'filter'
  onClick: (...args: any[]) => void
}
type MenuItemState = {}

class MenuItem extends Component<MenuItemProps, MenuItemState> {
  static defaultProps = {
    onClick: () => {}
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <div
        className={styles.MenuItem}
        onClick={this.props.onClick}>
        { this.props.type == 'marketplace' &&
          <svg width={"20px"} height={"22px"} viewBox={"0 0 20 22"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
            <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
              <g transform={"translate(-749.000000, -1304.000000)"} strokeWidth={"2"}>
                <g transform={"translate(750.000000, 1305.000000)"}>
                  <path d={"M3,0 L0,4 L0,18 C0,19.1045695 0.8954305,20 2,20 L16,20 C17.1045695,20 18,19.1045695 18,18 L18,4 L15,0 L3,0 Z"}></path>
                  <line x1={"0"} y1={"4"} x2={"18"} y2={"4"}></line>
                  <path d={"M13,8 C13,10.209139 11.209139,12 9,12 C6.790861,12 5,10.209139 5,8"}></path>
                </g>
              </g>
            </g>
          </svg>
        }
        { this.props.type == 'profile' &&
          <svg width={"18px"} height={"19px"} viewBox={"0 0 18 19"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
            <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
              <g transform={"translate(-799.000000, -1304.000000)"} strokeWidth={"2"}>
                <g transform={"translate(800.000000, 1305.000000)"}>
                  <path d={"M16,18 L16,16 C16,13.790861 14.209139,12 12,12 L4,12 C1.790861,12 0,13.790861 0,16 L0,18"}></path>
                  <circle cx={"8"} cy={"4"} r={"4"}></circle>
                </g>
              </g>
            </g>
          </svg>
        }
        { this.props.type == 'logout' &&
          <svg width={"21px"} height={"20px"} viewBox={"0 0 21 20"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
            <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
              <g transform={"translate(-988.000000, -1311.000000)"} strokeWidth={"2"}>
                <g transform={"translate(989.000000, 1312.000000)"}>
                  <path d={"M6,18 L2,18 C0.8954305,18 0,17.1045695 0,16 L0,2 C0,0.8954305 0.8954305,0 2,0 L6,0"}></path>
                  <polyline points={"13 14 18 9 13 4"}></polyline>
                  <line x1={"18"} y1={"9"} x2={"6"} y2={"9"}></line>
                </g>
              </g>
            </g>
          </svg>
        }
        {this.props.type == 'filter' &&
          <svg width={"22px"} height={"20px"} viewBox={"0 0 22 20"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
            <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
              <g transform={"translate(-819.000000, -1045.000000)"} strokeWidth={"2"}>
                <g transform={"translate(820.000000, 1046.000000)"}>
                  <polygon points={"20 0 0 0 8 9.46 8 16 12 18 12 9.46"}></polygon>
                </g>
              </g>
            </g>
          </svg>
        }
      </div>
    )
  }
}

export default MenuItem;
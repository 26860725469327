import React, { Component } from 'react'
import { Helmet } from 'react-helmet' 
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

import makeEndpoint from '../../endpoints'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Loading from '../../components/Loading'
import Renderer from '../../components/Renderer'

import icon from '../../components/Login/assets/icon.svg'

import {
  AppConfig,
  UserSession,
  showConnect,
  openContractCall
} from '@stacks/connect';

import {
  uintCV,
  intCV,
  bufferCV,
  stringAsciiCV,
  stringUtf8CV,
  standardPrincipalCV,
  trueCV,
  makeStandardFungiblePostCondition,
  FungibleConditionCode,
  createAssetInfo,
  makeStandardNonFungiblePostCondition,
  NonFungibleConditionCode,
  makeStandardSTXPostCondition
} from '@stacks/transactions';

import { StacksTestnet, StacksMainnet } from '@stacks/network';

import BigNum from 'bn.js'

import InfiniteScroll from 'react-infinite-scroll-component';

import { renderer } from '../../utils'

const testnet = new StacksTestnet();
const mainnet = new StacksMainnet();

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

type ArtiesProps = {
  name: string
}

type ArtiesState = {
  name: string
  contractAddress: string
  contractName: string
  description: string
  image: string
  total: number
  lastTokenID: number
  prices: number
  currency?: string
  saleData: Record<string, any>
  sale: Array<any>
  active: boolean
  paused: boolean
  userData?: any
  offset: number
}

const enable_whitelist = false;

const whitelist = [
'SPS6543QSVCWM0B1CQYD67RV4QP3MGFPJEHG4FHS',
  'SP1JPSTPZJK2Y0CR1FYN64DZ1PZZ4QVZV05TEP9VN',
  'SP1VR6N7CQMZ79Y13426VQXA5BWBGKDD3R24D63BJ',
  'SP16F2TEPS897QKWB89YMZBQFJDVP2VRBD146X9E7',
  'SP3PJRGTNZCVWH1DXK495QJNE361Z0PS5MKRP0A7J',
  'SP3VRHD32DN8CHPQV4WYYSMGG8MK406E02DS510N7',
  'SP226P4V1G7T6KVD39VMAGQV6HXKDM97T009AV579',
  'SP2GT14E9B4DAK5ND7T34J92NP5ET2FR77QCR1G5Z',
  'SP2P8QYQX5PMKVBXQ9FWK8F96J8DJXXW7NB7AA5DT',
  'SPE0N1TCKXX2YSPT042H3X3TR1KV76Y67P22C342',
  'SPBDCE0KCWY56SX6XE7MB6DVVETABYM2WRDK6PCB',
  'SP1N0A4BJYXZE2E4ZWM1MHZ67X3BG53RD51AKYFHC',
  'SP2KJC2WBNJPGVRB0GG30666PQW96MAKH7YWGPWB0',
  'SP82AJ54GGQVERK5YSBATJSZJ4CAYH940C7AVDR5',
  'SP2HY2VCHF05JXD95NF9SMX3PGTKXX7YC6TK20V3M',
  'SP36NC0KX6RZGPQXR73AMW8R0CXXHS06DRM487A5G',
  'SP3JEYJSQP0B8NXNZ6YJH5XJ9YHKXWF32X0BKN3XT',
  'SP3M7C7A18NAN167GAAVZE5WJBQ89Y1JA5SGF3HSF',
  'SP133S2CNGC1Y7G4VNEHWTS6GK6X9W9D7W61GKRP5',
  'SP3SQA5VN2ZQFH1C961HJ2NB5V627JNHXMMDPTXZJ',
  'SP21ZAV7D3T6P6KTB3V04G8VNSHZ3RTRG2PEJWNBE',
  'SP1EPGJCPYTKX9W7YHT010J5HMVWG0N8K627PD77',
  'SP3E4TWXM4CNGTQ7C7ZG7S5Z3WQHBSMH2QG1HP893',
  'SP3GEHTB65R4N61WW2XR6CHBZFYNAY5JRVYJY3JZ3',
  'SP2MCPA9RVQ6K9HZA3T4R1X9C1ECJVJMQ97GPDFW1',
  'SP9M7RXPPQ1MZSDQR2NXD4SB28VFSXQX7XZYPXT8',
  'SP3E4TWXM4CNGTQ7C7ZG7S5Z3WQHBSMH2QG1HP893',
  'SP1YDME6WQB99ZY59X2400484C9EY3380Y2Z2PFPV',
  'SP2K2KR330QQ1X21T4R19HD2RFEZZEXSV7GZZ8M6R',
  'SP1TDMMAXQH9TP2GREPRA9TX78DK7DKMX8R26VD4N',
  'SP3HPM0R83TNX684F6C1V7HH11W7D6BYCX0PRF6AS',
  'SP2JAGQ4EKSNKSJK866RXXVQ73194QK85MKBPKHQ3',
  'SP1XBGQZ7HNZ977XNJZCHWW34E0HZCC9D6BYE51TA',
  'SP37D403MX88VNSPKJTKJBN9EA9ZHPZRJ0Q9FJ8HJ',
  'SP2K28R39SDM15KJ1X4ZV0F63FQ0QTESVS77BM67D',
  'SPTJZDT173AM6ZNAMTBH1G9WTR0XG898848Y6NQZ',
  'SPTJZDT173AM6ZNAMTBH1G9WTR0XG898848Y6NQZ',
  'SP38QR25D6NK1NNFTFBK0X6WN18AJDZ7Z7VM3FJ4C',
  'SP2F7WBT3VDTYC31JWGVTGH9ASSQ1PZQ0RPCSS5YB',
  'SP1AMKJCADYRZ2ZWD667TKC899XCGXGW1FYZRDK5C',
  'SP3W3MGYG0HR7FY2DGX24K1JSCQECVGYS9CVNVTXZ',
  'SP32MJRPH66ZYFTSM8WJG364WRN081233RMHQY3XM',
  '952595048722532000000000000000000000000000000000',
  'SP2JWXVBMB0DW53KC1PJ80VC7T6N2ZQDBGCDJDMNR',
  '1092359849235330000000000000000000000000000000000',
  'SPAN8AGWA5G5FKNFAB5QH23TMTREH19P74WRDKGQ',
  'SPEGQQKR93GA66MEZYNW3KWDSNVE7AH6DSEGFXWK',
  'SP3XM195Y8G4YTMQ538Y5KD567TRW8H60EMZNYP5Y',
  'SP2N23S756ZW7GQNJ6PPWHKHZ27FE97NT6YW90ZVF',
  'SP3MNK2B5BTV6AW8BMVWS6ASVH4PQANZNKAMF52QA',
  'SP3E85KR755KMAN97FZYTR5D96YBJ9YJBS5M822XR',
  'SP3QDY33H0W87TDW1HZ3C2XVX960B6W53Z3Y6EJN2',
  'SPE1FTGZ46YP89QSVH4HYV1N8Y9131G15XMBYHAT',
  'SP35CT6Y9P23WNVFDRKD09XK66N2WPRSJ8V7AMJ79',
  'SP2BEMANFQAB59RXRZF54B4XZ0NDMBB3HGMD9SSC',
  'SP34A1JYEEYFPWDJJ5CB37N2K21VMZPNHYJ9GJKZW',
  'SP3KX1WC24RHQAD89HQKXJ060P2VEAVTK3K5FQC0X',
  'SP38V0Y8YSPDRSR6WWY33JJP868YM7XXXXZ0VNJTF',
  'SPZX0Y3HP9XZYH8FCXPF38RAFNAZZ9X7MKFYXR7J',
  '4610012062132730000000000000000000000000000000',
  'SP3HY1SA35VY36HHG5F2XKBX9Y97071J08JYCZJ5W',
  'SP3KW1JVCDNEVKN3JR4TGSCS9FW1T403WAH8BFNWN',
  'SP13Q7KW3MEYMHAE1DD9FYR3Q530N1317THHW5M47',
  'SP1EVAE7GZHDPRVEXEAJP15FMXRYK6J5Q2HKZVSFZ',
  'SP3GN4PPAF2682EMEHSBJQCAK3G2M5F7RAMKN5B9M',
  'SP2YSXDXE1E450S61V4VSZMZES3QNRASDH83P9D0D',
  'SPY2GY84SM7G458C58VEYXS26QXTKNKT97SP70AJ',
  'SP1ND4NNZWFZ10WN2B5N4XTDKT1D5J36DTWVQREG9',
  'SP3RQM0SAKX55S3RBTB0ZVTC9QAA5R79E1MFX7GMS',
  'SP1STGN6V7V2X273WCS37PFNVKHAFZYJS9CBAP6YV',
  'SP2Z9GZBYNZNFH0D09CPGY134593SFET5BC0ZFFM4',
  'SP7NHV7JG5H61HD6RHM1E82X1P2AXXXB1PGCXR4C',
  'SP2NHZDAMMEEASE4DKHYYCVAG8RF8PA7YHPPW40BX',
  'SP2YGEKG91929GDYGVQK1QK2JFC0NHDVXTZR3SPTX',
  'SP1T4VRJX5GWG4F0PYP0TNYZRCNPEVRBTAWGQQ99',
  'SP2W0KJMJB2601KK53Y7F8W9FV5YJ1QVCT0GBJHTA',
  'SP1048V27R6H3CEZ2CZJR9J5E34EZ0G9NX6K3MQGV',
  'SP1KP46QGXB55V6WVKF30GD76SXE8YY4R49MFJJ68',
  'SP39GKMC14QK2FFAVQMT4N89GG54376ES0YVP9P98',
  'SP3B8W1S6YE0Y46VSSF1MFEVCATPN9HHQ9VPVSBD8',
  'SP142BS3DJK11EZMV1TG47MK9MG3KBY0405A3QD7R',
  'SP274C9SKTRQV06W86GVVC0MGSNJ1EMXYK8E46PXT',
  'SP1BJNHYNBYE0R2NSHT54YX7GF73FWB8BP8HWEYC4',
  'SP2X3XRZ3C655E0KQZ6MP8S6C3JGWNNCWJYC9R3VS',
  'SP1GG400F4DPSYMKCG1C16E9C7EJ2CY3G3S002DS7',
  'SP221ZDPGR1B9A89NBB6MJDRXE6F7NHXM3D9TCNXJ',
  'SPMMT35W4HQ47812YBV2E65QYJV0Q43JHCG4X2QT',
  'SP2BQRKZC33JYP3V5B86G744A4HEBWHCAA79N5CNX',
  'SP3RAR4KAWWDW545QCZT678Y5TEHE2B1RY1FF7DX2',
  'SP1N4WQBQQCDGFXVXKWS6WEBZBE1J1WX0WXZHMN0T',
  'SPXQS1T1T2BKGSHH8H75PVFEY0R1X39F0B3MQWTJ',
  'SP3EZE7K5GMZX6XNB1FEX9D0PW5VVHP3C60CHAQGS',
  'SP346H4Y8J4MG3E4RMCWT3DJTQ391336Y000SH1V3',
  'SP3DG6MHN5ZRT9CW67T0QP2MRD8PMWPT5XZFJDVKB',
  'SP2T7YVBAJHDKR9J92PTFVY2E1PRGC969CW3HTQQQ',
  'SPNPH2CGMKTMD5BGM12067DZMXFE7NCMVB2BNQWG',
  'SP3P8QGR2V6TA8WC7703DFEEZEVBKJSB8CQMFZR84',
  'SP1J7VMKDJPVSC1GGM14KJ67K7PNRS3Q8FHRZ1TY',
  'SP2DTQW6VP43HXZ43X7196KF4KVZYEYRA8YJYVQFQ',
  'SP1A7T9F7JQW36N38A7T091MNPQGP83Q5RHH38S5D',
  'SP3GRJ1FJT7QC7N51PQGR6PZXMXZH5SKQ8B4JRW1D',
  'SPMSV5713DE5Y00398GY299P9BR9Y6X0CVE2WDEN',
  'SP2RS0YJZ2QH5VYXQ91X06B9QYR90BNGJETWP0V69',
  'SP363TB74RTGSB5HA5NGYTEN7A9CH1AB4GFFJK2J4',
  'SP1K8RG4PV202FHT8J9023G1WJRPFTSZXN9TPNEJX',
  'SP1ZZES8MY7Q88QGHWX7CFJG9NH6JZ1CBYCTAR9JX',
  'SP3KQ64B8G1TVD3T2XXT7TQ8468GYPBDJQS7QXRKK',
  'SP2F27R3Y5TDEHA7BRMJQ7DMPSVFKE5ZS8F2PZBHN',
  'SP1B7FFVFHHBCB466DVJR02BQ7PS9TNW02YA29DR3',
  'SP1BS2AZXV23Q060WSQKZ1S37YGNNGZ3MEX8VSGVT',
  'SP2HV9HYWZRAPTCC10VXCK72P3W4F9NDB8E1HBEZH',
  'SP37CMVQJF4PH2PGGDNC9Z42Z39Y2VGAREKDW9GKM',
  'SPHJXDEQ89DSTMP45CG3RQCCYCDYMMVEV43CWQPS',
  'SP1WK64BXKS44ZDQ9JY9F51BC429GSTET5M1TQ6VZ',
  'SP3T48T7DT9P563SM1F0497677W6A5TPK24KZVX08',
  'SP1N97ZKD957ZFZ2G7YMD3DGSSC4HQRQEY9R6CVPB',
  'SP1PC55AR9CMPDDTFK2FX1WZ8M4NYTTW92W2SBMVE',
  'SP34XZ1ZEW6S6VG8XZ0BEQT7026PQDNCA6HS5XHDE',
  'SP3MCZK6R909A9Y963PPGRMFXZ67BS1PQJ5DX7EHK',
  'SP1W5JEMR8F085392YXD6AR7J4PTC6Y3PATD3W8XT',
  'SP2J55W3S0AR2S5KGTGHHRMK6BH5ZG0Z26WA7VTTJ',
  'SPDSBJ44F52ZV5623FNYBV29DTPHC07HN2G0QJ7J',
  'SP3QN1N5GBR439C92YRWEYKGQV6W8NC0QFP317E38',
  'SP1767T13ARFJX2RARNMR5H6XYTQQCKCB6Y6PE0MJ',
  'SP1R1P7QYPJSTK6SXXB56SJMTB2PS6APEC29J1C5F',
  'SP10MR7XS69Z0T5FEZ9M1NXQE3J38WVZEC1WW9M41',
  'SPK9VZAAYBPD60T789F3WBFN7DQF2MKTPDVT2FQS',
  'SP3QQNWFBT63BRDPWB7WJ960XRFZ6JJ5R3RCA3NZJ',
  'SP3W9K0Z0FEKMP50TAPEBW2686MB1PT9SSSD09YY8',
  'SP20W96QTE79ZHR39JZRSQT6F60N8E88QGN6W9GDC',
  'SPA6115JWWJ1M4ZSKS7MA9DT2H5JY8XP8ZRNKM1J',
  'SP1XGM9EJFBGWD1NR2C87SZTW1S1SVWD02S5J6823',
  'SP3DSKC1H42Q09RSR36AZDPH683HZXH0V3Q6FS8SQ',
  'SP28KYD8TQC1QHTZ7T0B84AK5NYPFS4SH8WZMZ5A4',
  'SP2A43ZZNVC0PS9MY7CVRZGBB4YWMWYNSECC1TRBZ',
  'SP1GRXKFBKPQM4516ERC2FEDMQEHW5JWXE5FMZ7AS',
  'SP2TDWZA8JF9N5PGZVNWRTR2SBWBEP5G78709ADWD',
  'SPMVT1PNW779HPPQX9Z4J82EVE5V2JN16N0PPTHN',
  'SPQT30FDP1QVE58W42NQGMQTWF5DQHX5KVCJGBVZ',
  'SPG34S51QV6YTZQGVRPZY9323MY4BTCFAFP1HR25',
  'SPDEBDHSC595MNMZXQAVEWV2PF01EZCQR4243R71',
  'SP1N61TCQMDVZ6JBSEXWHN4N7XMY1JEVJXFB62ESJ',
  'SP2A4AHARKR9PNPNYWCE9RT1EF9BQ7GDPTRJ03EAA',
  'SP69MS8W17WWT6MNH8AB4A7BMY5AX6MAMWD89CCR',
  'ST3YDMPA5D18C791KNVT7FBC3KFCY426F7DFH0RQY',
  'SP7VNQD3GJT3ZBCJ94J20K1FE07W1W2VX8DKJYGC',
  'SP14JJQQHQJ48TFZ4JR14F1ZVCR50VCNPMSM8VFR6',
  'SP24GQGWVJ2J7F42E3TZMVACQX896RKK3Z1V9HXZK',
  'SP30MTEHAAYB5VHJ5AHD2EH76QTKZ0NQ5HT8AEM3K',
  'SP3GGBMR05RXR7X4N78P9H181FDAVFWMQTR9B7S0T',
  'SP3Q0SCQKAWKY4ZJ031CETN3R0WY4HZ9Z5P88YSN8',
  'SP1NR6C7NH70PMFEZK9ZEF91KZKG51M6WSYH1JBE3',
  'SP1E08C7JNNVSVP9CT8CKPZV2VRGA33NFST3KR7Z6',
  'SPZ5DJGRVZHXEEEYYGWEX84KQB8P69GC715ZRNW',
  'SP27SAJ9Y60ZHCD7HBKHXY5CWVNCME57V1JHM3EBQ',
  '1394749928310620000000000000000000000000000000000',
  'SP2M0A01CA4RSJHZ8K1K1VQ0WSS3KF2P8TJ47X68C',
  'SPC85ACHAGJ1RDX429J5HT263SAR0RK3Z9R8S0CP',
  'SP3Y7P1EQN8P1AKBY1WNB9797R3MDBNV0QD6D68',
  'SP3C8XDZDW7Y5X2NX3GGS20KHGBZZ2DVX53TXGEFZ',
  'SPCJ0JZVB02YYVSR5XVS1JJ17G4ZP1KFGD15B049',
  'SP2ZQW7E4AP1C91QW9AA3FRQF7MMEV0P0JWFZRBZM',
  'SP26TQ7R2VXHADRJM3SHR959MHSV242X4EFGPPY6Y',
  'SP3GT6YMNTGNZ50RBFAPR5WWJMQ2XKJCJAY6KQTFD',
  'SP5B6B69WDEWSDPWENY67MRQNERQQ6H7M9RRDZ42',
  'SP1RMWYHFV4YRWXGZTCP9TMQZVCRPYVH1H73895PZ',
  'SP3676WYESWC0HWEM6YFE0HK30M0MJT49W3ZQQ3Y3',
  'SP2QSDQETHZQWHAWJP0GMF9FB9EMGEXRTRGHDV1E5',
  'SP8XNKXZKKFYMFBQCF8AG9BVAJ4YR9CFYNQ5SHH5',
  'SP28FSG51DF04VB1VRF1MA6HC61SM4MYYC4F342RB',
  'SPMBAGHAH7M1M34DDVG182ZJ7G58CHYJHYVX1K1M',
  'SP27DZ9XD081P7ZF421ED0MHYZCXYV6GPA6PD1VKD',
  'SP1Y778PGZP79GJ36KGYEKSGNSP5QQHK24N647DDG',
  'SPMS5CDKJHY1MM6NKPE9C6H1GFMMXBY2594DC303',
  'SP1NYFPS1KPRYFKVS2RS2JYXJR98KEVTWZ2A5MQBG',
  'SP19EK30CBYF5MCJMGT1H0WH3DWGVW2A73212BP5Y',
  'SP1E97Z89JTD454P1Y3N8AQPS3BZD2FVNXPP7Q8D3',
  'SP3D5RMHKYECC4NWKJGCY4KQGQ4MGFD883Z7W78NR',
  'SP26G2888Y12BVPF0G57Q78WM07MMC13M4GX807DP',
  'SP2H9SJKH3P66M65FDRB7E1VYGDQH1R5RBNHS2A7T',
  'SP1V90X0YM51H87YX0F1YHB89C8SZ38QMA1SBAVTH',
  'SP3KBQBNW6NNJMGBP7WC3JSJETSNS8VZ7NPQCJK1G',
  'SP153DHDBVPQ1FSX4FQ1GRWQK43EC2S3N5ZFZX4XK',
  'SP1PTKK0ZJGRVVN8Z6E7PG2SZQ3CTCT5FY21MDBVP',
  'SPRGJTV2PGBV0ZY6K8ZPJ7F3C209S2VTTMBRDN93',
  'SPVM12QZD5GG98SAZQAWJVB0AZRF8QK86WHPTKR2',
  'SP13447ZY5YWSDQK89805P019V9TWJTBXS67KFFM4',
  'SP2SPZWKQC70FE0XFC1RAYG0H7SCBV4849B17Y26C',
  'SP3WHRQ988S08748VY0VZYPA00Y3JPSR217SN12S9',
  'SP2SPZWKQC70FE0XFC1RAYG0H7SCBV4849B17Y26C',
  'SPHP588Y6ES886214AA3S7WPCN6QQR2S3Z8348TC',
  'SP12956YQ15JF82XDC0CCGQ6X55B536209BMA2PK3',
  'SP3AG72H8PM7Q25WG04RQYWNJCY6RG3FRT872G9RK',
  'SPKT1FYMMRFGMZ64GV6KQFHCN0EW6V6S4Q662474',
  'SP2MDRGWNVZ57FJWK41A2F8G49CN4364B68Z42728',
  'SP2RGG767MPXVH55BKXM9VQ821YJGRCTCQJVN3YEY',
  'SP2M4TQW8B0KW0YSF1N6GGW36FD1G7G439NTF5CQ',
  'SP2S7WDXGWPBEBPWPA706WE0N6F9MH7HFQ13PJWMN',
  'SP16QKGGR648XDCMHQE7KDRHF5RBJNT820QVKR2T7',
  'SP1FQ2ZZXYCA0X7NV9SRW4V7QX9VH01TDFMJ7AHHA',
  'SP2EYH4WN0Q9F31553QA56MRP8YCYEER3CAAH8HZ9',
  'SP3Y211VCTZPGPXRBG5D0KSFTNDRZ1QX8BGV41RWB',
  'SPGBQ71HMCCME7KD23HS5PGAXE3VECYQYC0CXTFN',
  'SP3CFB4VHDSKV6K9H5BYV0JGN9RJYP0T72TXJYJWG',
  'SP3W7TR8TS8DB1AAFPM4SP40JAKHZ595R0ZXM627V',
  'SP3BB68WA5ZMVKR6QKERP6AGBJ99H5D9CQZQBN1Q3',
  'SP323T3T31PKWR9YPRCXCG5W63G3A498R9H5EQQX',
  'SP15PNCTE1PEB733EMJ87CRK2M3Y4MR5KN1WV12SW',
  'SP3VT7H9FK9CKFEKGX181T74BEXVMWQW8WC1QNMFT',
  'SP1CSH1Z8PWQFD4ZC39JN6KHTYPXZNRTSAKFAH2NE',
  'SP3K6DXS4AVG6ZZWNT5BEB4MY5R88TKFYJXP8CE0V',
  'SP24KT9P3WC4849P144R9CBKW4E9TNH48M4N7W2S0',
  'SPYFCHC36P6WW0N3XK0FZED6PCVJEJJWY7AX3M9V',
  'SP3KQ64B8G1TVD3T2XXT7TQ8468GYPBDJQS7QXRKK',
  'SP3VBME5VPZJ16G7KFA2VFTNHD7924RNE4G4J46ZZ',
  'SP23AEFTQ7TPKD2HCZFEMG0SQ9N86K4Z7DR3EKHA1',
  'SP2M63YGBZCTWBWBCG77RET0RMP42C08T73MKAPNP',
  'SP1T07GK9H4M0WP4N1DSSA7NJ7GNTQZ0GBZM0GAR2',
  'SP2QK20FRJ6N1XZ0T6RGF180RVQ62HPR1NAFMYTVB',
  'SPYFCHC36P6WW0N3XK0FZED6PCVJEJJWY7AX3M9V',
  'SP1TZ5YVT1B7RJRVGTHTYK8DSXF9C2P60KFD0SR5',
  'SP23AEFTQ7TPKD2HCZFEMG0SQ9N86K4Z7DR3EKHA1',
  'SP12DDFVRXHFC2PX8VCXRGQJ2W3S62YHTAEAQGKD8',
  'SP3XRVS0A5FB55SW5WYTD61YEQXHCDA79E5BDNVA0',
  'SP3P1PT8Y9TQFCDVZWEG22B1CBQAS4KVH6ZXAC70G',
  'SPPKV3KBDQRZ6JJK2VWB2JKTXHVYF40J253TY20B',
  'SPP1VDQKF2H90QZVRBN0K6Z90EKES9NSMYKXHVGH',
  'SP5XRD9Z4V0W662DH2GNS476T1EYQCPN866J77X6',
  'SP3YBQRESRPX4B90BZHE77J2YK5DG8BBBXWJHZ0M9',
  'SP3QDVGGTW97WXAAM3BWPD1XJFZY5FYYZW9ZFDZMC',
  'SPQB9C353DW6DJGQ592MN512294JR9KHS6PY56VQ',
  'SP2WS4025T1DZ69YZ5EBXBNV4DDDZ6N4Y9YWJ0FTA',
  'SP26N9KGZC8SJ29X03MFGP5VMNSY8K7SKXYX8KNVZ',
  'SP3V7QBGT0K73YT7PFQQ18A1RKJTFDMZ4FK7F4J5K',
  'SP36G0SZR51M7TVVWCFX2XTCJ09AMXYZGWS02SZWQ',
  'SP2BE9TGZ5T3FN17NMMVEYHBQV9HS04D780B28YFM',
  'SP1BZDJBA7VJ3GMZB3HY43ZXVRWW9ZDP0S2KRV82C',
  'SPYC823VTHKS4W6WNAYFEBH89GS8EXV2X80HH8RN',
  'SP1EEJY6T7T6NB10FF788Z1XMM3R2QKKDDG0KV7FN',
  'SP1J1Q43VA63EJM4G7GJ39DPNP12KCERSPFK7J1CA',
  'SPQRJ71SZSH8BEZ0XJDEHEB7VHMTN1K94VNDYNCS',
  'SP2Y5R7FP5JWGFZY7HCAAVR01H0Z21B3JVN5XWXBR',
  'SP2E0MZ0B3F8KGKSTDYDJM97DCB4ENEJPACKGS2WZ',
  'SPT67XYBF88FXARWT6WY3ZTG83J87KG9TXPF0TZ9',
  'SP25BP2WS8KCHSK6NSXS4F8YJSG0Y0XPTEKSEXB14',
  'SPFRQKT3RH6N8VPCF1D9JF0A7BWJ8FPZSQBCGMA9',
  'SP11GS50SF9VM718V1Y377KC1FAEM66CJR1EN0JMZ',
  'SP2NY0Z913RRWNGTXA9RX4AEG4MJTEJSBQRH2WERZ',
  'SPP1XFREP00CJXC65955T7HATAS4ZMVQFQ7ZWD7R',
  'SP1NJC4F7M5JJE0NA6JS6XX8BBMCRA7J1P3X1EWN2',
  'SP20NZZ0ZBAWN69Y82ABT140DS8DBG43H4YR7B5F',
  '938598806902727000000000000000000000000000000000',
  'SP3X3CMJA625J5R7WD3RJ8KNMEH70TGYAV5T4CR70',
  'SPS2FZ3K6N2CZPBM4BSQCEQV23V2334E7MJ4CHZT',
  'SPGN9ZFYRD7NQVXJD2NJF3Z9DCK22DGB01MJRM8B',
  'SPGN9ZFYRD7NQVXJD2NJF3Z9DCK22DGB01MJRM8B',
  'SP10QFJS0NRR3FK48DS7TJGAHQG5G7RQMKCDP57SW',
  'SP1ZGZK3RCA7R0MH571R6ECZTXP8H2T3ZF6NDR5XJ',
  'SP31BPRAY9BHPJ1FS8QG4TZ0AEVWS80RKVNXCBZM6',
  'SP11PNGVD9F0G07KECWSX3WKBFEMJTFH6P1TA88XN',
  'SP14EC2HB138R5Z30GS0C9M3Y804D7MHXYCPY5HXB',
  'SP1R5SHFR2ZSY1GYNZBBQ38WNNP7HWP4D6SEAFH84',
  'SP2FZ154ESZ8NB34RZ3RS147GD6DSEYNE8DQD0XDM',
  'SPF556QKXVDARH1K9G8A7F2BMSKA5B4FZ2DQM9WV',
  'SP4QZ39TT69WVDM7GPX8MHN2PZWHT84YP056JK9C',
  'SP2F27R3Y5TDEHA7BRMJQ7DMPSVFKE5ZS8F2PZBHN',
  'SP1X23TH8W14F8CPZKFCN497G4EEPKW0NMN32KJ3F',
  'SPKR1JG6RKDCA6DHPFC8VV5KSDKMBC28W3DZ89PQ',
  'SPTYWR4YW4G0R6ZM53QYTDP1NA3P2CNEJMCKVV81',
  'SP177PF7J7FDVBPDKE00CRGG67QF5XE40HKDF7W1R',
  'SP168NBMZSFETCA8CPAFY7V6EPF7ZV9X22MYZSQ8A',
  'SPWHNM1XFF6NRMH5JSNW895YASWF83HHD9JF3H4B',
  'SP2F022B6BFGTM7J0Q2DG4YZNCB7W2NAAE10F79N1',
  'SP3Z0BHS0SVP5733GDZ2RWJV1G2EW04PFN2NSX3PK',
  'SP18CQG5NS9XEPMSQ7YRKZTQQWEDBGETEXGKSTVQ2',
  'SP28WRXFHCQG83KEFC0MGSP9B8S79QCEPM2E1PDER',
  'SP1TB7V84QSAEM4K46V5P7573CXY4AT6Q2SZRFT5A',
  'SPSFDJNNVV5JCH5AAS2HF87YV8RX61EKBRN05EN',
  'SP2P7GZ6AJCH68J76K5G8X6Y7WT3VG6TFG7H3E3VE',
  'SP3N7BCZ9PN9M1FRK5X927QPH3R4KM4EPQGCKYJHX',
  'SP207GDQH94V3TCM9R2V0GJEWPYNQ8R8NMPSC1VWT',
  'SP1N97ZKD957ZFZ2G7YMD3DGSSC4HQRQEY9R6CVPB',
  'SP33DREDP20WJXRF8VFZEAZP0HHK5MQ60TZ0W4K3',
  'SP5KEYTM4GCEPXJ7T3QD58TJNSMM4Y12H2ABA2J0',
  'SP3H60ZDQ67QFYRDCJ2GR5BRSZM7WTJ7987KK7K5M',
  'SPA6115JWWJ1M4ZSKS7MA9DT2H5JY8XP8ZRNKM1J',
  'SP2ZV39Y544CG8M1G2R2A345VXQJD64CE06MF4P4E',
  'SP2YB1SVF42GB6TGCBW2ET05WWQ92YRS3K6E1J5HY',
  'SP2HN6H97ESYGM8T6HAE8PS4E0YPQ76CN87KSAVY2',
  'SP3XW4E3DJSEQ3Q0VB0RM6JSH5N713VHM1JD9DSNJ',
  'SP3TXB19BJ0S57M6P5709JDTVMRVXYNSHXNXYCRM7',
  'SP31SJ2X5683KDX8P58HWRA2DXY3ED4WZ6Z3DM0A9',
  'SP3X182G1NJ6EQ2V4PCP4Q9471YJX3N2J7WSQ2D69',
  'SP21BTTWV9S9P21SGGTT15GJ0KHVE2MS8K4QBMFCM',
  'SPRTCHNJWKYE9W7H9EF3WXR09HDT3B7FD74KZ241',
  'SP3XW4E3DJSEQ3Q0VB0RM6JSH5N713VHM1JD9DSNJ',
  'SPK66AG7725DA15RN7HM44NFTQ4XKCCAT4MJ5ZAZ',
  'SPP7NXQJZHGB592QPST4DM2JTZ5H7590HCPWKNFG',
  'SP1B9CDM3XFHJFNM58T6A15HZNRHZBK1VFF3Y94T4',
  'SP1356XWA7TSAW2CZ9RQN1RW057A54HXZ9K30WDN0',
  'SP2V6C0A29YQVCWN7GGMZHX8TTJBYQHEEK964HYS4',
  'SP3TJNARP8V8PBTXBH9ZAA68WCMF3JEEN9W07S5DP',
  'SPM8F2Q1KWMV1SFR8B67858D75J227H420VYA77D',
  'SP2GZQJDT3RPCRAB064BWNP2T2XVQA9X5ATF94BVE',
  'SP3HT404A7H6H9N8M1G4CTP263KYW707FCZTY2TQ4',
  'SP32V5EAKRWZ66VVA67XGDK18VYMZM5NT7NP98M9',
  'SP3JYXZJ7DF8FVCNTC3W8KWG3XXFK9Q0KFVC5BDM4',
  'SP36KGRN8M7MCXBEXSBB8MAE3ZTSSZX946SMV5WB1',
  'SP1DM9WG3Q966YVQN078JDFAKYYHFPG5QMFVNSNM2',
  'SP2R52KYR8WH8JT6ZP20Q1YQRYZEY4TNTCXV13HM6',
  'SP2M8KYSACMQBJBTVNCVMBKDN927TT04MMC4GXM0C',
  'SP13SY2XR2PA6VCZAQJK4TRPQDMHPBT85ZPY8J943',
  '467128541421356000000000000000000000000000000000',
  'SP38XSJ91XTY6ARN2W58AKKPBJAJ0AM02W1QJ497F',
  'SP3JXZF9985WV7RQGTMZY17FQTKA052HFH126M8Z7',
  'SP1M83JETM6QSMCASE9QZT091BMWSMVXCWB3B9TX8',
  'SP16YA5N2VE52JRDYXKFZ2TF7T2CBRB4SH8NYKJX1',
  'SP3J7NKEDW7HQW8EENSEF8TMB8NJQW0363ZEVGJE5',
  'SP13EJKXSRA879PG55B1J8824EGVC2PGZQ720NGBD',
  '797773563361768000000000000000000000000000000000',
  'SP174DSQPCPB3DGKBKYC9Y074BM9S806WFX4W158Y',
  'SP1DHAD4WY2FM8NSSG6W15VXEHVENH0DV198724VJ',
  'SP1DHAD4WY2FM8NSSG6W15VXEHVENH0DV198724VJ',
  'SP1TKR1N7E0HVPKKT0CJ76V7HWY25PS80QCBPGKG3',
  'SP2G1NW8A2TYJW37CVQHK9VWQWWPSVD8TZG20E2SC',
  'SP6WNCA16QYFHTQ2CQZ8DV9ZWN4WCCQCX2D6X3HN',
  'SP194T26H3X8Z0QHA5MB6KAH50A0RKVYWVEE9JRZ8',
  'SP2QYCY5G3KXFR3TE4CCTRNBEHD7723BNHCFA0AKF',
  'SPP1YM7M12JVR4S4SYHAQNV0J71155WYHSA3ZV0H',
  'SPKGP0G2N6W367MVZSWD62TKDG88V80BD5RRXRFB',
  'SPFE54QJRKZJPKGJR3EVEK4VN55AQH48NNFEMTFF',
  'SPBP6DVD455JBECDA3JE9J09GE7XCPB29MJDXZN4',
  'SP2Z02XEFJ78W36HHQKV7QSFF8YFPHHZTQBKDA5WA',
  'SP3QN99414WHW585526Q2XCN0JBC96Y68RY025W2X',
  'SPH8DYPNFV7VPK6MNK2G1X9H2DJ1D69PXNQKSE0B',
  'SP12T7H5RRV0FQQMKXZH12KXE8RVJQ99QVRC54HJB',
  'SP2HAV4V9HHDAKP4XGY81C0F5EKT4T8ZKBN4MBW4B',
  'SP27NKHNJD4Y6CZE5DRTB4KYY4VSK8T9653QEYNA7',
  'SP3J54M9HA9BQZXYCGN99YPRVZA6FMJXNKT15XRHS',
  'SP2CEEFYWGFB9QAAJ8N5QSV3X8SJAJMRS8NPCRNE5',
  'SP2EBPD5DVW81Y1KJ1CW9JGADX0X0C5PTQ0XYNY88',
  'SPMG46XTZ4H5RYAF9YJN4CG081D0TF2A5QE18PYY',
  'SP30CVQMMYNHFHW3SMW81QCMRB3EF2KGM6E0NEJDG',
  'SP48N9CC5QSQC1VS2ZMP7R262D3Y1TVWQ5KXZ2BQ',
  'SP277ZPR06WCB9NZBTFAS94FD8CNX3W4ZYGVXQ2Z1',
  'SP28P4C3VBMWV5JQSAJ4HEPJ2M3G6EDPTQBHN91NB',
  'SP1R5671T2130N4AHX8Q6CC0D60ND65WYPF6Z1R2H',
  'SP18DZBVKX9E1C2A8466RWVBRM1A8TVM0ZDQ0FNE1',
  'SP119GBPAF81GXA1JF03EGE7G3BRCW8RKZS9S7P0',
  'SP6Y9FQ6HE0HZ4G5XVT9PG0XZJJM2WWN0SXCY8YV',
  'SP11GZEGKDAG735WVNH7MP4CE8T15N4ZNH3G1PZSH',
  'SPMQETN1D51FPZ6CZ7E2H0ENCS7MMXER51DDGXE1',
  'SP1TRC4BZT9HQNVYMTXPPGZ8RP9Z57FE46WJ0KT72',
  'SP3MG6J9ZC7277WSYPWWJ6R6Q9EGZ2YN3CVVQPHKY',
  'SP37CMVQJF4PH2PGGDNC9Z42Z39Y2VGAREKDW9GKM',
  'SP320KN5FE9YBBEPB5F5H97R15HR68NWD81GTFDM6',
  'SPMSJDTTJ4W91KE70CFZXS17BHGT19W1ZJWAR0YK',
  'SP26AENERWK0P4CCVRPY43QMB681EY9BR3C7A22XT',
  'SP3F4MK19Z68NVTK1AW9NS6T6AB2MJZN0HBGENBNZ',
  'SP22EJE59T4WP00J6C0VTNK9B58RM5P7TTP95DVNT',
  'SP24P5TWW0Z00Y0P9GF66KW0YN2274XTA68SSEZPD',
  'SPRX8W53YWFKB61CAMRH4WPT3N1SD7H2KCZTH9Z3',
  'SP3BMWC7K8Q1YZDQM3AHSXCPTVMB379YPS0CDTM47',
  'SP31XFT8MF2Z6DZSAHGM1RVJ2KGMAB88TK9NWQ1K1',
  'SP1DD4ATRPKJJ6Q2MCQB5N9GSTQMBQBEMPQR71QJQ',
  'SP1R44RS1FBWCXFF5YX5MJP8C3928Q0R4V1TXMYJA',
  'SPKWCZZXJV9ZTGTKHJTFTQTED5MKWFDA9BX2ZC63',
  'SP3SMKBZE753XY44KXP4SXEGTVKFS8NHP7T81V0ST',
  'SP1ZCY61ZMBS4V8VKNQMT0DTB6RH37PHEBW9TV0AY',
  'SP2AZF6A5CHBXAAJJ26HP52YC3FC18QFV29F0P181',
  'SP2FP1GPNXNWW6HD2KSHKMV9T1T5M9GNWNEMPPPW6',
  'SPMMDBAA4ANHQXZ6E1M26W1S3XKK457BRWW9Z0DW',
  'SPXPE6ZTMH35FYVSM72K1EBPK0H4QDEY38XWV560',
  'SPF0Q4G9AGFX1RM0NMC9VS6Y1T5QAN7WX01S9TER',
  'SP1JZ6QM7Q7FXE4V68DQ6579HQ4FGS90FRF6FG33P',
  'SP2WDB16WS4AV72ADBX5BH7V53SZ6HKZMQ4JET1AR',
  'SP1YRDM1EKT3JWAZ3NSG4P8X1VHT9R349BF78GVWE',
  'SPK6KD9DB3WS12T48YDSYMG2QDY6GKMG0W9XJJTK',
  'SP17NQQEFGBZ0DBED9P3DF7SJQFT2NRA8XNECH3G7',
  'SPVFS7MP9GECGM0GYSVS11B03BBS7BKKEZ389WW',
  '577777781578506000000000000000000000000000000000',
  'SPZ028GMTW8RB8240JYJXE953KXSBCEWAN0XR388',
  'SP3A35HYRZ4RTQBN9DM4TW24HH32HPYHP6RGJ5Z0P',
  'SP3DDYQEPWEV28SS30P7C67DKEM8690MZF0RNPJEZ',
  'SP3YEXF9PWHW613236QXTQBPJ57H3NPAPG99B8781',
  'SP7FMG61HXCZ8J786XJH86JJ4Z6JW1TFJZGQASGG',
  'SP3ABZWV9VA5TK0WHC0JTDA0YBR0ERYJAQ6WF69NA',
  'SPM5TXNF8S8Q3JMBTSG4WG7K56ACFHT26532AZVE',
  'SP1QRFVDS76WFV5XCDHAR7FQYTSNM4M1P4TGFZRK1',
  '876027036303274000000000000000000000000000000000',
  '510648423141568000000000000000000000000000000000',
  'SP34118B3E95KHN7EVZQRC1GZ922SG26FVNDNQ07K',
  'SP3QQVZYDBR17KGSRC8F0RZQXGKSKFKBSWF5EFG9Y',
  'SP1V1D23E84MAE1P2R4DTVNTVDW1SHB7VDPE6Q8VP',
  'SP3WMY8HXX57VXDYHWGS8VXHVR7BVXBY14NXW1NG1',
  'SP3369HDP12NR74M8VF6T0T4B4KGH8CPYJFKA2ETB',
  'SP2YKW1A9MEHYQ92C73DCTTCDQ7CV9F0W0NHVWWFD',
  'SP3GS4Z731W99DR4EKAR1QQNRN38HHNQGVHNFW4AE',
  'SP3Y9TYCJQZDBG91F1M83Q5M499MZDHKTGBRRJY3N',
  'SP22HZ9CQFWTG1NNJDWJ6ETC81QWMEVQEVCK0WCPA',
  'SPC2T9FMJ8AP3PYC1ZM7QRY7KGRRZG6DS01ZKEG8',
  'SP1258FANFWQF281Z5RMQHQ88VAFSM5RDRM30Q5N8',
  'SP18TQHDRVSEZDEV0DV96706EW9ZJ5ZZ0GHV7PN87',
  'SPC2T9FMJ8AP3PYC1ZM7QRY7KGRRZG6DS01ZKEG8',
  'SP1QW4RWBYEYMYTX94DT4XEN7973V8MM809PR9TTW',
  'SP293HK121WA2EBN0NHVXQQN6Q7HAERT6C3AFQVGT',
  'SP334YNDANM9GDKPFGSR1673NVHC6TK12ZCGYQK4W',
  'SP39S27JHN0VZ3VPRABNXYCGTMABKGDDJM3BHCGK1',
  'SP2M3CNR4EGATARM6DFZJF1TGJZ8W8VM5XWGW9EKE',
  'SP1T69NA6KYGPK492N5EYWG1EXYQYTSXW45SET8RS',
  'SP2SXA8BDV2BDPB9P76WV8K8QX7ZE08VFY7TR4EG6',
  'SP67NXGSH46PX491ZH3CQH8EJD2C9R25TS4WMYFT',
  'SP2VM7818GMXRYQQPPTDJV3QGY6PAPF0R3WCH9F1G',
  'SP4H7GENZXVHFMJ06KMRSW7WMKGM9AZ4HZC32QKB',
  'SP8Z3QJ02PE1RZVBYZ8F01H1VZREBDMP1C84EYT9',
  'SPHEZ0FWYGQSNA4P3SQTN27DFZ84FG97BH7G7ARD',
  'SP162FJ7ZC6B6455WKNP59KT2JEFKJ8T9WV94TNCZ',
  'SP21R633FMNFF78Z43C0S5J8WQTKPC10VXE1Z72F2',
  'SP27ZWBSBA67AY9Z0JC8E167X0S403ACB64XP63CG',
  'SP1DYNB2MCFPDXB95W7Q7B6K615J3PZ3H69TJGNAR',
  'SP2XDYDS6SYKEHZ0WDYX3EPA2YFPKBMYTATZZZPS0',
  'SP2BBY5A0155BYQ8KR7VJNES5FWW7K4K1KZNYYKM0',
  'SP3MMTV9Y37TBFD39GPTQFSMJMW5AECCFHN5S7PZG',
  'SP3BEC2ED1KT1MV1RPQ4QST1CJGJVR1VT7C96VYAY',
  'SP3Q258JPX4C3AB4919H213A4T3BHTCRTK0ZXQQ5F',
  'SP2MVKCPSJ4HKQRC6PG71G8E2CJ4XCBJQKVYMEV88',
  'SP2YJP5C0XE53S6A2NYYHEBTCKG7JVP8M82KM3Z90',
  'SP3HD6JF8N5214S5Q7GHXBQGKY6XJNTRH0DS6P6KY',
  'SPJ9T2Z5Y6R4WD0N7T8E4EW0D3726S547549AV72',
  '770395941417795000000000000000000000000000000000',
  'SP3NHD15FAEXF0Z8MS3S2AFPD76MSPFXA711KBS6A',
  'SP1H0BXNRCWTKCZ40HSK734ABYJ7N0T4PEF4JZRD0',
  'SP188EWZE6ZZ12BRCTKVYMJVX4YP4ENT29TFNRPXW',
  'SPVFS7MP9GECGM0GYSVS11B03BBS7BKKEZ389WW',
  'SP1CJQFCAX2NXZFQP2R20BJJ2HZZCNC8CCQAWQ9DF',
  'SPXQS1T1T2BKGSHH8H75PVFEY0R1X39F0B3MQWTJ',
  'SP23YCE6RY0Y6BP7XYZWBDT4BESCXTRW9A147RG4P',
  'SPPEW79ET9Z2MCQV29PK9DPAM08MWNAM5EFWYN6S',
  '1019763391534560000000000000000000000000000000000',
  'SPMC1A71XW7F3GHP29VZFV3E6QDWGKA9YEVESKT6',
  'SP3KQ2W47V0AYKJGMEVQTHZ607ZC4DB0AY6TETPF6',
  'SP3BGQ05SN87GC0PAEZZV0AWJRF8MT8Y0KMMMMKJM',
  'SP191VNTSR3SCEP118K0BMEY8TG6EKC6TMNDFDMZF',
  'SP1541YM97HXCBJTF46TXB874RJB7XG6Q04D4015S',
  'SPQP85AZEWY9E39ZEJWAAPZQFH1TM8J3ZS6A8SBC',
  'SP2R9HFA9SVJ74HCHPP7NT64Q5T75FZJWWK9VXRGK',
  'SP3GKD512NA3Y6WRBNB3MCRFK5H6PKB1S8WWT1K2H',
  'SP32PZ8Q9CRFR2KPR58RBNAXBRTQ8MWACNV277K3F',
  'SP3ARRBB3VQM438PNYAD9BN83TEGCCMR1FRH4W2F3',
  'SP1RKECNGCB48KK6WBF06RGR7PKZX6FR4R1VQT1NH',
  '1075100015377900000000000000000000000000000000000',
  '844140099760077000000000000000000000000000000000',
  'SP12YJK761ZGYV2ZEF21K7ZZYEXRG93KH0N4DQVDM',
  'SP110K5HDZT23W6JMFMGRDDPSB60KJPRTSBT3DYAM',
  'SP1EBZJ8VFW90TC5ZX074A79QZ7VFT7JVZ5R09H6M',
  'SP11FB8EHA1KAMEEKEARWRWT059YYSZRNAP75B4PA',
  'SP86M4G3THA4SY76MP6XJVYSNKNFGSMQEDNN12RY',
  'SP17RRGR47TSEHFDXV7X3T0TPTP19A993GEB5V370',
  'SP1PF6STPMPGAQTDS08TTW1E0QMFBY73R3DV12TH3',
  'SPRQ77V8KWSV03C3GNZEHFCZQTRS1MK472JC72YK',
  'SP83BV05YBXVGENS59YSJX5JQDH47NJN9WMEEWHC',
  'SP30BTV3905TS3A83CENN271AHZHGM4C3FDZX3JNR',
  'SP1ARSPV0Q80DMESWSDX3HPD1RJRDYZ4PEY67XJRX',
  'SP6Q5B1BFNRFDDMMNC0EJW46H70RFAR20BSKXY65',
  'SP2Y0ZF00T087TKVVV7F6EPZYAFA32GHZEXF2M269',
  'SP2PC2DF5D7RQ2E37NZJYJTM5KB2J05YTWN9NYRK9',
  'SP30D4QP56C60EQ8BT35YQ727H90RKAB744WST5ZW',
  'SP3VEXW1SS0SBM5W44PBC0M6WZ1SC2BG3DJFDN7S0',
  'SP358PNJHYZKREQKJ7SC3AMTYVCFJF25EN1JSQFBJ',
  'SP1WFK5AF3A45ED83TA81J1KQ3MF867YC1J9GENHB',
  'SP1MBKXW7PAVM7951EMBQPAVV4QN5PJFSBP0FFB7A',
  'SP2CBJRZ7PZ4DYKWYW4WYVHGQD8RXCB98FMRJ1EVZ',
  'SP2S9K3Q5FJNH9NPCJDYDJHR8RZ5YDMERZ2FHW27C',
  'SP3A8RAS78QZM61VW8TFQE77XAM0FPR8VZ6F9CAE3',
  'SP1SKE9VVP76J3X4GRRQKW95NQKSH1G3T5D2T3J2D',
  'SPHCFSF5HRAF6Y0RH3EH25HSNQ751GXR86M13WDF',
  'SP180FZ6V6V2KZD7N98AZWVH55648NS87NE3CXNVZ',
  'SPJWRWPFFE61BHZQ6FYDKGPRY9NTQ6CER0RJK1G2',
  'SP2FVWPBEN2GG0V80GM5Y4EK2P24VD48WGZ7ES659',
  'SP1HC0ATVFW3H56FHVESY55P3TS5BA9P6PY7QA00S',
  'SPDF3TD80GMRYN1KM2FV8JYN3DFRPQMW16W7VHQF',
  'SP3R76HPNP8M58M1D76YRJ0BXDNG097M75MWVB6B7',
  'SP26KV4D1AXX0YY31CT359JXH6G5X2CR6HJ3C365Y',
  'SP1HQXX5190GAN5PBGVNJN7HCMAZD98ZSF7V4J1CA',
  'SP114GEH027Y41WKXCKVMH6TRRF05G5EEVT24F0KW',
  'SP16EVMVV6GCQD15BV2R0WM7MNKZB1Y48M6C2EY1X',
  'SP2E26G132H6Y9WW905CPXJ3MCK4TS5GJKW5GNDWZ',
  'SP3WPSN2VE6K1ASQAGZNMR3D8SV00QAAQRDKS4A7F',
  'SP99DZXT34JDS0YHCHZY2E1QFRJSZ1AMX5630S62',
  'SP2C60QTFSXQFWE3RK28D63DY3K2ZDD2C6YRKM157',
  'SP2A4G6HQ2DEV9G8V7YRZJ0NQPVRQVA23999GC4C7',
  'SP393R1NGAJEM9207TDNY622JM3MHXBPZWR4TEPXR',
  'SP2ZH18NHKPRSCJYFSQP64BHQN6PKKRY55A658RK9',
  'SP18W3WTTXC4JS7DJ3HZ5BFT66JN968AV4M5GVNBY',
  'SPVFTZV6EZ30BVGKQMSJX3WGCD8VTH6W4B67BJ8A',
  'SP3FX3YWJ3RQ4PX01T1FN0Y3XWHJ6J51ZGME9TSJ5',
  'SP27BF8R8HY31V2ZTV8HNVRBVRTSSFCPVZM4S9RMQ',
  'SP248M2TJHCT7WPVCP5SMNYXNYPFTEJZ8S8745AZH',
  'SP1N5JNPTJQGPEJM7TH4A5WAHRGPJNH53W8EYJGH4',
  'SP2ZXS4WTSS4GZYRSGH05PNAN37JJF1WASF4N93T2',
  'SP1MPRP2YNA1RSZDZYV2M5X2Q04ZHDE7EC4PQXZW3',
  'SP1P4NZ6G4MS3YA7YYH0SXV3ZAAMSGPRKSDJR31B6',
  'SP2SNZ7905E5MR29QM77Q7KNX8KXW3437Y2C6PDTB',
  'SP3ZED1KAHRQT4WKBP24S7BTZ6EFHXJW2VRVDNZH2',
  'SPY9CZRZFJ8VGSPYSNHXB9ZVTY5KBT4C6N19FPXY',
  'SPTYC4FQWXY3XYY3FN84X4TSEDSMTQ2K9Z8211ER',
  'SP1Y74HQKRZ1B7Y9C5X04QBYGAGCCZNR7HWV9GB7Y',
  'SP30YNBD5ZWYDARCCSQGWDE769D3FEMFPR406KJTH',
  'SP1R4N5JXA5AXH2F1QHC5YRBB1HXHQB5MF5298PXG',
  'SP3H3G4FWXR6MQ8SA4DGV79K9AXVZXT1E8RBYG3TB',
  'SPSTBANX1EP5WPCWNC6592VD1XC0BWBWWHYP1XF2',
  'SP2NR2625HB0YFFRFBD9VHVYESNY83VTMKZXMV6H0',
  'SP3YW10116E6K5K225SPW4DXFSBB6X1W9W0YZTXNM',
  'SPA9W9C98RPTKFN4JH0GTZG70QHSG66AB3RC5F71',
  'SP233DQ184TJ6QEVS6C40D364D74P82SG289MHHPK',
  'SP1FXCKY6E03H7HYKWX8Z0DGJ54YDK5CQH1EDSAJM',
  'SPFSN90N11WAT93HKZ9CZWTS39TBVX3G0KA968ZX',
  'SPPR5GQAWQF6WWTE51EVX7415CA04EV8YZHM3Y1H',
  'SP3T926Z341FKZCSX7KB5SXTD9AB3CYQ4ZFR8JF0A',
  'SPDMT7JY32FDEG7Q5WKJ5VG1MQMK32NZAG37E4MP',
  'SP1PZT22AYATGXZS983J912Q673T8AMC2CT3M1RX0',
  'SP2FNPWKAWXR5SQN2YJKMDGH9RYWQVXTYP9VC3WRE',
  'SP2FNPWKAWXR5SQN2YJKMDGH9RYWQVXTYP9VC3WRE',
  'SP3AZ138W5GBASPMNDMY5JC55977Y9GARTZBCHHD3',
  'SP23DNEW4KX1KE90AX1BDM74XPJHREBBSMBV2B7NF',
  'SPHX8K525GMMB4RVD9951M3172T1NAN5Y69HTSC9',
  'SP3X0VAXHGWEGBEPTXTQ0ZN46NV76N7E9HDDTKTA9',
  'SPZ7YBW5J8Z43T6VKR4JSY4GV222RM5QXETBWA6Z',
  'SPB4JKPFA47D9F4ZHJXYQBTP2TJYHJSN0AK774PC',
  'SP2B5KP8SQYJ2W50P8H0GK0APETMJSZ1PNPQWZJWY',
  'SP3A4F8789CVZPEM98BG45KFNS5FX8J6QZ5WAYBB6',
  'SPBW8GGZTH6C9W7H9QAMAFCA44TJS3DA629VZPWP',
  'SP1ZPQQGCXE57YTE86WSJPGP8TTXEGRPFDDJR808P',
  'SP77HNCX8KTBFTVKY6B40YAVS79GNA8BKQ84RV02',
  'SP38R8498DSX7HMZNA70AZ8THSZYFEPWE9TFDVXVJ',
  'SP779SC9CDWQVMTRXT0HZCEHSDBXCHNGG7BC1H9B',
  'SP16JKVNWBBGFPZXYWQ7YS95SZKPR3CMJHFHTM7Z1',
  'SPPC3WSS0W4V9CM0AX2MV4WPVR2NYRNS6P6381QW',
  'SP31HG1E426X3CQV2AGVN8EZS6C96BFHED7ZNCD7V',
  'SP28WRXFHCQG83KEFC0MGSP9B8S79QCEPM2E1PDER',
  'SP2NY0Z913RRWNGTXA9RX4AEG4MJTEJSBQRH2WERZ',
  'SP11GS50SF9VM718V1Y377KC1FAEM66CJR1EN0JMZ',
  'SPC9FR1S6RHQ2328RE12Y39MZZ1QMWCDB2W8KAGT',
  'SP9FPBXJGHW047SAY59TNX2N1QNBB57PS0ZVA9Z0',
  'SP2FR08470FQW2W6XGBMG8PDR2DQK01JW5Q0SF2K',
  'SP198FCJNCGWQFFRR91X1H1Z8SNCZBQYP0JCKVA49',
  'SP2QK40766TMWZDQF1WRTKBNFX508VP73W826XW60',
  'SPTQ9DPG8F3WDJ237866V389N6CFWZM7ZQFW1SDH',
  'SPJ7J6JXK9Z10MCETKXCECKNGZNB0X5QW59WRA6Y',
  'SP2QJB9GP3WC82QMG44V7B67YF72TMHTZY8YYTFYX',
  'SP35XXMRZ9MVKV9502CJWAMDWFWKWEP95KZ9JY3JY',
  'SP3AD28RC4ZDG3455RS1Z1159FN3DR2DJNMABAHGW',
  'SP3PAG6QYJMXD4T78S0JTE7A05YETJ9H431NMM4GX',
  'SP3NXPTSX59CMPJQ0BMX0FYT7HTGNAN74RSYQ652W',
  'SP377M2RN4S885WT0B2R62FB49XH1B9YDYENCZ11H',
  'SP160JX4EB8KZYHPYX48QW12E94E93B2HG34Y08B2',
  'SP39ZK5PTPT2Z6VS182992P1KCH825FJHWGRZV68A',
  'SP3Y14MMQG9S7ESSG8M3RMZCG5TQP411K0V5501QH',
  'SP1H9PQB2ZJ4XQ8AKM58N8X9G6SQH8BBM9J7XCRX7',
  'SP3ZBVN821J8A1WM6TGRAR3P6920TCF7SEWKPTV69',
  'SPV3GG8RNKG9HH9PZBNFB7YFYNCSHESNBMZ1T73X',
  'SP1W337D0JP9PS0SF9AV0MQZJFTH15H5WPKJRQD3X',
  'SPRY7H690JQ34RQ6XN8GGEMEJS1FWKV4BPRPFF46',
  'SP163AFWNX203RBAKN9JHNVA7RSBBPKGMS2G68ZHJ',
  'SP21JSD2AXECFPD8GEYKBCA5JMS2FDA94AD3D4R1V',
  'SP24JY28D6F6MVNJAWPDH5GM8YDVP18DDWKPWP0C3',
  'SP2DVX8ZG50Y5E3KAHJHNCGV5DD1R3DNQWBPV6NJA',
  'SP11S3V5FDXFWDDA4MP946803W06266V3SA6KKM5S',
  'SP22P44AJBNA5VAB9GS6X6BXEWZF9AKS1TGJN1JB7',
  'SP3SXC031VFGZ024QM2V2B5QRSPBZQYFHTEFDYXWG',
  'SP1D6B6XJ18SEJYD8S28QVW2CMVM29WJY2HWE7819',
  'SP1Q7CAZK5B8DV97NKMTXX0FYGMBCPF5WFGMNZ1T1',
  'SP353HXKZH73W6JJGF178675JT6NJCZXXYC0V693F',
  'SP1AD91SV8R6EC37YXCNRD4C9WVGZ3MAVB2TSR60K',
  'SP2NY0Z913RRWNGTXA9RX4AEG4MJTEJSBQRH2WERZ',
  'SP1QXABBT7YPYHP9SJA72QDW9EZDJKCR56K6DTW3X',
  'SP1FZA5VDBRTDDKF4P70W55TJE5HSDYCWEP8E29B7',
  'SP2DQA9A82SDNQ6JDH8BVKZN3NJJDJ78MK78KZTG4',
  'SPJZ1C5QHGX183TYQYFZ5PAN1G303V4S43Q1J6TF',
  'SP1K2YBFE0F4XS99K4J3NJWBV0M3K7HH79C9Y8P7X',
  'SP10AYS6DN5VWVD3VKHDA07ACRR7VAK3Y78FHCYS7',
  'SP1BCKS3VK6BWEFJ6FE32RVES6BSTGXB503XZJ6TP',
  'SPQ55CR4K0D6KGQZW0SEZ289HTG3ATBADW0B5XT5',
  'SPAWNY38R5NF44RJQ6JC9S5KSKNNT0PPSMTQQ16T',
  'SP2QAB2C58P13J53RP7Z3BB7HSPCM66W4KWR8W8R7',
  'SP2A540QEXKHK77SZC57BDFAX3AMRSBHCJK7VE5YB',
  'SP29R6SNPAZDGZZ8MJXXZZ1HP6W91EFNN4M2MSN8D',
  'SP2Y3NTZHTRABY9DQP8RPNYGYRD49ZR8ETJWK0F8C',
  'SP1Q0563F6X3QEN4H593QBSW484W9VCZ3VZRHHNE1',
  'SP220W8GCB7HRDAA0JJPXNFFNGAJS73DYSFZC7SR0',
  'SP1EVPPEPSE88K3K3ZDDM6JF49G3AE898HBFAB16Q',
  'SP37K13FZ32M00EG78BTFV5BC43X2Y1GKWNR35CQD',
  'SP1SM3JEASTFJCTG20TKWM1BN2MXPS3A7X38K6A20',
  'SP3779BP668S472VEADC5VEPJ295Z81K11TAHY104',
  'SP27JJMTA01AW3XX8MHKZB8VP2ZP8871Q7PK1KPYR',
  'SP2MEPPNV7YHYQQ0V3M4SF1X8F1X9D0A5HTN1TJXW',
  'SPV0S4NGZ5P3EJ52VV0WC956PTED9KS4XHQS5HY0',
  'SP8PW7X49HWE9ZQBYPN9MCFZ38NT98PNQ6W7VWNH',
  'SP1BZZGJ2NHHX6QJTPFT2Z1W5ENTY6JGNFMAW441N',
  'SP1SDGEVBDDS6RNWFWKZY4FQX4E8SHCEFPFP3DJC2',
  'SP3B6RYQ1S3T6A9RXAEY34R7W2C6F857GF2JRN2AJ',
  'SP35MRQSHRZES247FEGM7RCFYH9QYH1T6ZSVYJ2YM',
  'SP169007MN9HPSM4BC1EGVXPTCYTY6KGDM1MA5WC3',
  'SP3TYY6273KK4QB1DSK9QFZX6EY042528EK7EBE12',
  'SP39JSH4G7T4CBN29B2KYJZ09QTGCDAS58H4YSVGE',
  'SP321DH96SBG9MKFNC6NWK234PG2JCC5VKTSH8AZQ',
  'SP9XKFETDDVXCACG4501SDWGV3R8AEDRMKZNHP4Y',
  'SP2Sb5g2xkx7h6h5ph6fkwh3fy80821b4jh2s03k4',
  'SP4ZZC9B09698CSPHGCZF6MR41F17E72MG06MKFR',
  'SP3JYXZJ7DF8FVCNTC3W8KWG3XXFK9Q0KFVC5BDM4',
  'SP3JZTDZBKH923DAA1MEC8Y1822XG6ZC3PHAD5YGH',
  'SP2JE6HKZTPV1ETFF311VYFH5WQYBMWP94DMXN5G0',
  'SP1VKZNP9CGQE8KJ2RRSX0XKRT1TX4FY9AGVR4E2H',
  'SPT2X3Q9V7R8N071ZKMGE5B26X47WC2GGC53A4FE',
  'SPW9CCP4VEB8Q3N6465SAY5ABH6M0XWDER6FA9VV',
  'SP2QN3SGNT8Y1W7RJQH4EGK31A7MZP22BXQCJBNCP',
  'SP1E4798MP7RNHPVSBM954MSS5EJNM1AC3R53DC31',
  'SP3QKW6QH71HPB8R9WTH9V5QJD3WBW7J5DQDPYY7N',
  'SP2XTNZRESHZGN3Z9NC5QQ68X17YJ0SQ7MQN28G62',
  'SP7TFRHH1EEAN46N5GK6CJ2V8MY7KCT8WJCKQHRH',
  'SP59YR7W1J8MRD1D21TE2YJCSXQ9FBT7SFARXFVB',
  'SP1AK87K9JTBB4SE8BTR8ZNA9F3XVEZMR0BAWR6XV',
  'SP1YMPPF5NM41YP6WRCJJ1QKXR2CXV9AA8FSAJJCQ',
  'SP3QGPVPFV56ANTG59BJ6NP81PVV19B49TPMD2Q9V',
  'SP1YY420PGKG1XAAPXE481AGJFHTTYW2M3YTS3A0Q',
  'SP25WDYH4SYNQGZ06HYNQAC8HV2HMPYYDANJND6HM',
  'SP38PH8NPE6RQT2A8J75PNTAFYKPKDN1J8Z8SPFEF',
  'SPS13B4K65NWXQ7EYSD22M9386Z3V2112EQX1H4Q',
  'SP2V9PAW16G678SPGCDGZ173BW0DFH7H8057BRDP2',
  'SP2V9PAW16G678SPGCDGZ173BW0DFH7H8057BRDP2',
  'SP18YAT47MB10EF7D4PHA0JAMSK7S28V73QR6XGH5',
  'SP3M439E12NVMB5B1FFSJXHNSYXEGV5FFKPCXXN44',
  'SP3E9TEVED7EX8388XH44E20ZCKC5M09NJMVG2JBV',
  'SP2DHB2N6X9XAGVXNGY42YMSA4B9K0S5TSMRCM73Q',
  'SP2PH4FTKB3WKEAZ77TQV8VZH7JG7Q33WYGQD9BM2',
  'SP1N2V79HZMZ10CV5GH12T8DX979TRN3EHCTFGC0B',
  'SP2P4SFDFTAMSF3MP8GP84K9HPDJ7H2GBNQRA371R',
  'SP33SRM0JY04SH8KQGMXZCM6B7PG8DYEY4XWSN7V9',
  'SPY6C1WFGTDNQAJJC2Q58HQMFX3EV8DFY3EJGSCW',
  'SP21F3GRZE1ARJVGW7HGC4THQPP0CK35QDBA6QPC7',
  'SPQ6400WNE7NF2VY8GCXXH5ZJ3QZDYXB49T0F3QQ',
  'SP9KRXP3GFJEQ7ECSTQQY504H0T2X21Q74AE8BW1',
  'SP371WKV3A2SGWT3VN6Y87X107Q447C5DRACXB7H0',
  'SP626566X23S1RYAE7F7Y9AGZJH52VZTT320MQBT',
  'SPEP2Y5QCGJ0EARVNZQ81CJHYWJXGCF6GP91JQWS',
  'SP29HTFNE9GWDVCJ7S7293F4C9XMHPKEK7H3RTQ4F',
  'SP25HGG98BM7EKGNZ9NVPPVFC40XZJYYGK3GNWVGT',
  'SP1BSCFQRYSBQ63D9DVKVTYAXEMCD930S10EQQPZZ',
  'SP2CN01S774TZDQB2HHPA5HKGV00QJ990BFQGZ27K',
  'SP4ZRRYFFQTPV1G0ZVPFQD58SCRY6BEWNR7VDC4E',
  'SP2455T7FWEFM2NST97N6KDJ920GZ6AS9PJFVC6PZ',
  'SP3AWE771HSHKVAWXJKEFRFP79SB8FK3H8E6YMSRZ',
  'SP29RFJD6P6RG44P6J57P2P18D1F9BXVT9MMXYGPD',
  'SP3RQVBVZQ9WC6M4NM68QR0W9G8YV8N2P323Y389D',
  'SP31YS109H2MKSHXH78B2ZCT4RSX2F4PCE6EF6Z3X',
  'SP1934QK1ZXGCEBB3MN9Y0FNR20GMDWM3Y5P5VV5',
  'SP3E27T9620SB35RWDTT2ZC5CB1AHGR5N52R97WH1',
  'SP3B0PYZD7EX1SJEE7VT1RM2Y324085CTTF7Z02VR',
  'SPH6AN5QPE31W5X44KZKNY3NZZJ52QRP250CKMXD',
  'SP2NV953TABCH5EFM2X655M0BXQ9M79XDNYCVFH49',
  'SP18HJQG9BAGG5RYYPFSCJVSK7PAR4AKEG1B7T3SH',
  'SP245A450BFV3NAWMS6FKW0PNXAS800YY8Z2KD8PA',
  'SP302E5HK6CVTN4JD3VC852XDSRRZ1B9EB9QGYJC4',
  'SP2R04STPM9DJD86XPQGMYH7PG0MR7YZKN7G85ZRP',
  'SP87H2YS5D1NN956VM5G9PRAV5A7X52NDX3GM3ES',
  'SP2NJG19Y0NMWDCQRPHCZGADW6ZA5EDXTN13ET0YD',
  'SP2EYP7917X5JVW66B8330Z1K08XP73PXX71A1K00',
  'SP20ZAVGZ8QT6SEGWD3XD94NXZ3TA6T2KSKQYVJPA',
  'SP372F8V36B9BADAXPXEAH16ESNXC9T6V5QFGBZSD',
  'SP31KBGD3CYC5FFADFDH1H5H18QZ8T5P74HM9NH3Q',
  'SP1WYHPJJVN3P0PS32BMF33P6WVVK1SNRRS28ZF0G',
  'SP2V4GKQ28G0J1640870FNJWQ5F7WCJMFKT16X1TQ',
  'SP2HWAXQACZ78FCGQ7Z0H2V71HVYP51GNAYRNZB28',
  'SP1FV4FZ8D32S7GKYRPFWK6YHRJE5BZEYKABK72Q3',
  'SP4S033WJCAEKQ474AKEE4GGPWFYEMTGVR7WT4ZS',
  'SP291N9WH1EBKDPMAAN607SWQNACSSPPC5KT38HPD',
  'SP2MDEE7BMXWTNST6PKE8MGP2EWD6412ZNPTYMQ5S',
  'SP1PKK6KJPM826D0X6AMCJ63KEH2M456M4T22WAPQ',
  'SPN6VDWZJZC9FM1N5Q94B823FP52GB1JZGHB8W8',
  'SP1G9PZMQSFRQ7Q98XP7JMNE2C22RXK1W61RXTNKP',
  'SP3RBTB63ZW9CYSJE9FJP6CNTYZAKMPWH7GY627TX',
  'SP329927K7GVYDSVC8M2NH9PT7EKH1Y1S6JK2KR1A',
  'SP2GKPENKXK6TG163P1K9XJG14MCPDP4E1KTW24ED',
  'SP3ZHTYSFF12T9FTY28MJ1E1YJXNBE66KDGR2PNF',
  'SP2RNM2ZQMWTMMHW1ZA67NRFEK4H95J8SAGS66FA2',
  'SP3TYY6273KK4QB1DSK9QFZX6EY042528EK7EBE12',
  'SP169007MN9HPSM4BC1EGVXPTCYTY6KGDM1MA5WC3',
  'SP3HS7ETATVZF203PWQX51MAEKNFGYSJTNG4ZWG4V',
  'SP14F30DZDV6DN6Z2PVH0E9V87FJD6Q8G48CTQQBK',
  'SP1YGBYDG771BWP0M9FYAQ1MMJ4SPQ0FZFBKYSE34',
  'SPNDT6MKXQ1D9PC82R9C0ZJ931XSDQ0SDKFPM7JN',
  'SP2E1PV20KS54VRFVZCE2EQFKZQ124Y9C6JE670CG',
  'SP2Z4W6J9SS4001AM1Y92MSXYNA073GY1PAHQSQE1',
  'SP3ACRG4DNB6D8BS2XHEDYKE2Q1GGK9Y2VA8NJK7W',
  'knowSP1WNXBS65ZZTQFPB5SR3X8PH9X3VY48J1MRSKT7A',
  'SP2AG959T2KY3JF27RMF4MEBXSE3TNHCDR9Y11629',
  'enterSPC86SJWPS3N719CPJEWJ1TANMRD54N4AZ9FWJ47',
  'SPDTHYS02FZ128YJ1B134HTZ1V6G8ABVK925Y41J',
  'SP39H2PACPTF1X6J8FYRH1WKK59KKN792H5CTVFA3',
  'SP3C2S21PJQFTE5BRE7MTJM836KEZMJ2B65ET7KGR',
  'SP1WG3K9MBB9NNCN35ZT5ERGW09SDQH1168N2R73H',
  'SP2JMNKT6C7XT4KBQ0D7BZCXAH9P8R31Y1CRJ46PJ',
  'SP3P8QGR2V6TA8WC7703DFEEZEVBKJSB8CQMFZR84',
  'SP1MY5DSGW2F848HPFJMT5CNSTSP9B98ERDZ78V9T',
  'SP3B0PYZD7EX1SJEE7VT1RM2Y324085CTTF7Z02VR',
  'SPFFNWYJE3C6KY0YY19V4TA0976M61YQA8Q1218B',
  'SP3B9R0NGDY4PF050BGTPSPJJ5C15TXJKPZTD8V8R',
  'SP1F67C8C6KAHSYPN64653ZAJM7KVQPG8BFAJVC2H',
  'SP3EYPEMRQA9VFBG8NYF9F7D5RVEQTRCRCZY3Z8ZJ',
  'SP4CMB9WG0EVYCG75B5R8XNQYAQ60EPTK7RREEYZ',
  'SPEJHKZ80PMQ1XZFS68VANPY70T50ZT3EGXYRXHD',
  'SP1JT4B2PGVYME7VBF3VJTC6GBG26PVTYYT4AEKK7',
  'SPKTZMVC876NFYB9RAZC35H95V8V1H773VN9QP4X',
  'SP33YHDX5YGEX5TR72DZHYJS6P47AV37VRYFK8K8X',
  'SP2F2W02XST3SYCHHN1YZH4CKPV690AZAPX45TSZF',
  'SPSRKPW2C0YEHM5MCAYVVE399CCCEXA0K9E8C7E1',
  'SP3T8QFCEAMYNZATHBDW1WWVAJHY77ZYHT8MXR6N0',
  'SP2BG1ANS2070TT3RNTAV0CKTA454272AWSMA5SN1',
  'SP3FHY1QWQKARS59V6VZDTQQ2VSWZFP0F50NCC6S6',
  'SP3Q1XVTT5WAZS3J1971JNF8DR38DYFV73WKZVVQS',
  'SP3Z9CHQCFZ32J63ZFJDZTSNGD88PFEJ33WMJBGN0',
  'SPPCP25HHGQ5DJ7DP5RN53S5ZTKQCN3HW2ENC6NK',
  'SP31KT08Z0GH11R002C252Y8B036F2BASH0DQ75W3',
  'SP18A13D0YXCPWE8YPQXZ0EZ8G3P5M67SBG7EKT7C',
  'SP3BXDKRE1A02NQBYM1XKPAJXR7TRFRM4Z5R909B6',
  'SP3K8MEGAE82QVTZNRSJK1H77Z3J205FGDYHDV599',
  'SP24HMAXH3VB1G256WY62F80YZ97H8T22PT11QNPV',
  'SP186KF5V728506RCM92YEDQN89S16M3KPGHZT3NB',
  'SP3HGY33WFK57KZYV82JWT1YKMM8D2FDXK451VED7',
  'SP1WDVZBA6ZYETNFYF0N7Y366HVSV0SDGCPE5RQBB',
  'SP34MK2JJ4GKR3M9DNW181DWJRS9A0FGYYM70EDK2',
  'SP1WDVZBA6ZYETNFYF0N7Y366HVSV0SDGCPE5RQBB',
  'SP21JZSKVM1DMRD0E39PFM2KM215DARED7QFFWQT7',
  'SP16DE9N9X6Z0ZHZJ51RY4CC2PCS6JARG7W9Q785Y',
  'SP2B49F04SQ5MDVDF6TNCMDRKEV5WK9X3TTEN8RK5',
  'SP20X1J3DQKQMGV4K9Z72AWSJAW31SE1M678SDWR3',
  'SP3R5TCK97NMBS1V1MARCK0YTDFWG1FKJ94EFQTF4',
  'SP18QK4T40DKYQK5D07NGWXY92NGA5VRXW332PCP1',
  'SP37VBPPYVZRPRPG4R4J25RQ8Q6DJK2VG0KYFV47Y',
  'SP2AH9BH1AQCAM5G0G5CQ3XJC5WD3NMTSCPZS5FJ8',
  'SP28YMSVYBM6XX2Z0AEK6H754A06AH7YPSGQCN78Z',
  'SP3J2A2AR1A34DA3QX4AX2JDQ45NE1TXKG8Y6P52E',
  'SP2QDH8A7RNMYC0RCGW9W38J9X7F36RGEH72GJNFC',
  'SPBRD45GVGRWAH0TM7JGXQFGPQ5CSPDJVYNHRG9P',
  'SP3EVHT45ACRN86XRMYTJ95733DB9N58WSZ5JAYVN',
  'SP282MGBBY1ZQRBRY685Y8AZ44V5TRYX0W9DYYNSW',
  'SP13B679M39G60Q32JV3X4E3827JEZ66PZ18J5FGF',
  'SPPV03QCX3HWTW94D118GXN6F66E9Y5WQQ34XR5Y',
  'SP3ER62X9PAHFGTZPS95FWPA9Q5GSYKR50H229WKG',
  'SP2Y7H9P8N8E2PJVNJ13P2SB0QRRFA4RVEJKBAQ10',
  'SP29PGQAWX59DHHXADT9QA1HV8JAMR273F5J7CMRJ',
  'SPGEWB9WHBVNHARBM5ZMXSBX9NKV2221JE772AE8',
  'SP3ZTBH83RW795JPMQKACRMPJA1GXR2XWVVT58AY2',
  'SP10R6KV7AVQ411CFYDC98999Y1XHXADSVAG7W9QV',
  'SP2JZHPYW51Z89NSKGAEHMCMCRR2DXW1JHSARFX52',
  'SP52Y1G7855750KZ9QR6Y4F61YMCEFNX2K4TJE01',
  'SP1AHD5MMT6SWEP0FH1JDACKCAG7YQF5PYHW0C9W8',
  'SP1FXCKY6E03H7HYKWX8Z0DGJ54YDK5CQH1EDSAJM',
  'SP2SREZK1STG8ARR3PJ94NTFN9YX04PJR0344PJ1W',
  'SP1A81VH1E9FVCC504TTG0QE3C7AENGVNR18AA5AF',
  'SP1BK1QN2S919EFNWX52Q1VRW5ST76DMCPZQ8V4ZM',
  'SP348HP22W25CD6GNWGJ2Q6KMGPWPADKF6CKGG1CX',
  'SP38E376EP6S6JCYV372H2TYCZDZKEFNVJT4AMR7Q',
  'SP1XGM9EJFBGWD1NR2C87SZTW1S1SVWD02S5J6823',
  'SPRNA4A9JBN3HQTNYJ1N28KDT3X6B8QGA5AG4E0X',
  'SPRNA4A9JBN3HQTNYJ1N28KDT3X6B8QGA5AG4E0X',
  'SPFX5WKZZDNS44S2JCYFRJJ6KRCA7S2NN57HFRK7',
  'SP3RT7HAQ6221JQG2N2MVT4FSHYZS7QVYGEM26J4S',
  'SP3A64CBPVJEE9TGK3D71WDZDPS7BM201NNEZHNQX',
  'SP14K7YYMMBVQZ34B1CF63NQDGXE4QKGCV114XPDS',
  'SP8BX4W9E3JGE474BXDTF61D0GESNZ73019FF6E0',
  'SP2HSTSEDR73E2FM0D5EZ944WPY1M3FTPYYT177Y7',
  'SP34J405PJ223C7GB5KF5RMQ2JJ208M4E4Y8VCRXQ',
  'SP1FR2M102H4DE4DH96R4D29RC8AGQZG5D5Y4S7CH',
  'SP3G8937YFG18PXF9P646DW71RHFSMJWWSV12SNSN',
  'SP3A5VJWA3CH4BM7W08APVJKJ8MQ7PXXFACWAYA2J',
  'SP2P2B3VN47GT79ZRBDZBSY3W3AZTBPZKD6NZ9D5G',
  'SP392JQGN7ATE9TSF6MTY7FWFA89C3W8FHTGCCA2',
  'SPJ41EFH97BCXNEMMZMQHDMG9HHA52MHYZRJB1HP',
  'SP1KWZ8MQA40FF6EXK4G6SMF06B6SZ5FVKQYC5YQT',
  'SP4DQHVX11XXG4XN97HR845G9V51GKGS05Y86NH6',
  '"SPNDT6MKXQ1D9PC82R9C0ZJ931XSDQ0SDKFPM7JN',
  '"',
  '"SP1YGBYDG771BWP0M9FYAQ1MMJ4SPQ0FZFBKYSE34',
  '"',
  'SP14F30DZDV6DN6Z2PVH0E9V87FJD6Q8G48CTQQBK',
  'SP3HS7ETATVZF203PWQX51MAEKNFGYSJTNG4ZWG4V',
  'SP2FS4ZFK7S6GK6E2EB07Q20EKSKGNY5WXPJGDTRG',
  'SP3MZQHZDX0710MAADWJTDNWQKB61CPRZE4M6XJX5',
  'SP1QGHS0PPQF8MFQ6JR29PCY516V7J8C2MNTQD3KQ',
  'SPK9KYYJ73REQ9WGC2790RBZ72NY2NPSRW4SJMGV',
  'SP9VG7ENSCNW5H7R4YEJB7J5ZVJGFW0AE61B9S0C',
  'buffaloSPVGABVA62GM02WX99DHX26MVP271HR4P9TS9F6M',
  'SPR6STENCPMJGJ3QBAF8AWDARHES5VT5TB57KJMD',
  'SPF556QKXVDARH1K9G8A7F2BMSKA5B4FZ2DQM9WV',
  'SP1KBVBP3AZP7YA968Y3G14A17P9XXFPBPEVF5EG9',
  'SPF556QKXVDARH1K9G8A7F2BMSKA5B4FZ2DQM9WV',
  'SP2QK20FRJ6N1XZ0T6RGF180RVQ62HPR1NAFMYTVB',
  'SP2XZXH4A7F82FJY2J98Y2V90S05Q4HYMNTNDS50G',
  'SP313MA8E3V7TRZJKRQFWS7TPM7YBX7ZF603XN00R',
  'SP39WRB2D5WS6H7JG5P10R76YCYCTF0XFHS5XCN47',
  'SP3BP1V6QYZATWY3JE4CQVS7MT3JX41CG7WRX0YV7',
  'SP100Z09HAAHFY5T99JEK27A0YTPBKAV9F25MYB53',
  'SP31J0HW0CRSBZNGMKM1BQRAZCXYXR61RTX3H62E3',
  'SP3MGNEDPVN77VQT86SE9DTJA73QCYVT6AMRM3XCC',
  'SPEMEPP2YK63YXJGWP4ECEP73A1MQS88WA3J5XE7',
  'SP2JNHVR2GV9B0FW4T7K68SC3W229XJKEGFW53XF7',
  'SP2VG655W5QFF18VJ4M0917DVDX27716Z2GVQ6HP5',
  'SP14MS143SX43GQRVCHKHM7KZYK4XE346S8BS4BA',
  'SP25WGN11VMJYP956HVR7T78930MZN5PH6WGRNQ89',
  'SP11B1HYK8AFDX7BWJSX5CM6RMG1WD26K9PZSF3PP',
  'SP3X1AT1ZWXVWM09C4S7DYVHP27PG981C8Y15AX8E',
  'SP36HJ13B501A0TW8BY7M5KXPS3234AM7EXS0B8QM',
  'SP1NQGFG5DC230X5ZB5MYAZQQM3CD2Q0MHJ24D0ES',
  'SPCTSC4D62EXN0HF82W0VM1VDPNZNKD6MT9J1BDT',
  'SP3RA8FPDKK3CEAMV9NAK403V7VTNJYNR97Y520B9',
  'SP1GAQFSFB7NS5HSM8QA2SMDBQY6DYG1M24H1CVPF',
  'SP1HNJMJVWV1FZG0NZSAW1YMW2DHVXEFQ5MM72MEX',
  'SP1YVF9EWSK6HM0JZR4B3KCM7V3NKVE18VVNFSQV5',
  'SPYMNJMTETBD4HK4E1KXT9M7274TX2FZMYWM0NFZ',
  'SP10DWX1R01M3B6JHQGMBVK00T1GSJ0RTERC0BTV0',
  'SP2Q311XTDBECZB9QK6Y37A6P0CA2VNWMFBKM942T',
  'SP2XMYH3T4MGCH6ENR5PRCRX4BS9P1ZY9862SG0V0',
  'SP7JA9AY4AV8SG4ET7JCN19474S0YF9AN52HG0RZ',
  'SP2STV54509DRVAA57PH9Y3VW8JG2VPJGYQA4G95Q',
  'SP2JX1FKFQS8CEF5TAG3JRXVYRC7KN8SANXNZVTJJ',
  'SP30PEJD4TB2T39JVNGNVJBKSY43C7XQV2GD3566Q',
  'SP3CTX73TEN63R7W4M96CT6JZGTYSQZV5FHB754AH',
  'SP2YB2FBGW8QZ5YMJN2KA04KZP19S3BG8H35J3TA4',
  'SP3N380HP9V70QKB5FBPWY19MV9SYB2GDN9Q8KF4M',
  'SP2SFVDCY52N31ZM5RMB3ETG7HQQKF64HQQ2BWWNN',
  'SPRDDSP55AT0YB4R7Y2NVWEXPY0S5X16B6VTZZKR',
  'SPC7Q7P3SNFPAF3F87DDEVAHYBJS323YAYMJMAWB',
  'SP2BW4TBZS3TP650YD7WR3JF999YN1E6WBQ44YFTN',
  'SP2FW9F9STGPHHBY30MPPY7FFCWJ07M5VRZY8B7HT',
  'SP25XPNAHPRXQGCZ8F6JYJAF0H2QBN0KPQDGZD6SV',
  'SP234ASHQMS2CNNYNZ3HYX870TYVXPMEYR7TYTFEB',
  'SP235Z2479NW63FG5H1A78JFYSNXS52F6FVSDBJP6',
  'SP3VTT4F0DKG0JE7J0MGDBC6HZA02X67R9KNXVZ15',
  'SP25MKFCH1M0H42V1989JMAM0KFJ67V2HXFQJ4JRK',
  'SPT329BTQ4341P0W9QMED60HFVZ13SK2FJR9QWZK',
  'SP1HM1N1X20AQ90XQWC329CAN22RGS130C2WYKQYN',
  'SP3X5QM0JB233G3TWRHAEXBBPQRRG5RK8F8APXZ0A',
  '325JEvocgVfvtJiWBz6MJeL288e2C2AwXo',
  'SP18MJE0D4XK6KCD3E413C63S521654ZKTY9WWMZN',
  'SP1QCMQZNJD9E3T3EPKYS12GXXF8RPAM8173B683R',
  'SPVF8PF9GD644JFR0JJ3MX940SMCXJBZTQNSD5SK',
  'SP2C1NCMMHFJXXTCAK3KYYJVY9VV0XH62RMEJNX92',
  'SP226P4V1G7T6KVD39VMAGQV6HXKDM97T009AV579',
  'SP1KBVBP3AZP7YA968Y3G14A17P9XXFPBPEVF5EG9',
  'tz1duKyHB69ZHA2cFbAxFgGTDyCsGyfo2Jbm',
  'SP1X6M947Z7E58CNE0H8YJVJTVKS9VW0PHD4Q0A5F',
  'SP2ZWP17K3XB7486JCHFVPZ0BJVPDBBB9XD2K9VFE',
  'SP2KRHG0GCJQY7NKBS7C67XAH9Q3QW3TSTHHCH3CH',
  'SP3R7Q3QMTYC4QR0RFDR9HZBWNBNBNDA7S549CR6Y',
  'SP22VVY6NQ4PCWC0FDKWP1TKN5J8SEWCS64TF3Y43',
  'SPSD04PCT48NHMTP8BP0P07DZAR33J6RN8E5DNV',
  'SP15SPP0ZK0DY9JQ65H111N2RFG1KRA3D7G0Z838H',
  'SP2KG1PF5ZWAX11TY58XYDTSRBC0P2AQGB0NT15DH',
  'SP307DJGGSKQ847P97FXZJK736R5GH90NJNBC0K8F',
  'SP1RA9FCQW3PKQ325W6754AW87ZNBK7ZXDDMM1KHA',
  'SP2VSZB9BEWBTWVVB15R8SW6YBWPHYF9MRFJ4JVQV',
  'SP3TN2WB4HYDA2MXCZCW9Q6166ZYX4NHVVA2S1FYV',
  'SPCZVBQJXAVT0HNA0XJ0A10T48JMN123XDV5SZ9E',
  'SPSD04PCT48NHMTP8BP0P07DZAR33J6RN8E5DNV',
  'SP33FRJTAYXHJ0RMQF5MGF1RAA6SSV7NHB8Q7D2EG',
  'SPHK8A7P61C6ASWKYDX1PCDX9YA54DKVJN49EXGJ',
  'SP31SJ2X5683KDX8P58HWRA2DXY3ED4WZ6Z3DM0A9',
  'SPV9HNVRJ6833QJVN3KD9T1FSXRJSN842M9PJ02V',
  'SP13ZDF0VZBZSJ19W7DXNHWJDBCS9WSZB880PQXQS',
  'SP27J5HGXHQF7S24FW1GAF6CP66KHMH9E618S8EDT',
  'SP2M709893RZF75DCR7W4XY2A3BYYKH4YMAGZM32H',
  'SP3PX3W4MZMZ0DCH01E9N81X0Q6393GYFPAC04PWC',
  'SP39QW5KC3N0VB9J3YF340YN9C72EJ3ARX5NN6ECP',
  'SP1CVHMVJ5KWW1DPJV7X7CJ9QW2FCYJ3KR001WK9J',
  'SP3KCC9HSDZ9QFN0ECCN5TBFSV60TS38KANBBRC9N',
  '80756930442357700000000000000000000000000000000',
  'SP2X6WHVKFN0JD7B4TGXKM7WDM0YP8E39D58MXYSZ',
  'SP366KB4QVQP68D2JD9K2RJF074V1ZNSAVPA4YT70',
  '948887263898688000000000000000000000000000000000',
  'SP1Y52V4KKMK45207ER9PVVMKECGAQ0272PS8DPA8',
  'SP14E544B2FY8BSKTV5V7W8NCRYX2B7NXRQ7B7NJ9',
  'SP8EW8QY6H1SSWNAS20123XWQGQ8GZHEHSAE0RP7',
  'SP1SJFW3TTRJ9QZM2J53GSNV7C6SDJKCSE32HT9XK',
  'SP2YCRG9VNTAECNP2NMPWWZD9K95SGS9PJDYYVHAJ',
  'SPMJ3RHXD5GGJ6KKNFV24ADMXZ7K1S3S9ZCKJG4X',
  'SP2Y8V41F6Z93GVTBHHE0E0ME169KN14A60V7ZYHZ',
  'SP8J08AXNGX8XX6JVVY6JFT7RR7FGDTVFRQ6G8WQ',
  'SP3RDHC842EJ6HWNRSW2SGF96TF4D8AYVT0HPJS2E',
  'SP35MEYYBHSFCFXY296YGP7NAT6Y4XBJW2VETR8AV',
  'SP1V66N0ZHZRG19E8HNB0YERK52APCP3NJ6TSNAAX',
  'SP3789DGQD0GJQ8JFGZ7FFMJTC9T0STRTGT1WZGG',
  'SPT2X3Q9V7R8N071ZKMGE5B26X47WC2GGC53A4FE',
  'SP31T6XXSKRCSKQSFDQR5XSZAXBZDR23BMMKXHARV',
  'SP2AEY9QJD5MGDEEYYTNYBVVS7S97W2S0302HQ7S1',
  'SP1MFKG17M5H4GWAFPETN5A7P8ZJ8BTVMGJBCSS4Y',
  'SP1MFKG17M5H4GWAFPETN5A7P8ZJ8BTVMGJBCSS4Y',
  'SPWGPEYXBPJ56TVSFTVM9V1GZWYNG9SXGXMYEE7W',
  'SP3A0N3VJWEGR0DZZNXD15FMRYRMBKNPXDK61NHG6',
  'SP3M6D6M2BS7FNEFV111ZF6WQYATNJZ89Q7MXSPAE',
  'SP3DD84BVREMAETAC6S4PP34P5QK1XSX379119B94',
  'SP1MBN1J1TSMPCHCEXNM5PXTR29FEB4KTZ1M8TB9V',
  'SP2132VJK6NHGW73PQSDH0QKY4CMT5BQSZ75XADDF',
  'SP1JQ5H6TQY47CBN5J9YAAZ6QX8DSYWQNJN6MQM9C',
  'SP1767T13ARFJX2RARNMR5H6XYTQQCKCB6Y6PE0MJ',
  'SP129SXC2YE4VM9ZXWXF1WSRT8M5BGAEZ0PQK685D',
  'SP1K8RG4PV202FHT8J9023G1WJRPFTSZXN9TPNEJX',
  'SP1A967GSS8V1HCGQWTWDHFRQP51K1Z7S8CA4YJYR',
  'SP3DJXTKT88V4SWEYDPJNNWC6NGAQQG32X0GJ8W4P',
  'SP226P4V1G7T6KVD39VMAGQV6HXKDM97T009AV579',
  'SP226P4V1G7T6KVD39VMAGQV6HXKDM97T009AV579',
  'SP3DQYFP84F83X3GZXWSY58R8ZSQ3EBDWWV7W4JK1',
  'SP31J5PYBW08QNTTZRZTKR0V6TZFXSZKQXM8HR6EE',
  'SP34XEPDJJFJKFPT87CCZQCPGXR4PJ8ERFVQETKZ4',
  'SP9J6BTSPCXGQ5HC066NRYQPK43S48V7K299PTQX',
  'SP20XW2AXT77DCDMK6A46E8W7RGJYHKKTKJ82M75B',
  'SP2WS1ZNH9GX7X8CTVBKSPBGN33H4HWH6RZXKH1Z9',
  'SP23GTEC9CSBCGX9308TXXR2DMD7QA3HDHJHFPXKW',
  'SP19FW37SB7P91QSVB7CTXJM8RXEFVMRE2SC8GZ78',
  'SPVW9RM7YZR0Y6JC1JWAJ2ZNWKDEWDG7H6B5DKZQ',
  'SPA8RR63S67TJX9N2FVWG330J8TYR6W3B15W471Z',
  'SPKHC4BQ5QC5GNHHJWQQSP082GT5KG6VJA47QW43',
  'SP3XW4E3DJSEQ3Q0VB0RM6JSH5N713VHM1JD9DSNJ',
  'SP1RZ3000JCHNMDFM5CQJ93E5DP03EWXBW16G775Z',
  'SP35K2KPJFTMYS0YCCFRFNGH14A84X7AYV0WBQ8CQ',
  'ST1N73VJQ4RPCJ4KVEAXXPQHDACFJY4HSVMKSM2',
  'SPCXHAET5B198A7ASPRMYDQEWXMBXJCF9RRY3KT7',
  'SP2GX5T7G2JM4NFKRC47MMP99XE7HG7J3W34Q4CBW',
  'SP1B7J71AJYYG77Y1H80G7DRNJXT4J39164JEHF8X',
  'SP2HK7J6617VBSKXQGZWMXP2R64MMDX3S54M0S1Q6',
  'SP30VMF712XSAVCS56Y0FN5PBVEZYTT0E66TC8P6A',
  'SP1QD67H1AQ6CNJJKJAFRGC33WVK86RZ8TNW2RDNB',
  'SPVF67F5BQWX5EQEHXAT5N791JAB6TPXXWTPA471',
  'SP3JAP6Q9K5GRV47W71Y8H4ME1JT07ZAJQA3V33F3',
  'SP96RVT5F6ADKJRF6DRXCR87PPVA2W2T7AAV8FCZ',
  'SP22C281THK3C7014ARYE8VGPNV9BETAPZ1AR0QR7',
  'SP3HAEWNWKKKKH5Q6S52VK5DB91FTPRTDK5402GM1',
  'SP2FXSQN477AC6TG08X1TD7X7GKYQ4C2BQHWT47K4',
  'SP1HS4J6A1X3GZE1HR0HKPTFDC4DZ56EZ6KBBCG7J',
  'SPEKX6PPWEJ11M9MSYTD3QR6V3ZFEQYMKPB3WFQQ',
  'SP2JCZ9J1M48S9BP72WABT5493EFBTB17VYTKA4PD',
  'SP33BYZCZ1Z129J6505EZRH8NEMVBA6R4ECCHYCJQ',
  'SP1CH04C3M2WNESWNVRKJ0ZD16ETQCPY8WD414Q9Q',
  'SP29ADDZYV8JJWDPR1EAD1JR4RRN1M4VFBP4H8H94',
  'SP25KQCMCSK6H210EEAQNS7XHAXEV6CYKP9H2V9R8',
  'SPYFM7DRW1PCY6NSBP2Z4DQ67WBM7R2QX7EKHGD0',
  'SP12SFE9ZB6A47KMVMBGTT40WQVCJQ2DQKTDGDSV3',
  'SP22V27C27T5MP23C2JEBW77DBHBZEKM8RZ7HCRDT',
  'SP34ZJNVZCHM4JGD9HXXNKH66NKKC58KHW38QSZTT',
  'SP2RCXT3BVDA3RQ0S00PZZSVHNFP9D63QHH3M6SV2',
  'SPNX73NV21H8AAW7Y6D15GBWFJQJB23VXPYP86P4',
  'SP2Q7ZD8YV62C801M0ZZPN92ZT60BVW0RJD8878SR',
  'SPVFGC83CGGTRG0Q9Z5A7TX3NAETYWPX8TV96NPE',
  'SPE10S69JYEF3HNT6KTT12BW3JP9N1G0E56T9A2Y',
  'SP1DY3AJ05Y9QBE2BFW6AD0F74X3WVGM380RK6743',
  'SP2H9DFH9QWZGZ5FYHGRA4SF77XPKFXX638HZGZYH',
  'SP1FFER1MC5ZZKSQ8Z5QKD7WN4W0YG5R1ACH3X75G',
  'SPRTRJXJKMRKFGPCTYJN1XHJEJGGCBDNWHJGW0ND',
  'SP1E7Z3594SPWP0JRZRWHJ2YNMNEK43FMPBW287YG',
  'SP2GXYD9JTFT5311GXJN8B2PKT8N958VMHPEK29JS',
  'SP3QWRPTQN2WXHQR47WHRTW856HG6QFYVFJKS0SNN',
  'SP1TRFBSKWFYBW4HQB3MPSF6SN1QSTSRZKJ84029',
  'SP35TXQW6AY5DZQMYXFXHN68QWS7EGWSRPWHM50K1',
  'SP3WGNKDVS878ECD62YANHCMPPRWQ31DDKRS3EZ5V',
  'SP1PV1Q4R8EP32X29W0JHEBW20SQKH7RCN9YD81R4',
  'SP2EFM3DRRB07W7PNKHEJ2ETGQ2QVNT43BFH7MMW0',
  'SP22P5BR62ZM8SMFNJN13300FDXJP8XGAPQ9JQVGV',
  'SP1QQA144ZT0AQHBAZYNFSN0KVFPZDEJM9WCGC6QP',
  'SP3AH916ABBGFGBYPE0JTXZZ5GZPEJ5F686V4EARE',
  'SP3KZ7AF7HS2042BS2JNWJSTCQK49GCSDT0D535YC',
  'SP2NCHDFKQ4V0V190K0REMSRANF284QS4FDHTJX1B',
  'SPWY33X7DJ4XBK8Z3EFM0DP5DYRXB8TV9Y2JFT4P',
  'SP229P1FG2T4DQH957DW09YR0TP6RWT04FXMGFHTZ',
  'SP2YMBAMG6697QMNP5D4MCRK6JR7719VK80G917DX',
  'SP3KH9XRAB4P0RT0SR3QD9K3837GED887X9PPHVYW',
  'SP2MQS5MFYPAD05RD44HPNY729CENXDWEX1YFP7CA',
  'SP23Z7AJ0PA59W0RJQDQQD6N7KQQD3JSZPMN8E12N',
  'SP17RE56S00TMKKRJEYVW0RNEC15T0NH9R1G66EP2',
  'SP1XJX15ZZ5J1FQMEHJ2V21FMPCZQYQ50NZ33RES9',
  'SP19J3T6Y07FVEWX4MY9YW88B8BSDRJJSKWDN6V6E',
  'SPZPPYAK4PA6A25YYWK1FE6DPKQ66XTME522V9BB',
  'SPX9SNZRQE6E7M29Z3BM7WZ0HJMB5Q8PFHWMKCY7',
  'SP31J0HW0CRSBZNGMKM1BQRAZCXYXR61RTX3H62E3',
  'SP2GCTK4J5B11TFZAWMF34S1XV999DWB1DBR379VA',
  'SP1WHNS9BRCT7ZWB6ESV2F0BJG5EA8N1Y6FY44RB2',
  'SP3EP46KCZNZA9R6T56HV9DK6CZT1N0FA7AM8EZZB',
  'SP1KE0KV3V7KR86SHBGWDEWBA9SFJHEJ9YZQPPEB8',
  'SP25W11XB8KV9253PJNBZ8ZY6Z8G3N9Q9ZKWE6GTR',
  'SP22VB9N0SD0WZT82RS6RJWWJHNQ83WD8507ZV6NX',
  'SP3E5AGWPG6SDF4H610ANMZBZJG5JAC9ENMW6QS7W',
  'SP24ZF21FNJ2TS20X5Z4PK5FN0YQMBHYXYNFPZDMG',
  'SP18RY4799HBM43KHP3PZV7W4S1E4X273AK81MEXZ',
  'SP10SHQQ3DEAH45T0DAW73BP3ST0T10V67K6KJTM4',
  'SP1VD9JJRDJE1XKS6QBPK59MQ2HEWJRRV4JWQ5580',
  'SP3S5NMXWH933BHJRFDPREP317P5GQM6JZ0ZMJHXW',
  'SP25ME5B1XGR0X41FRQME6FP34HSWXSDA9106PNXY',
  'SP1F3FVND0PE20VVBEN9Q8AX3EB11MZE879K2FPDV',
  'SP14NMR2VD37T06F9QY411RD1B7SC722HHSWATP90',
  'SPPDWNYJBSTBDYQNXSCAT0D5A3VSZ1ZZ6F56RYM8',
  'SP3X0K7N1R16VZ2CX9B5QCJRMXTC7M4QPBXDP3R9X',
  'SP2YXT839VW7KYVXSBGGK1KGG3TVGTQXRQ9PVB81Z',
  'SP1G9PZMQSFRQ7Q98XP7JMNE2C22RXK1W61RXTNKP',
  'SP3M91PQ2VAS67804G7DBMCSFR87V38GTHDWYS6NJ',
  'SP1A2ZBNY2RSJG96YDH37P5P1C0P9RHMC51EVBTM4',
  'SP1WBNM0A4ZVY1GVZMXDPMV831BF0BMHFJK9ZXNRW',
  'SP2TFRXKWFMDNVD80GMCKAZ0K2FDB488XA4SWZKYS',
  'SPKMH36WNMEBRWJFVNTMA6YNRH1WN143GN1RJ4AJ',
  'SP1TZ8FH94HW6J45D6NTE349HWYHMRZ5PAVJ60VX7',
  'SP3MNA97ESX56M75GND7A6RKJX2DGKV7HGRWT8V9A',
  'SP2X3SAPQRGPH445ADSMYMG2M4HJQBSHV8FN8S5X9',
  'SP1JXMRR52D7AEW10KM7NGEZT2B15VF5TPNQ45NNM',
  'SP2QS0MZ1YSKGSN6QJ8QPPJVHS1HPCSQEBP88TBZE',
  'SPPZTBAREQJTPXC5RZ5DAJJQBF7T3T5RF0YB3M6B',
  'SP2P8T8WNQDFG6XACRA1K98QF0Z5SJ2BGE43S4RT5',
  'SP2P8T8WNQDFG6XACRA1K98QF0Z5SJ2BGE43S4RT5',
  'SP2YX17ZAS8ZVRVF6TVNRYFSAMAMBZAA7Q5WRYHT1',
  'SP275YP863KVK3P7D5G82YDS5ZKGRD7R2JDQR0VWP',
  'SP3GNC0ZX4S2MV9AXTB1VZNWZ017JKJY07N5NNJ65',
  'SPN8YG6CPER6WZFV6TXS5E7VNNB8S2GK3BMMBM26',
  'SP3X8Q7Z8BXF7HRA85KYNA2YB4B97RED8SPF0WJWN',
  'SP31DZTCGZ6G1FST93FTGGRK4N244Q2YY53SF5FSY',
  'SP1MSR67WME95NW8ZDWFX67X6J0FFMA3CA5G3Q1R1',
  'SP390SG3Q5QGYZPMZ6C9Q31S9CPXY0MP18W0DEFJW',
  'SP4RN877SRPFZXRBWJBWAXJCY7K27HYY7QB8FM9C',
  'SPTPXJRTA0B5793A92QB098JNT77FWKGGJJ70A7W',
  'SPN7C68VE0HZJ2812V5ZT444GJPZXE9G3TT9QFYT',
  'SPA8ATG7P9BPNK311WMCYZJNPNSKK3AQPEPQZECT',
  'SP2HWAXQACZ78FCGQ7Z0H2V71HVYP51GNAYRNZB28',
  'SP17R69J3Z3Y1P24WFWWWAE3PTV8S84BBBRWH1T9W',
  'SP2PJXD4EFBERJEDGXN86W8HXE1BDMX3S6WNAWZ6E',
  'SP3HRPX04P0ERHVW2DHYNMM1AMNVAKK39ZTAQ00WD',
  'SP3A24AA0PCE2KGSNCYR60CCEPT9ENNB69EFNDP8V',
  'SP161PNT18YVAY2SGJ4RQWZ65N9HJ1JDZS10FTQAH',
  'SP1CA9N6FWNE5BA724DW6BKP65N22B4HD0B2B5C9E',
  'SP1PEHZE1EKW76JXV5APQJBNBAXQNW0MNDNKMVCK7',
  'SP2XV0Y7KY3438MRWBCQDS6NNAGD4E7WJ1674HTY8',
  'SP1PKMEAYY78QCGTZM85B262K9ZVMXGEC7ZZDCZT3',
  'SP1PEHZE1EKW76JXV5APQJBNBAXQNW0MNDNKMVCK7',
  'SP4NBPXY28Q8G2HRTXN0Y6MQXKH5V5DJYMKRKRTE',
  'SP3G3NVMENGGCXSHQ0TXK2VT8SD0Q3ZD52WD48HWR',
  'SP14R4HTSF0VXB71P31SAJ5081Y26MSMVQ6XQPX4P',
  'SP14R4HTSF0VXB71P31SAJ5081Y26MSMVQ6XQPX4P',
  'SP3G3NVMENGGCXSHQ0TXK2VT8SD0Q3ZD52WD48HWR',
  'SP1K5SQEEP9E1DW4BRE9F0VE0FRFX1MHSD5FWW86B',
  'SP9SEZYHT8YEMWPBJ7MCY0XQ5DFJ720RBG6T4QX0',
  'SP1V9HDQK80NS5M69X8JJFKVD6P8DC84AFWP11D9R',
  'SP1EPM32B4A8241CFB068EEWQ5RYACVQNEXJZEERG',
  'SP2A3F89TG42JAWNCNVZ3AWYGMSHS1VEK0VFRV8Q',
  'SPE5XQFBSKSSQE1DR86RJ27BJPH4BD6W4DXDWHPT',
  'SP3ASEB7VN69FP4R75H1H7P11NYXD6GE17NWR3ARW',
  'SP2X2Z7X2SQMS5C7EKP4PSKQ9T2B941PMGZE73PRM',
  'SP12KM5V6VB6Z5RVDDFMBZJH1T930PS80ETJNADAY',
  'SP2WXXANXAH8NTR0B1WP4X9NC1Z98H58ZAA9XF7SH',
  'SPA4RA6BTTTQR2Y3RNTGYER0504TMMTCYK9KQDJK',
  'SP302CMYXQYJY61HXKB72HSKW6DABG4Z7MY0HE9AQ',
  'SP31MVMJEKK9Y71H2AQSEMDPZHEGDQ45HMG3S5P6C',
  'SP2ZRD2YZ593QEEEWSDG80SFGWY9EEWR4W1RRHPKS',
  'SP28A8VTKFWD7ND2V7277E790Y6HX4MM1XYT74231',
  'SP2VDPFG68TBPA8B1J53BHKEVYNEABFGS3GEJDCQ6',
  'SPJZ7H0JRJVZZ9VSESC0PJRNRFJFBCENZQ2KWQGT',
  'SP1SX8ZEH03RRFCBF6AVDHNFNGJ7GEV5NEXWH8T0G',
  'SP1QBSJFF3C5JCNM3XGQ9CH2XV6ENMQ79SW7TVN4M',
  'SP2W03GE8Y6PJQF97MF0BQZPPG9PE58ZVVFTC9FPY',
  'SP14WG8DQRNBNJCZM7WBMQGQNKVVNT49JGZ9CKZWH',
  'SP1SJFW3TTRJ9QZM2J53GSNV7C6SDJKCSE32HT9XK',
  'SP314E6K9TJK511VW56X8ZDGSPFZ7HHA9HJYJQA6J',
  'SP19RCECGYAS262YCYVQCR91XN3S0DY66B5GE0SKQ',
  'SP3NDX6RVFBYW97BTKK0PE20SABAYPFVZ282ZY4KJ',
  'SPWTFHWKN9VGXQPZ3Z3RMZ0CJ0EVS0VYA5T8J7FT',
  'SPR4G23D2NP0ZEE7M6D83M59BZD0HCT8RGZF1RJ3',
  'SP519G52BR2SRVK7EJQPPER52HP8MHVJ5WEQXFZY',
  'SP2WPTFTP17PGJM8328QWG3SNV9EZ9W1C7EGTD5BQ',
  'SPE9CQ6VBE2DER8MG4DJVZ9123CZM0QSVGWXSKWD',
  'SPB1CVT7HXC50JPDX99HZFYX02G0665NNHQTD16A',
  'SP2K5CGWEW1E1W0H8WVJA2THJHT1RW5D70MC94WQX',
  'SP1T48M17PR2HB0DJ2ZGC7PKJXYPNYWRAXN5MWWKP',
  'SP2CM396YQ1KK8TQ9W37K26HWYM8M4PS9TRNGXW9Y',
  'SP3AP25RH9WQECPK0A78N8VM8FPTSEKCZPKAT39T0',
  'SPM444W0PQ1J53KNSHBD5A8FSBXQ70WTAH1KXMH4',
  'SP26DNEP9ZTFGEZ9YMMZ1PB4F28KATTJVEKK7SYDM',
  'SP25T93SD21WT7HCSR0S8R9JXEQK7MXBQYZJMA1MR',
  'SP2TCDF2CVJ8BAVRZJ8M6W60QYQJWNA88B4ZS77P1',
  'SP1ZJV30BHXM3VECJGCB66RD4KYDNW2H2T69393VA',
  'SPWGPEYXBPJ56TVSFTVM9V1GZWYNG9SXGXMYEE7W',
  'SP1FXCKY6E03H7HYKWX8Z0DGJ54YDK5CQH1EDSAJM',
  'SP39KKA7MS9ETYSRHBVW32324VMQYZN1QRJWFC61D',
  'SP3B9R6SPYP6E693ZTYVB1AS81HTJPQ081EZBB3S2',
  'SP3PZSVE6PG3S1VHH7Z1BJ1CCXH045KV3NF1RGZA8',
  'SP1934QK1ZXGCEBB3MN9Y0FNR20GMDWM3Y5P5VV5',
  'SP3MD7JC0MZN7RBWNEVDQP75XTGNJ77R4QMQCHP53',
  'SP1G3EE24D80P4FR11RCA4VEEX7M0266385JC1FS3',
  'SP2RS5E5NS98N8V1MP6D1DR526QJGJPXS1X03PYF8',
  'SPK7F2MYHN4Y88YRTJH46RZQQ8DFXS4APZ02SSZ6',
  'SP2S6WBYEHHEJ4J4WDV36NBMD7BFH7XNHVX5G4GHN',
  'SP3JRC4EFED6H0TNC8GJZZXG0DASCA0KJEFP7Y5VS',
  'SP1A0PMGKNNCTBHS4N9J5915ASS1WKKR84YVHC6WM',
  'SP10T6HVB41B3JCJCCW5V70CG24658ZB4JQ4MHKXB',
  'SP3CBR1A3JCW6YHMAZW23B1H1VRHG4G09JPYQ0R5F',
  'SP27BRRC7CMH26Q7XXB4ZQDDTT5SCRMS0Z0FHR41W',
  'SPS1FB3GEZDH7BYFN0XTYQPSVNX0YT4FWWK6VF5J',
  'SP2P1AFAMKWN4R72QDD0AM849EC78XJGB4CQFMF7E',
  'SP27BRRC7CMH26Q7XXB4ZQDDTT5SCRMS0Z0FHR41W',
  'SP15A1DG72JA3CWVBRC8GZMBZXG33HT8AEZKQDZKJ',
  'SP3W6437E0VK8BFZKHBN7QB71BYZ5WKCQT6WSCDV8',
  'SP1K5SQEEP9E1DW4BRE9F0VE0FRFX1MHSD5FWW86B',
  'SP3G3NVMENGGCXSHQ0TXK2VT8SD0Q3ZD52WD48HWR',
  'SP14R4HTSF0VXB71P31SAJ5081Y26MSMVQ6XQPX4P',
  'SP4NBPXY28Q8G2HRTXN0Y6MQXKH5V5DJYMKRKRTE',
  'SP1PEHZE1EKW76JXV5APQJBNBAXQNW0MNDNKMVCK7',
  'SP1PKMEAYY78QCGTZM85B262K9ZVMXGEC7ZZDCZT3',
  'SP1CY7XYG92JX536Y5JFZZD1C504PW0J8FZG66T5Z',
  'SP1CA9N6FWNE5BA724DW6BKP65N22B4HD0B2B5C9E',
  'SP3A24AA0PCE2KGSNCYR60CCEPT9ENNB69EFNDP8V',
  'SP3HRPX04P0ERHVW2DHYNMM1AMNVAKK39ZTAQ00WD',
  'SPV0HGS3D35M1X8SPE1GEGHRF3DGVYWNKGMFETR5',
  'SP1D7CG9T5QZJ5VMP7TBKJ6E918E48SY58W0Z95SB',
  'SP3EKS4P67JMY3WQ4TNC4DZQVPAJ3WHZQHH78T7ZR',
  'SP3NZMKPNWS5J6S4XZ7G8HX3252NKV3BF28RQ0BR3',
  'SP17PPVPGF4QSZS678B56AY0FZJH1NKMT42J368H2',
  'SP3PZS01EDBVWG0GZPMJ0QXM4GGSNMYSD691Y92E4',
  'SP636HFPNRGR42XBAB0Y4YR26G2HYBETW7PBX9ZK',
  'SP2A41J300STB9CTHWZX8C29411SV7TFWWBGPMQVX',
  'SP38CW30DPE2VSP5JC75S1H17FSSKTKH7PDQXHX06',
  'SPJB6GGMWVWA13ZTY4GVT4JK0GSSTS1YG8QPZ09R',
  'SP2X2W2D5DPX2GSPG6ARAB2675RDGR1Q881S5WXQG',
  'SP157P6T9CVZPNK9PNYER8TMYM8P3X91839KSVWWH',
  'SP303ZT8DWY84MSDR5DB3PVJCYZEKGVREDK4ZWWB3',
  'SPVJHDZEM9706DVA4VVHPZD57CJT9Q0Y9W63S42',
  'SP2QNYCA6TMJC0CG698QXYHECAGJC0RR94N2DSHM',
  'SP3S8SE8HTRW7DZK3QM8VQHSDYBT0HA9F25FB9YMM',
  'SP2TJ3G8YDCZBYQ4M6ED3JKZXRER431Y78HS30V8M',
  'SPEEV3KKJ858GVX9E0AQQS2SNJPC9ZM2QAQC88YW',
  'SPAF3V7AS6DFN4FD0PKP76KSW6FQ6R4Q09MCSXDG',
  'SP1FSXRTPZ7Z2527QA6FV1FTZFEK6RRBKWCQ9GJXP',
  'SPDB5A5FJDSSCK7Y00Z8DJ0FDVCHC2B5R11VA7C3',
  'SP3Y0HQX1QX20H3KFBS6NFQG7J4CNBK278SKH3B3T',
  'SP3VDF4YEYXVJN8CV0VPRSD3Q81ATVWNEMN0S8C0',
  'SP1YR1T5D81H60RBRVTT8JXN10C6VKG300YZBVK1H',
  'SP1QWP585KPEADZSN3YM62ZW6Q3EPYNTPGHZ1VCS2',
  'SPGGT3QKYG8DH21AVBGF4NEMZRT48NNDJS9G8PNH',
  'SP2437W5Y47YZTHHJYDS82JJSF3V6K8D9V6SVBAFM',
  'SP86TBW36DAZY10T3RGZ7TV3FXM1ANPZED1TH1HT',
  'SP1JEFE8NSTCD6TD285TGQ49XH0SN4M81WPJ67997',
  'SP1XCTWGHYND0Y0AZQKDY84PRR7TE8C24YYDQF9NT',
  'SP16W8D9T5ZMQJBRRGJPW38CBJAFGX0YR3WVKJZN7',
  'SP2FSXBXT0JHDTAY88A31EK8M2N61FY41D2HZP5XP',
  'SPSQG45FPKZB8BBZSZR71WX8CJJ56E79T119F0XX',
  'SP3Y37BCGPYMVW98T59BTA02NNVPSEYSZH2T5V486',
  'SP23ST11X757AYVJ0GEPKEN84K25T3E2BYRYXVNN9',
  'SP3Q8VW9A3RBYNPTEGPW2HS89YEMM6APT8VQH2JRQ',
  'SPXT2DAZQWCSCYQ69EGQ7ZH5VC6QTX085421XQM4',
  'SPWCV7YQ7FCJ53K78Q3SSR2TY5CC32TB2A24Y0RC',
  'SP1T3E597QK1YFEN9V6VC99RR2VRJBHAYBCGGFFH0',
  'SP24AXEVQPKR7AP6AFNJEAWNAR8K1RK2ZV2JWNSRV',
  'SP1T3E597QK1YFEN9V6VC99RR2VRJBHAYBCGGFFH0',
  'SP15N3RN2YNT1F5W40HFKEKG7Z17FXPTP1GJEWSHN',
  'SP2EVEDY4E4ATKJQVNGJC52NT35GD5APBRCHAR6QP',
  'SPZ6J1AC3NC19JJ0KH6KH7WCFH6Q1YKR96XN0GHQ',
  'SP2MDTC12RJ1NS9PZT5S36AM5MH89BSBFDKM738HA',
  'SP1Q5YQ0BX8YTRRYZHT6BZJWH50T6CG4F9AT3DYG9',
  'SP1A77V4W01MTNR44VWNE4P2XXHYVTZQE526QDH1W',
  'SP3N5RZKY1P4XD9J4FZTTJ6JHZHGKZ4PX0VB5EJHM',
  'SP34VBZZ06G676FC5WM3VYF9J4WP5ZA0TJWZZCYJ9',
  'SP3E85PNYD3PV3TXJAJMFGSBYDYY6PFJK4JXRB67R',
  'SP1YYK6JWR53BZYQXAH57XV4CDABM3B6A9QP12CQQ',
  'SPYKJNZQV43EBCCT3J0P4XKRN6KWHKMYX0QMG1WT',
  'SP3J8C3XTCDW2FSP8MMT9BNMR0FHZKHZRYR45RZW',
  'SP31WWKY52ZW8ZWSWGJY0VV1FP84XGGFKZD4MMRY5',
  'SP16FBHC943VR1WBQ720ZX4KW348N440C5NEVC88D',
  'SPFTP3NFYF5X3JWWA9VZ1WAF39WBKXP1XK5NXTC7',
  'SP2511SRET2DEMXJR9N7VX8440TG37ZCC5NSZQKM',
  'SP2GK60A1DJPRMGXMTTTVN6YE77XPGDCY5NHVFSF0',
  'SP21DYD9V9VN0AGYTCGWC7QDAQACTWQE59HNC9FPE',
  'SP14MDQHJ30RGT1M41SGP6W02JRXG6D2WTFXJ9CRD',
  'SP1344W2ZQE5R3W9FH7TMFVE4D9KGNM47G76E24W9',
  'SP1TW4B901GQH7EM86MTCD3XGPV5CWC283XDSZ2BE',
  'SP16B6ES5031WKNG0ZCG5473HX7QZSEQ5SX53N3A3',
  'SP20WCMYQX6X3H1VJHTYPHB0G3QHEH1ZTQAPE1CB5',
  'SP1E1MG35RM8PXV40STKK79G6QEZG59Q1F7QKPWQ7',
  'SPRWMDK7F2X3VP47DTD2FQKB0ZJ685CTKDGNZ9M5',
  'SPSHD1GNZHSFFX561JTAF06ENZ7RVCJZG73WJPVP',
  'SP3YNXNCX3N1CGYKRQ9N3MPZXDRNC1423F1GQMMK',
  'SP3NQGQPQ767TABPD311S9A1HFZ3C57NZBD3CVWGM',
  'SP12C1JQ13N4E41NJH7344DT2K47CWT1SASH64P2E',
  'SP2AFB5GSBBDS875F7TKKHXRDP9WT0YAP8V55WEBT',
  'SP11X92YA36BR7QG45KVRGSN1TPFTY0YRN973QEVJ',
  'SP20M9AKF3ATR1XTM0R8R49759KVWW8PP09MTHFSP',
  'SP24SQ7DCAZWE8WNDFHJ5F5A07PT0EDRT8W7SK0NN',
  'SP20M9AKF3ATR1XTM0R8R49759KVWW8PP09MTHFSP',
  'SP10B89A88SGPNHAYZXG2KXQFW9QSA9X6GV60AXTG',
  'SP1V4S88W49BAG42H1RFS9V2PR4N9940B85JX8HD',
  'SP1JRAHAV25GTBHGV721N0GST4FAGYA5VGK8NET2M',
  'SP3JQR5H14BH1Y8GK6C3Y7WXTSCVDW0AA7Y6NYG7K',
  'SP10B89A88SGPNHAYZXG2KXQFW9QSA9X6GV60AXTG',
  'SP1JRAHAV25GTBHGV721N0GST4FAGYA5VGK8NET2M',
  'SPKT5E3CBQPE44WN99XKZYWZJH93ZSYSKRE5GJZ5',
  'SPX31AFPFQ1B9EXN7RRW0NYV0FDJ03TY1GEJP416',
  'SP3JD315VNCPKPGCMSCQT9ZJW8TJMWPSG3WK4W87P',
  'SP2MMQN4BVGG9TYBTV0Y5WFAHRQPVMJMY8NB88QW7',
  'SP3ZWRDF5NP1KQYP3G97Z1FG1046BV7CDFMGM8K8W',
  'SPV1CCFTB5RKSMJRMW239VMN4YP3BQYWAWBGGK0R',
  'SPJ2NRXG33S3434YPMWV9YJKNFG3RB2KF06XQ8JM',
  'SP25YGDTY20VT948KBC19NAXXTVRSMAF4ZV46AC9A',
  'SP2KM0D10Z7W977WWBX2M71PJXCE8FPMZBDWAQSV2',
  'SP29BG74FBM4694GE3Z3KZWEV3CT7F2690SWMYY58',
  'SP3MDYX2K5MW5NF5D0RCP9J3E3KX52TYPTDERJK7W',
  'SP1FN7K34VKG7X562S6T63S72PAPG273K221F0ZNC',
  'SP3Q04HGHEVJTQ3QAWTXEF9EV45KTW3H0E139YKCK',
  'SP2055XTRHA6FTEDJYJTC8X8EY937FWWESGKX3BPY',
  'SP2ATVTFKVGWK3BS9M7AFSTF331FN97Z7HYC11TK',
  'SPR5AX1QFGQ1GDW96ZF2RF8DS27S2VB8HF8HGH66',
  'ST3J523YTK8JK7HZE8WN0Q8V222MSH6VXKETSSNSG',
  'SP23P8SJA1QDGN0EA4ZYSJ2M3MCEQQMRB44TZQVC',
  'SPSHJE4F0D0ZKJZ1DVXEDFFD6AKHHKF31H3M77B8',
  'SP2ZQD9D4VGKJPCK2HD3YC78Q9J6PVK52HF1K1A9F',
  'SP32DFA3HXYZ2BV3P8H6XQM8EN94D2212QM71BRYG',
  'ST3DV0TY2TFQQK0YHEZ7VVTCFQZ78NCDCQDDSAPYC',
  'SP1W8ZGAFPWSCV30XJT5R88BS35MSBC98QFNB1T2',
  'SP2ENR6WYJ9470SWQAAM8KTSW906AWKWR17QP6424',
  'SP9SEZYHT8YEMWPBJ7MCY0XQ5DFJ720RBG6T4QX0',
  'SP1K5SQEEP9E1DW4BRE9F0VE0FRFX1MHSD5FWW86B',
  'SP14R4HTSF0VXB71P31SAJ5081Y26MSMVQ6XQPX4P',
  'SP4NBPXY28Q8G2HRTXN0Y6MQXKH5V5DJYMKRKRTE',
  'SP1PEHZE1EKW76JXV5APQJBNBAXQNW0MNDNKMVCK7',
  'SP1PKMEAYY78QCGTZM85B262K9ZVMXGEC7ZZDCZT3',
  'SP1CY7XYG92JX536Y5JFZZD1C504PW0J8FZG66T5Z',
  'SP1CA9N6FWNE5BA724DW6BKP65N22B4HD0B2B5C9E',
  'SP2S37JV8B3XZ6T72EBN3SQ7FAXJQN5P27WJT18DX',
  'SP3A24AA0PCE2KGSNCYR60CCEPT9ENNB69EFNDP8V',
  'SPV0WDJQHWJVR2XD59H5NGX9AMNNTP4Q92XV77JD',
  'SP1VX5Q5XMM5HRKB3H26KZ840211KRHNE8RXX33FK',
  'SP3HRPX04P0ERHVW2DHYNMM1AMNVAKK39ZTAQ00WD',
  'SP1K7C1QEFTEW3ZA5PD51BSJED3WY1JWHZSDJTN52',
  'SP5GJA98CH5BYRR753WT831Z0FMPX0XFBY1T59AJ',
  'SPF4FR0X9Q4PAF6KENDD3NVAGQTM8A830A4F96YG',
  'SP10C8D18DY86VH0EETEN1X6N76J18HDF6NBM6RB',
  'SP22KDHD9H5DXVEXZ9F44MQPW4AN7HD36FM1TM4ND',
  'SP2Y8V41F6Z93GVTBHHE0E0ME169KN14A60V7ZYHZ',
  'SP14WB8RYYEJ78A92N7GK3574XKTPNV97A48CHS6R',
  'SP3MRCD07FPSRB505DQ4XDBXDZCS3Z0CP8AC7TSF7',
  'SP3JD00EW74NEWFRH5QJ7WYGCWJQDQPS3P9GEAR0Z',
  'SP3KPC43PAJA0ZFEYNGZDWTHKPWKSHF8ARP2CQ6ED',
  'SP1VFDGJQGDYKNTBG5C99SFV83KAJC74VZ5DF18G0',
  'SP2BKH8EEATHQY27A4K8EJC66NEV2D1JT2KX989Z0',
  'SPZSZ9AEQAGQ4ZN8AN5MZYH6PK1XVV4K4K5X9RT9',
  'SP2TW4GBFZ4JQ83D7X16Y37EPC17EHXXENGK7B0F3',
  'SPABQ2V9TZAF217CKR3AK6A923K09HPH5YFN7T4P',
  'SP2MAWMYQPPVD3KH8SXC9M0HHERQ45YAKJFTWKXQ1',
  'ST35G9BE4N03Q0S6834DASFCTTHEF4EHRHA9CAN6M',
  'SPB4X387V8G2PWF52BNWC5SWZXAC3R33NBQEPRYJ',
  'SPV0HGS3D35M1X8SPE1GEGHRF3DGVYWNKGMFETR5',
  'SP3JAPS45FXZY1Y8Y4Y2HX7VSZJ39A6YNQAQY10TV',
  'SP22KBCX32H0DH8R7TWE1RV0QRBREYXHZPD0CWGGS',
  'SP14N6PEYKMNS05K8ZW2WX3AFA7JYTDNPCWE3BKG',
  'SP2PJ2F711VQA879RYRR54504N3TQJRM4ZNAV336G',
  '16qQPBhAWLFjhmdSdX7NM9GBjr2Wp3YNNC',
  'SP19PS4497APKH1BYYZAZ1A5T4FYWC4BFMJBNRHND',
  'SP31VCP8G7QD47GYKWRD3TCG1ARD52R3VWFDE8MEW',
  'SP2B2MAXZZ16AGTQREP4E5B3GPJZ9DDQJYTYJA6QZ',
  'SP1XRNWWV70BE90MKGJ1QENS91FZSBE5426N9XQ18',
  'SP5H6192GWJ0PTABXCP1RRA18E298MY4S6BWE361',
  'SP25T2M2ZFD3ESPG0FPQW52XCVMQHEKQV5ZA0T2B3',
  'SP1YTRTB83ZJ666CRY6XZYY5DQZ69F8PW0872J5VN',
  'SP1VFDGJQGDYKNTBG5C99SFV83KAJC74VZ5DF18G0',
  'SP3Q40E740MF3FW99FW9JNWPBF1JDQZYESN14NKT4',
  'SP2BKH8EEATHQY27A4K8EJC66NEV2D1JT2KX989Z0',
  'SP39M45C99JSXH414EAAG94P4QGJGA0AWSXSV2ZZ0',
  'SPGRVAYZVGY2P2YVKWB2WA4Y43BX841FDDY8TBH0',
  'SPYY4VMHEJVYRV9E6G39X0HZGW5RGX9N3DYAHNVQ',
  'SPN5AC2CZ9HF6BYMXBFXE97BEZ4S7X4769A4WGDR',
  'SPDTM6JTXA4FFB9NT5AX6DH2NGZB3AFQ2R0362GH',
  'SP2CB3NJ6VA84QN42G8YCZFPE6FNZXTCRYHKGY6KZ',
  'SP30YE7G0CF3TF22SB13S8VYBZ0NDY64M3CMKAWJD',
  'SP3GPBVAMXFVT2GFB8QJF34ZGVW2CZQC8A6CX2N23',
  'SPVDQNFDAEYW7491TYKHT2NNSBWM3XXCYYHTW6DT',
  'SP1DTSEFASRXCNKG0FM3BKC7KBK2FFMQ8QCXGG2DS',
  'SP2FVNZZR0GX8ER2EXFPXRQHQVWMRYWGXJ7T94386',
  'SP2ESKKZG2TNXPBEBCRJ8HR2E1WNEVQKF7YMDBHRV',
  'SP2B4KN07DAZMF5J7QPC31EF9EPGAYBDP85ZGE4JX',
  'SPDECJ9WFEVNF1EF956R1MM4874VW35MK9EBH3TB',
  'SP1SS8ZYDM4GSYVT9HHJ2YDFMYCNSBT9EH7Q8EWHJ',
  'SPVE0TQGEER650CVXGWBGCFTBZ1PRV5EPE0RKWYZ',
  'SP38Y52A96N7XV9RQCTQYEK6HT299P2P5A81GC44H',
  'SP13E68V79290ZVXVEEXWTG57MSFWG82VHJ54JDF5',
  'SP3J1NYQ0V63B36JGY9H1XXE1K9TPB7AQJGK360BZ',
  'SP1R07DH72066TF5HCAWWYZG1VRYD9VFWXD52AB50',
  'SP1ZKS01KDBP1WZT07T6XPZN8EK82SD0GSH76PJM7',
  'SP1W3S7W15EBRZ1SJQP5NRKR264Q61JY8E13Q6PP3',
  'SP1DCTMXB04DYZMBCSX9K9AQNCMF8ZQPRDT33N5EJ',
  'SP17YP1HGWK7DP5Q69GRG14W34E078S4D78YM1FA5',
  'SP2XV2G7H7DC97ESZGFKWADTYZWNQH1QHZWGDDVS1',
  '902722572356622000000000000000000000000000000000',
  'SP9FPT7V8ATKY3AZP5JDV97PVV4CJ6938PEE36E1',
  'SP2QMV6B1W69YW73PWT064V403S990FWHM0ATKN05',
  'SPT9X2WDC3MPQHMDSGAK03P37ZVAF0YS6G3P8GX9',
  'SP1EZ3PN034N17H5KHFPWNK4959FF7XM8JA2ZDYP6',
  'SP1KMRJ6Y25DJ4CGPQNNR44YXSN08DGH8PTWTDV5Z',
  'SP25CNYQMJN1WAHQDYSP29J8XDPY2QV2PQQAYT0CG',
  'SP2HKW3F1Z1Q05TX6GF5RX82YMA3D7JFYVY1D58PW',
  'SP1WRWXQYN4FYARF4VX4H106H4HEP6NFA4GB66RJW',
  'SP1D6B6DPY94Q7RP2R17EK2RRK0ZMR1DAHAPKM6A',
  'SPXRP5BPH32CM0ZA51T7Q9JCJ635609WKMWP8H4Y',
  'SP2WJZJGAH8RYH2TRJYFGZ83HHTRX81KJVTN0K9K',
  'SPVG680R8XETDHB4BEMV9XW7VB66MQHGRH6KSC3W',
  'SPZRAH9K1V2X52P8RK15RCY2R7Q6T81DJA6R28V8',
  'SP2VH14NCJ549FZC9B1S3BFS6P1E0P938XJZGKVJP',
  'SP3SA8R3CY4SQACBT4Z1S64K6V18XNEPMTWWZC7Q6',
  'SP3TZB2QZPEF673B7S49FMJXK4N17TAT1KV7YFG0C',
  'SPFMZAN3VDYH05A49XZYAERJ96T87ST3WSZXMMSY',
  'SP245G4Q1CEHSPSMQ7JXVDQ0M4WD0KFKHS2TF7N26',
  'SP3SH8QFNT4Z9J7NKDRTPE9AHDYGA5AJC3DETEDWZ',
  'SP3E3HK7RF7WMSHA9B8Z7Z6TVHECQS7JGDEVFPDNA',
  'SPRA6FK6C5N1Z7G61N77YWTN2C13GEX9NXQ23MX9',
  'SPRCG0WK82B7W16BWAZNNZACBJF0DWXWG0EZV65P',
  'SP368YRZ81XA52ZX2WGXBCZVFZVZSYX203RD2J4CY',
  'SP2AZZWG7H99G9JW0PJAAC1JDANZ02GZ9CWGEJQC4',
  'SP22AHVGJNP97Z6JZ2VA5YK1KCG96BM5EXYJRMZ4M',
  'SP1TKR1N7E0HVPKKT0CJ76V7HWY25PS80QCBPGKG3',
  'SP3PSNW4JV2R2HBA4903TDFEWJKDGJZ7F15D3D7VT',
  'SP27SCHQXMV5NN3QCG973K3EKDRNJQQAS9XFZCD85',
  'SP216Z3HX9X2ZSVWC0F43X64PAP9NPN5A1GEZTGHW',
  'SP30R97JRW1YM2J908QHS1VTBK90A1BY7E9C9MPWZ',
  'SP18SSC4VPYF2190FFECHR0SR1YB7T2BAXT96EN5P',
  'SP35FJ4XYFKRCKAS5KEV7N7KQC69J9GMQXB52PB80',
  'SP1P72Z3704VMT3DMHPP2CB8TGQWGDBHD3RPR9GZS',
  'SP3241BKVT8YNEEVM7VASQ918CQZ60Q9EN5B39KS0',
  'SPV6C60TM2J564HHB3MCEFS28YJHN5MVRA9QGA2F',
  'SP1XQWQTXFSHRFD0WZGQADXZ2V7K19VS49KWWSED9',
  'SP1W93AVQPCSZ75HTDWHHZNPH7PZJSS5ARP8AW32B',
  'SP1S6ACEAVF8WZHV1MNS5PE8MRK335NZ5B3TY2B8Z',
  'SP3AYFAC2JGVW75XVMMF75BB059SM1XVPBMXW62F8',
  '1251088084201940000000000000000000000000000000000',
  'SPDV8MQ78T5BMXEFKX69EBJ853S76DTPX94KQR6N',
  'SP26G11Z4BGVQX2Y4ETRH7RK5EK4PGMQF1NRC52JY',
  'SP1HNS1QE5Y2S9E09MV9WZSKYWAXE5FGMM6RDYB4E',
  'SP2CWGFRVABKYTBFG78XDHW6YH3ZCP154RQ388TR9',
  'SP3ZAYNV0GDV6K2TQFDY5X15JBBNN4Q6QWEGQQRCF',
  'SP1WV9VWBG0XRN6ZK5CRT2XPXXP59E5KHTEFM7QCX',
  'SP18WBARTGVBABH67ECBDFMNDSEZ6ETRHGH7WDB1R',
  'SPCE2R5KD3ZRNTER0M72M0A9BPCJM1CE8WQBTS6R',
  'SP8QRVBH8QY9TR5WX9KSDQNQCQMESN5EXM73JPQH',
  'SP3VHP5FZDEC2WGF5QCJCXBYKDPXNY1BB7JP8TCGS',
  'SP35BSNYK8C55QSK4HACVYZHENGMG45G1FHN6S6AZ',
  'SPH97E9WZBK2VX77SHJGYZZTWWYCC6WFMPDWWHPQ',
  'SP51FBHQZ9B2YXV02FV6PTQAYSCGJ0WM7BPH2QG3',
  'SPF4FR0X9Q4PAF6KENDD3NVAGQTM8A830A4F96YG',
  'SP1B18E0NN9BWR2J0TETCVTG0MKQYKXEY19VVNWDP',
  'SP34Q7E10V5HPVND1T7MTE6MYNTAZGVJAKWFNMW9A',
  'SP3ENMV85SC1W87GXBPHM1MB0BDQBN762P451WZTW',
  'SP2YKW1A9MEHYQ92C73DCTTCDQ7CV9F0W0NHVWWFD',
  'SP1PTT5GSWJSD4PZR4WR4NHJZQ3NJEWJK41HV1G7H',
  'SPM05DTFQ6Q44VTT871A32415Q9D5P9XCZAX1W8R',
  'SP3P4C7Y072A6VZEWKR187K27X8B92X8K91KK70X0',
  'SP28TG2YN0KJ88JGKMJDP8EMQZQBJ9T4J5A470FCN',
  'SP1K1WPB0TWCZRTGW926HBPAAMJYSC4GD94ETSE2G',
  'SP307BQHCHH036TZ2SYQG0C01AR5YMYJ9J1XHNNR4',
  'SP3WKR81PZE2JS6R48SY2R6KXV3VB2KBM2C0D4RS4',
  'SPZV53PK1ABCDFD6W1C9R567GEKR4R1KFN086A9Z',
  'SPTETV41R6ZHFQ5MVMR6SR001HF711HAHYYNQPX0',
  'SP2E852RZJ5QX4ASNGT1N1Q1A7Z3Y1MZWSHQK0MEB',
  'SP3RYDKM1K785D2133JCSK0NE69Z6Y9B32KD314YF',
  'SP3RGZD05NTD1B02S7JG65WY814B8Q4GT4MVQ9YPZ',
  'SP32EAB7DW35075N3SVPN53KKYHCG5V8FPYGF69WD',
  'SP1QRFVDS76WFV5XCDHAR7FQYTSNM4M1P4TGFZRK1',
  'SP1A967GSS8V1HCGQWTWDHFRQP51K1Z7S8CA4YJYR',
  'SP3MSSVGZTW4TGZ7WXJQZXYX1FJZRV30F0YDTVS5Z',
  'SP1GK0YC6T0W0HZAGGXFD4FA1RBPJZ05YBWJ8MB61',
  'SPHXP538TC412PVP8YC5NQZDSE6PN8DZV2SMY1KT',
  'SPPD51286H34ZYAA1EFWGSMQ9CSDF7JQ9R25W45R',
  'SP3YB4SCQJDQZMH5SMNVMHXMRWX8RRNGA8ZFFYTT2',
  'STHZHC5D5300HSDFA6AS2P50SJHD794BY6CW3HFQ',
  'ST4M4NZNXBP2BTBA20AH8AQ7HEGQNJ6KWGG1TEXV',
  'SP3QCMJ9TK05C4GYE6V219AA8PSAW8972KG8J948J',
  'SPB82VP95WS7W6XQF92053E5DZCJ224425W1NVPG',
  'SP34S80102KYXHC0C5VC3GDPDVY3WFG1G5G507Y0K',
  'SP1AHD5MMT6SWEP0FH1JDACKCAG7YQF5PYHW0C9W8',
  'SPJG6YPP0SDMVYGXPDNBY3WG4RCYVRD0PTR08N6Y',
  'SP1FSCDGKGCNB1S871MG7ZWG4P1D2JEXNJFAQVX9E',
  'SP3CDV7032X3GM01SXYSNXSJY7EG5XG008W21A7HQ',
  'ST1DQDDCN3WYC7TPHPCD9E2GDRD25JF15NTZRNHX4',
  'SP27JJS3774KPR0MMB5FH77VWBD8KD0SVNZ4QNX5X',
  'SP39CCPB32JPTB5G2SCSJ8FNFZ2Q435435KAJP1AY',
  'SP390E3P44T0XJHWQ7C41R4Z5G76K79TGC7PERXJJ',
  'SP3W3N4PN7VX602E6JGX5N8JR3RRVGNYZ4D12BY4W',
  'SP245RYQHC7TZH2VDQ95KHG4CNKMYYX9Z8A08BGTE',
  'SP1F934ZWR42NBC8W7YKRXJR3KYZBTMY66A9SF8T3',
  'SP2BSJWT2QQXHMPE3C7ZEQQN68ZRXB6X9C1KJ8KMB',
  'SP3BPB30CSNHF29C1SEZZV3ADWWS6131V6TFYAPG1',
  'SP1DGBRD78GMYGKDX9YZAHHK79EWN7737Y5XBAGN7',
  'SP8ZYMY9KR1CHDAGVFTFB8026RGP6E2VS1JGNVP9',
  'SPPXX1BEVRS0AG7K04S8NETBVPQJZWBT3F1TPHXM',
  'SP1FR2M102H4DE4DH96R4D29RC8AGQZG5D5Y4S7CH',
  'SPV8DF6MA3QD4T5926AX0RDRJG1D59RT4NX3HRVS',
  'SP37PM1Q3VY6KFKCNMB1WMK1W7D0CH1WZMKJVRRSD',
  'SP2TB9BR1WQ8TZTACRY4MWM27KGR85VQAM8BK49NE',
  'SPR9ZQMR0P4ARZT8ACYQX80V43ZSC5697SN9ZT7T',
  'ST3A0N3VJWEGR0DZZNXD15FMRYRMBKNPXDHVDY78S',
  'SP20P02KXAW338AS0ED5HV0M37SNQRFAMPB0F1R51',
  'SP3SF0PSD7KYVJQPKKRBYJFF7NENGFHZSBVHM3B27',
  'SPECGD4HBPH4G8D4P24H9G747GYS7GPTNQVFC0S5',
  'SP1Y52V4KKMK45207ER9PVVMKECGAQ0272PS8DPA8',
  'SP2A6F802MTTNE4P2Z6WG8SXMKBH4JXMX5JR2CZB6',
  'SP1AHD5MMT6SWEP0FH1JDACKCAG7YQF5PYHW0C9W8',
  'SP277HZA8AGXV42MZKDW5B2NNN61RHQ42MTAHVNB1',
  'SPQA3CAAD1XQX9D1Y2MFKQYXVBX40WSRVTF31WW1',
  'SP2PMR4W1WM9N5PRCWA00D65QAE105KV62586WQVT',
  'SP2QND89SYHEPQ63QRE3GVKQZAH1FGXP0YJG2SATE',
  'SP2HED4RQ8CN0YZCRG492523W0563K0Y23H19RBY6',
  'SP9M54TTRH225M9HF6ZAXWPF77M4B0VTPC3MZYGE',
  'SP3J0E8P576KYGRWJTBVNHHCCAMF6AGHAEWHEX4J7',
  'SP2069715QFEA9GK8Q2E7QREFABGKRP78C08NTDTK',
  'SP143YHR805B8S834BWJTMZVFR1WP5FFC03WZE4BF',
  'SP2ZFHBEJNP50S1W9V0R540WNW0PK9VMEVMEH9W5Q',
  'SP3KPC43PAJA0ZFEYNGZDWTHKPWKSHF8ARP2CQ6ED',
  'SP3W23AJE2FEJN3R9ZPCS1QZEGRZQ86A4810H9HSQ',
  'SP3W23AJE2FEJN3R9ZPCS1QZEGRZQ86A4810H9HSQ',
  'SP73D6E6JFAMFEV3E78K7EGT46AD5DV583S37937',
  'SPSMJE6H7YH9RWWXHC98YHHN26F95EWQJ0TWJ4JF',
  'SPHEK2P9F8EJYQRWRTYEBTXVSZ4PSA7X8J9ASNDP',
  'SPCJHTKQZDC494RJNGJYPN5DCZPMW5RH2GA1QCWG',
  'SP3GKD03CY339NVCVG0PKKRRC4CAGCECYXNXGQ69',
  'SP1934QK1ZXGCEBB3MN9Y0FNR20GMDWM3Y5P5VV5',
  'SP2ZD78CEHCFPJ71SB8R0EK0ZMVAGB3NTHK947F06',
  'SP2X6WHVKFN0JD7B4TGXKM7WDM0YP8E39D58MXYSZ',
  'SP3ME9Q42M0BH6C7VEABT2Q94YEXXW41J5MYNEJ0E',
  'SP24MMVQBRMJEVMJDMPM1MMDCM72Y946ZM2ZDV7KP',
  'SP3ZKZEFX3K4HD9JXKCKAE0QGBHG0WZ50BRAG78D5',
  'SPEAXKHJME2RR5M09XSQGGHNKYJB8FG2YZWKCF8B',
  'SP3ZJPSRZKYFZRFCNKWRP30RERBB7Z7B5HPEKVJHX',
  'SP1K7N173SYNXG1MEM0XM9F91BQE9WH1T4SR1R6ST',
  'SP21J1Y36WGKVK1S1SP0DS05860JST14PXM04RB3A',
  'SP3EJ6T75R7S1AYXE5ZPBWB8M1P14M99D66DG2PN7',
  'SP2TZE09GHARKG0B8NTT9X77QXBTQPQ2J1579T0D8',
  'SP1D1YCGZBBQDACVW0ZR7E02DBRECT16F9GPYNC3N',
  'SP29SFB03X2KN1WG2WNJAXPQC57865FFDGN7W8XMM',
  'SP1HN3TYFGVH2VG8S9H1PJ4WWH9TBV8G42SFJ70R',
  'SPP96RTM53XTT2W2WBJESDQFM9BGDHG39RD8WQ4G',
  'SP1Q3W9ZK6VEJBJYJTS1W295Z5HQXA1FEHGBF1JGR',
  'SP1386044X5N01AJAGN50NGKE87K4Q72P7DHVXF3F',
  'SP1J4SFHSMMT5Z0PG3WDD1TNGZVCWMB5QBYHNFECG',
  'SP1ZMQNZK293A6ZZ1G51XS3T0F6GJ6P9GV0RVQ4SV',
  'SP15TF0R1GCEFA84WSSRFPWM8XDQSQAJN7QAB31BE',
  'SP7QFM32TYMDC20M5VABB1WBB03RDYTM4FE1K7JK',
  'SP2QTTHJEVX28G3FZNQDQFSTRKY68K30EEYJC6FMZ',
  'SP2WPTFTP17PGJM8328QWG3SNV9EZ9W1C7EGTD5BQ',
  'SP2BZQ48MADDN62X044NNJCNXF5BA33C3BFQ3TZJW',
  'SP1A967GSS8V1HCGQWTWDHFRQP51K1Z7S8CA4YJYR',
  'SP30BTV3905TS3A83CENN271AHZHGM4C3FDZX3JNR',
  'STV8DF6MA3QD4T5926AX0RDRJG1D59RT4QWF3Z25',
  'SP1G9PZMQSFRQ7Q98XP7JMNE2C22RXK1W61RXTNKP',
  'SP1TSPWWFXRMQATZ5VW6HMA7NE7QHFATQ2JJYJC22',
  'SP1D1YCGZBBQDACVW0ZR7E02DBRECT16F9GPYNC3N',
  'SP2Y4Y133J0FG8F1MD1886TA465204FX3AXX0PAD2',
  'SP2KSK3WJ1TJWMBWWEAA5043ARA21ZNFCARTHT8EZ',
  'SP1S3BN5CYWGT0WB15VPYV09G9H347WEK5RFCFHKC',
  'SP20P02KXAW338AS0ED5HV0M37SNQRFAMPB0F1R51',
  'SP2AEY9QJD5MGDEEYYTNYBVVS7S97W2S0302HQ7S1',
  'SP3VGB2FS9FGN6M6SHWYWTCV3F0A08MQ6Q78F7E9',
  'SP31X93C7KBY3K0B62G0V69KENQNQFFNXN1HFMMWH',
  'SP30H1W3MPKWGYMJNMP72P84WGCKH1J900GB3JSW4',
  'SP3YF5XZN4CNKRANEHVFWS18DAG5M2CHQTSBZQX35',
  'SP5QB32W2AQFPDJ822AM87DGYRQ6D68JDVQZKMM9',
  'SP2E9G8NZZ9TBD33QNPM7DZNP7Y857PKVYRN9YVJ4',
  'SP398XE371G08T84A99TCBD8XKWY3S7VVX6JKJWKY',
  'SP1XKHXQFEQXZ8ZFKSRG7J393YE73ZN89THVGC9C2',
  'SP3DPBT6N2XYKKXAC2V6FSE5F2QRFVPPYDE5QGJTT',
  'SP3DJ2JP8TG8H0SHBBZKPB5JMTXNP24CBY3JV5CMZ',
  'SP1934QK1ZXGCEBB3MN9Y0FNR20GMDWM3Y5P5VV5',
  'SP3Z9D980T9N7C8QCA9GKH35AMV1S0XMG9Y35YP29',
  'SP328XV1DRBYT6J3A7AV0HDP58V5X4NTXTB6KCQNA',
  'SP1CA9W3C35F6WH2MH1D5Z1XQG9595Q1C3P7Z2NYY',
  'SPZWEE5NWK1TPYNG27REQYM2HTWCAHZKJS0HX648',
  'SP1PGTMSYFYSWAYXDSVDPPCVV1Q2YZQ6GNKPNFS07',
  'SP23NXVJWQARSPF1K3TD73MHVJE4EFV9V58GXHT73',
  'SP1C05YRGY8PHNEY55MTG2FSC3XS4AXE3H1KAYPCQ',
  'SP103BXR2GBV4WBESM8C4WCGNC9ED3PGT03X4WXTC',
  'SP2AYJHP9H3JM3T26ZBW0SKBCXJ9S4JW03VQBP7K1',
  'SP3JYTEYFKCY12WTY7TNRCQ392D1WF0YTPV71P0EM',
  'SP3ZTYBN9PYVVFKBEFVSZ2BEGK3HXRNVP6FDG79WV',
  'SP21JC3HRQWVQAWKT8HA5915CSQMY7R5S7T8VY738',
  'SP1GJTZ3XMXFBYTVV31A7K5SCWTA9NMGADJVSM7EG',
  'SPX9PMFDJQ6XJEB71HAWZXATB59YAD24RB6GS0XJ',
  'SP343SNH3E14GAHSGGF2PH9PNN64DP5HFY9HT56AE',
  'SP3BE9A1AMTWTQEZPXAGCBW4BTA25ZKJ7D9JGYB34',
  'SP2MY6V4X4HJHSQ62QXWH9RRGXPGTBXTMQ9YXTKCC',
  'SPFC7G0HPZBT24BQ8HP8RH65DJ9T9Z55N91QJTRW',
  'SP1HCKCWNY6FGHD6BZSW8DJEYAHC9242WEHGZXZRD',
  'SP1C6WQ9KTV3769S8X8YNAWBXKDG2Y65P5EEDRWR6',
  'SP3D9TPSQXDTB39KYF293XDJ5MC4PYJ3HDRQD932S',
  'SP26Z6SEXDKTW5KVS3KD01Z9W73RENRVPTCB0DRPM',
  'SP1KB67EDCT14QFA92E6H9F4KV441DAWF3NYT3G74',
  'SPJNAX6P9ZRWAMAXP0BS99AE7HMHDKCTT4MNHM5M',
  'SP3HD6JF8N5214S5Q7GHXBQGKY6XJNTRH0DS6P6KY',
  'SP18P831TBGKSGMJEMJM0V29CMKJP650ZT21YJ3XX',
  'SP3G9BMCJ0858Y68MM35R6HA0WAZDNYXWZBN4RYK1',
  'SP19BFMAD9XA1WJ1SV4HG1KYZDPGBMK56CAFE7ZMH',
  'SP2XSWV398DAV0J0G9TG6NVA4FCCJ7A9JT5NGQJ2F',
  'SP3AF7N5ZKAHMSTG6FDSPQF1T2NV5BMVGBSDP2ZGE',
  'SP2NYTQWKMJGR86FN9GQMRBF9FGEFXB0PV6BXWS32',
  'SP37XVGVDMC85DBMZSBVHDASS37FAW2QDYSH144VM',
  'SP2JVDE133B0NRGVCDVCRZ1AC92JMGF2VXS68EMZ5',
  'SP3J0Z8YSJD20TGEBE6M992CWFDG18VB0PR599VY9',
  'SP3BWAHYMTHQZHSB8N49AXQNTYWBACQBAN8Z4QFRD',
  'SP2HTWHXF3AMQ2RJKQHX00SM6XBTGKJCZ5XNHSE4M',
  'SP2WR2QSJJAZ7Q6X4NZQHT6QK58BH25FHG3EE5CTN',
  'SP3DRKFMS38DN51X5PYJ23VVVJRXYVH4E09J0DAB',
  'SP1KATN54MZRH14AJP2D73XA1AXD420MRJ0JH9RQ1',
  'SPYMQPZY0YQBETFNHNB52984SYKJ6FHGZMW59MHT',
  'SP1K62TNW8HCDQ7D3BRZ2NFNH25AC9JFVEM34X5ZQ',
  'SPWHKXYPYR63QE2ZCGB8SPMZ8DTD0Y5KBG1VWFBH',
  'SP2DFXST8ZR99DEXZRNSKRH84EGS854Y1HVP8FC11',
  'SP38J10VQRENJWK6NY2CX95D8RKXSYTCDJT8EDYYX',
  'SP3K22XKPT9WJFCE957J94J6XXVZHP7747YNPDTFD',
  'SP3SF0PSD7KYVJQPKKRBYJFF7NENGFHZSBVHM3B27',
  'SPYF9PC72BSWS0DGA33FR24GCG81MG1Z96463H68',
  'SP1NYHBF7GNF9CE7P5KB27VZTHK3V8XANTMXNHD2',
  'SP2ZCER0Z8VVMCDA3817SDFVES833XD9ACYDAFH1T',
  'SP2E852RZJ5QX4ASNGT1N1Q1A7Z3Y1MZWSHQK0MEB',
  'SP19CFFFPNJXXPEBPDJXKJJ2TQT11WEK1M5GJN5W7',
  'SP35G9BE4N03Q0S6834DASFCTTHEF4EHRHB2PAKGQ',
  'SPC7CD8S84AYZA86EK0AQTHAE86MJ8235KC3W3JN',
  'SP2SFZX1WJSKT1GA2STDT6E5NWDX44GW4BB8DW4DJ',
  'SP3GK06CKDRH2R5KDGSXWJM39HXX5VNYCEGA9Q745',
  'SP2JPS8NHJMGYZ9YAYGD1NKN3PQAB57FCZ8CK4ZTM',
  'SP1C9B99XBMHGF4FVREX7NKTDEVQ7EBMZ222THRXF',
  'SP25VWGTPR19E344S4ENTHQT8651216EPNABRYE51',
  'SP3ZQ324JA8CPZ5X38S7Q7ZCC0MM9F3PKRDJA0PC3',
  'SP15D95HP0TTD16AQ3XEDRKHS5B84JR9R0W0ZZ2HZ',
  'SP3JCN7W79KNRJBBPBRPKJZ7TRCVAK1NGV2FX4ZH',
  'SP9M54TTRH225M9HF6ZAXWPF77M4B0VTPC3MZYGE',
  'SP3EYT7KF5ERWQFTWW3SWHS8QRYBNSMRZ7JW73YXR',
  'SP19WSDJWTH4CW3YG554XS5CAXJJGAN83P8CFZ4K1',
  'SP77NT5VGM8XNMCTGK39Z1F6JRAX7GK7MWMS22TQ',
  'SP12BEEDG31J0AH68DFDJJYZ36D002PKDZCP1DZQE',
  'SPFQX05AX2TCQZQFM138PST1F99NE6P1REHGQ9FT',
  'SP3MSQHD6CD47T80DQTHN964ZVMMBX12VM6SC8RDT',
  'SP295KYJ831A19CD99FFFC11ZWJD86AJMC98ZHD5D',
  'SP30R6C4BZXKA2ABXRS3D7TGS6RKEHHVRTBGX1K6F',
  'SP20R5GNHG7KTDEH9SXRX8ACRSJNJ5FGFTYDGBND2',
  'SPK7DG4XGAFC8G000K34MK8N7JRX9NEWG7J1Q6F0',
  'SP21JJ0KVPTGAA1PD8RG1QR0ZSCFX39Q86T5NNFCY',
  'SP201VQDZD54J2RM07N8283D80X7SY15ZBGCMRB8T',
  'SP2VG7S0R4Z8PYNYCAQ04HCBX1MH75VT11VXCWQ6G',
  'SP1ZQX86FCXXRVBP2RA8KZZ29VE2F74C8EH1ER1K2',
  'SP262CK3VPG6PDF4S96TTXFBVV9Y9Z75F51A6G83N',
  'SPM47FFWVBCG8T92MR69RDKHBW0GYG9Z17RCF1FC',
  'SP228WEAEMYX21RW0TT5T38THPNDYPPGGVW2RP570',
  'SP3XQHHBWE3XC1KWDZVSKVKMSB99GF9X1WCQTJYS0',
  'SPM2QP0BFNRK47JREXWE3Q557WWNFEXBJZPZDQEN',
  'SP1WY2NB1DXCV4K2H5H88D4G1QNKKZ3VZ398CET8G',
  'ST1N73VJQ4RPCJ4K7VEAXXPQHDACFJ4HSVMKSM2',
  'SP11FQ8ZNC99P06JJ1V3FC9X88VN1KJ9JWMZBGT24',
  'SP9CMJ7S8XR25H6ZKAJXT4M7KSPQ1B8PPVSYJRTC',
  'SP25RRAZ229Z1MRBN0N26CY39XRXG793N5B0SF0RT',
  'SP2TZE09GHARKG0B8NTT9X77QXBTQPQ2J1579T0D8',
  'SP3G9BMCJ0858Y68MM35R6HA0WAZDNYXWZBN4RYK1',
  'SP3BPB30CSNHF29C1SEZZV3ADWWS6131V6TFYAPG1',
  'SP31SRNVFS8J1NC082HCQD8KPKG17DAJ95E7R6N47',
  'SP1TGXH1YXPQ0DKZTYJEJMCQAFHXGM5GSBV9YB5QE',
  'SP1C671AAKE9M5T7BPR7X8F9WRK5W7A4PVMHSSPGZ',
  'SP1J58J25ZY6J9Z07EVHWXWJCNFGBRPPWYNM7W2WD',
  'SP20YNBVHSJDZ4EHMKJC9W44W9T5GC2S37Z800FYS',
  'SP270ZGQ9D4H3D8JSFJ8PNGAS50JXRVRAHMJW2R26',
  'SP152B5BQQXY4BPQ8Y8AXX8WJFKWV7NQ60RBK2ZAS',
  'SP3MYM8YGM8MVT10WKQ3A19E3MAG2H6PHCW8PMZWT',
  'SP27PKT4G30CTRE2W6NA81YBD4YT47VHQ2Y187X8Q',
  'SP3BKTBJ4TEG0ECMW03VGPT429J8MSX842M1CEZG1',
  'SP1XPG9QFX5M95G36SGN9R8YJ4KJ0JB7ZXNH892N6',
  'SP2AW4E8WM686E5BN2C9YB5JG4386KRNC2005F53F',
  'SP48BCJ3QEKFV2SG5KM18651HWBMXB1HR5X60BAH',
  'SP2XJR1NE854KCFPA16RTREN79VWM2CDE3WVQEZ3P',
  'SP3X8M1HA91NKWHQEA5RAE0XA4389HCGGQ9M3GETZ',
  'SP37GHRQC8DTV97CA9ACRPSC2QRXJCHWE11FHCMB0',
  'SP2AF5NV00FERBPWXP7JMXJBCXQTTW7869PAXWQ6C',
  'SP32KNBCPDS2M6CGYNHVFRR855GQVQ340AJ24PETF',
  'SP2Q24T5AYQ7SY42HN5Y2S3V31Z9RWRA1J5MXT4JA',
  'SP15GB7FQAWPCJ79R17D2H4XSD77P5VQD77VT5TP1',
  'SP3C8XDZDW7Y5X2NX3GGS20KHGBZZ2DVX53TXGEFZ',
  'ST3EPWKX6SYSEH36QD71VWBT9CWD5EP00JJ0PSQT2',
  'SP2J5NJB3VY6CMR5WKXPJQPSM3CCET24AC9M2M48B',
  'SP3VHP5FZDEC2WGF5QCJCXBYKDPXNY1BB7JP8TCGS',
  'SP1RJKY3Q0HCHJZGM5FD8ZZH6VFEXQQJYFXNRP721',
  'SP1KM8F5K40JWB28HZW4P1BPWGNJ958KXD0EC0GN6',
  'SP9T7ZJCWDQBWRGXXQ0B5K6J9224JA4S1R7P5VH2',
  'SP2XP5GE1QRNQSX9PW0Z0HW1Y1V6KQ4S2CHBZ09C9',
  'SP3JXAXGZA5JJJ4YHTEW6Q46PKX3VMT0Q0F7JDYF7',
  'SP31WG1RDCABM0AESH0B29F6NSSM6860FMDMJN3RV',
  'SP2X86V3YB6K44B7YSZKA515NEFRW0YM75M1TD8WT',
  'SP3F8H4P82MS7C81EKQ13N6HWH83BB6K8P3QYG1X9',
  'SP2JVE2TEVBXPH1239QK13VZ0V09AF20RC4YCTMJA',
  'SP1EFXDTWEN6SAV0HQ5C32EC9Q8XNDRSDJHSZYYC7',
  'SP3QNWE4TVX9J5CNYR33H7WT9TQVT9XA93GPD2W5W',
  'SP1WNPFD0EMGNK3E7YWZY5KV6TB38CGF92FX06GGY',
  'SP1EFXDTWEN6SAV0HQ5C32EC9Q8XNDRSDJHSZYYC7',
  'SP3HEKPVGABYA0GWJS2ZSQ1XHZYG6ZST1J80CR649',
  'SP1S0W1AQXV4PPDA3CHF57QG6BN3ZWVT8SH9F3W0D',
  'SP3TX36CJSVKH51CAGZ7WTK75XTAVF3FV9JQSMJ6P',
  'SPQCAMFRAXV93WNZHWXSDHSZGZ72G9RJPHXQ8CXN',
  'SP1ZDRF9A95YZQE7YFBR2MDGXTJT43AQV2SSY7A88',
  'SPC9EBR8EPSBJVF736WJW10K3253RNBY18AG5RF9',
  'SP2Y08B8RZCHE91JG8MSTV1ZSW7GH0N34DVZTNAH9',
  'SPQ4SV0NRSNDYKXHNRYA1VT6W9CZC8B306H2890N',
  'SP2H4HFERWC4208VW51BPGT9C2J74MT1W5JDBGZAZ',
  'SPZ5DJGRVZHXEEEYYGWEX84KQB8P69GC715ZRNW1',
  'SP2FDQTSTZR8BA5KJRXC9J946XKA80A30XKYNKVP9',
  'SP2SB5G2XKX7H6H5PH6FKWH3FY80821B4JH2S03K4',
  'SPJ7Z5GWZ01YW327H26JNQ2W7X6Q8ZG9SMF0SVAT',
  'SP2R8FEQJRT2R8E16HN36SK63Y9GEVAE851QAYJ2X',
  'SP3VVKYWFH9TS7N8J5GMA8AV8JYDJQWXVWXX3W7ZD',
  'SP2MCPA9RVQ6K9HZA3T4R1X9C1ECJVJMQ97GPDFW1',
  'SPV00QHST52GD7D0SEWV3R5N04RD4Q1PMA3TE2MP',
]

const fetchSize = 25;
class Arties extends Component<ArtiesProps, ArtiesState> {
  state = {
    name: "",
    contractAddress: "",
    contractName: "",
    description: "",
    image: "",
    total: 0,
    saleData: {},
    sale: [],
    lastTokenID: 0,
    lastTokenFetched: false,
    prices: 0,
    currency: undefined,
    active: false,
    paused: false,
    userData: undefined,
    offset: 1
  }

  componentDidMount() {
    try {
      const userData = localStorage.getItem('userData');
      if (userData) {
        this.setState({
          'userData': JSON.parse(userData)
        })
      }
    } catch {}

    darkmodeListener(styles['theme-light'], styles['theme-dark'])
    fetch(makeEndpoint('/api/drop?name=' + this.props.name))
      .then(res => {
          return res.json()
      })
      .then((artistData) => {
        this.setState({
          name: artistData.name,
          contractAddress: artistData.contractAddress,
          contractName: artistData.contractName,
          description: artistData.description,
          image: artistData.image,
          total: artistData.total,
          prices: artistData.prices,
          active: artistData.active,
          paused: artistData.paused,
          currency: artistData.currency,
        }, () => {
          fetch(makeEndpoint('/api/nft/lastTokenID?address='+artistData.contractAddress+'&name='+artistData.contractName))
          .then(res => { return res.text() })
          .then((lastTokenStr) => {
            const lastToken = parseInt(lastTokenStr, 10)
            this.setState({
              lastTokenID: lastToken,
            }, () => {
              this.fetchNFTs()
            })
          })
          fetch(makeEndpoint('/api/nft/lastTokenID?address='+artistData.contractAddress+'&name='+artistData.contractName+'&reload=true'));
        })
    });
  }

  fetchNFTs() {
    var nfts: Array<any> = []
    var numNFTs = Math.min(this.state.lastTokenID, fetchSize)
    if (this.state.lastTokenID < (this.state.offset + numNFTs)) {
      const diff = (this.state.offset + numNFTs) - this.state.lastTokenID
      numNFTs -= diff;
    }

    for (var x = this.state.offset; x <= this.state.offset + numNFTs; x++) {
      nfts.push({
        "contractAddress": this.state.contractAddress,
        "tokenID": x,
        "contractName": this.state.contractName,
        "sold": true
      });
    }

    var sale: Array<any>  = this.state.sale;
    const filtered_nfts = nfts.filter((r: any) => {
        let filtered_list = sale.filter((l: any) => l.contractAddress == r.contractAddress && l.contractName == r.contractName && String(l.tokenID) == String(r.tokenID));
        return filtered_list.length == 0
    })

    sale.push(...filtered_nfts)
    this.setState({
      'sale': sale
    }, () => {
      this.fetchData(nfts)
    })
  }


  getLength() {
    return this.state.sale.length
  }

  fetchData(nfts: any) {
    for (var nft of nfts) {
      var params = '';
      params += 'address=' + nft.contractAddress;
      params += '&name=' + nft.contractName;
      params += '&id=' + nft.tokenID;
      const nftId = nft.contractAddress + '.' + nft.contractName + ':' + nft.tokenID;
      var saleDataRecord: any = this.state.saleData
      if (saleDataRecord[nftId]) {
          continue;
      }
      fetch(makeEndpoint('/api/nft?' + params))
        .then(res => res.json())
        .then((nftData) => {
          if (nftData.name) {
            var saleData = saleDataRecord;
            saleData[nftId] = nftData;
            this.setState({ saleData });
          }
        })
    }
  }

  getTotal() {
    return this.state.total;
  }

  getAvailable() {
    return this.getTotal() - this.state.lastTokenID;
  }

  getVolumeSold() {
    return this.state.lastTokenID * this.getFloorPrice();
  }

  getFloorPrice() {
    if (!this.state.currency) {
      return (this.state.prices / 1e6);
    } else {
      return this.state.prices;
    }
  }

  nFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  renderCard() {
    return this.state.sale.map((x: any) => {
      const saleData: any = this.state.saleData
      const fullData: any = saleData[x.contractAddress + '.' + x.contractName + ':' + x.tokenID]
      if (fullData) {
        return (
          <Card
            renderer={renderer(fullData)}
            collection={fullData.collection || fullData.name}
            name={fullData.name}
            currency={this.state.currency}
            sold={this.state.prices}/>
        )
      }  else if (x.contractName) {
          const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
          return (
              <Card
                collection={titled}
                renderer={<Renderer />}
                currency={this.state.currency}
                name={titled + ' #' + x.tokenID}/>
          );
      }
    })
  }


  amount() {
    return this.state.prices
  }

  fiveAmount() {
    return this.state.prices * 5
  }

  tenAmount() {
    return this.state.prices * 10
  }

  mintText() {
    const artiesClaimed = localStorage.getItem('artiesClaimed');
    let whitelisted = whitelist.includes(this.stxAddress());

    if (!enable_whitelist) {
      whitelisted = true;
    }

    if (artiesClaimed) {
      var claimedCount = parseInt(artiesClaimed) ?? 0;
      if (claimedCount >= 10) {
        whitelisted = false;
      }
    }

    let mintText;
    if (this.state.active && this.state.paused) {
      mintText = 'MINTING CLOSED';
    } else if (!this.state.active || !whitelisted) {
      mintText = 'CLAIM LIMIT REACHED';
    } else if (this.state.lastTokenID >= this.state.total) {
      mintText = "VISIT MARKETPLACE";
    } else if (this.state.userData) {
      mintText = "MINT"
    } else {
      mintText = "CONNECT WALLET TO MINT"
    }
    return mintText
  }

  renderPrice() {
    return (!((this.state.active && this.state.paused) || (!this.state.active) || (this.state.lastTokenID >= this.state.total))) && (this.state.userData)
  }

  stxAddress() {
    if (this.state.userData) {
      const userData: any = this.state.userData
      return userData.profile.stxAddress.mainnet;
    } else {
      return null;
    }
  }

  authenticate() {
        showConnect({
            appDetails: {
                'name': 'Stacks NFT',
                'icon': icon,
            },
            redirectTo: '/',
            onFinish: () => {
                let userData = userSession.loadUserData();
                this.setState({ userData: userData });
                localStorage.setItem('userData', JSON.stringify(userData));
                window.location.reload();
            },
            userSession: userSession,
        });
    }

  claim(contractAddress: string, contractName: string, salePrice: number, claimNumber: number) {
        const functionArgs: any[] = [];

        let functionName;
        if (claimNumber == 10) {
            functionName = 'claim-ten';
        } else if (claimNumber == 5) {
            functionName = 'claim-five';
        } else {
            functionName = 'claim';
        }

        let standardFungiblePostCondition;
        if (!this.state.currency) {
            standardFungiblePostCondition = makeStandardSTXPostCondition(
                this.stxAddress(),
                FungibleConditionCode.LessEqual,
                new BigNum(salePrice * (claimNumber ?? 1))
            );
        } else if (this.state.currency == 'MIA') {
            const fungibleAssetInfo = createAssetInfo('SP466FNC0P7JWTNM2R9T199QRZN1MYEDTAR0KP27', 'miamicoin-token', 'miamicoin');

            standardFungiblePostCondition = makeStandardFungiblePostCondition(
                this.stxAddress(),
                FungibleConditionCode.LessEqual,
                salePrice,
                fungibleAssetInfo
            );
        }

        const options: any = {
            contractAddress: contractAddress,
            contractName: contractName,
            functionName: functionName,
            network: mainnet,
            functionArgs,
            postConditions: [standardFungiblePostCondition],
            appDetails: {
                'name': 'Stacks NFT',
                'icon': icon,
            },
            onFinish: (data: any) => {
                // this.setState({ 
                //     success: true,
                //     successTransaction: data.txId,
                // });
            },
            onCancel: () => {
                // this.setState({ 
                //     success: false,
                //     errorMessage: "Transaction Failed"
                // });
            }
        };

        openContractCall(options);
    }

  openClaim(contractAddress: string, contractName: string, salePrice: number, tokenID: number) {
      if (this.state.userData !== undefined) {
          this.claim(contractAddress, contractName, salePrice, tokenID)
      } else {
          this.authenticate()
      }
  }

  mintingBehavior(claimNumber: number) {
    let allowed = whitelist.includes(this.stxAddress())
    if (!enable_whitelist) {
      allowed = true
    }

    if (this.state.contractAddress && this.state.contractName) {
        if (!this.state.active || this.state.paused || (!allowed)) {
            // no op
        } else if (this.state.lastTokenID >= this.state.total) {
            window.location.href = '/marketplace/' + this.state.contractName
        } else {
            this.openClaim(this.state.contractAddress, this.state.contractName, this.state.prices, claimNumber)
        }
    }
  }

  mintButton() {
    if (this.amount() >= 0 && this.renderPrice()) {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            price={this.amount()}
            currency={this.state.currency}
            onClick={this.mintingBehavior.bind(this, 1)}/>
          { this.state.userData && this.state.active && !this.state.paused &&
            <Button
              text={this.mintText() + " (CLAIM 5)"}
              price={this.amount() * 5}
              currency={this.state.currency}
              onClick={this.mintingBehavior.bind(this, 5)}/>
          }
          { this.state.userData && this.state.active && !this.state.paused &&
            <Button
              text={this.mintText() + " (CLAIM 10)"}
              price={this.amount() * 10}
              currency={this.state.currency}
              onClick={this.mintingBehavior.bind(this, 10)}/>
          }
        </div>
      )
    } else {
      return (
        <div className={styles.MintButton}>
          <Button text={this.mintText()}
                  onClick={this.mintingBehavior.bind(this, 1)}/>
        </div>
      )
    }
  }

  nextBlocks(offset: number) {
    this.setState({
      'offset': offset
    }, () => {
      this.fetchNFTs()
    })
  }

  getBlocksNext() {
    this.nextBlocks(this.getLength())
  }

  hasMore() {
    return this.state.lastTokenID > this.state.offset
  }

  render() {
    return (
      <div className={styles.Collections}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.name ?? 'Collection'} | STXNFT</title>
          <meta name="description" content={this.state.description.slice(0, 160) ?? 'The best place to find, collect, and sell NFTs secured by Bitcoin. STXNFT is the first open marketplace for Stacks NFTs, with the lowest commission anywhere.'} />
        </Helmet>
        <div className={styles.MintTopBar}>
          <div className={styles.MintImageBorder}>
            <div className={styles.MintImage}>
              <img
                src={this.state.image}
                height={122}
                width={122}
                className={styles.MintImageSrc}/>
            </div>
          </div>
        </div>
        <div className={styles.MintContent}>
          <div className={styles.MintTitle}>
            {this.state.name}
          </div>
          <div className={styles.MintSummary} dangerouslySetInnerHTML={{__html: this.state.description}}>
          </div>
          {this.mintButton()}
          <div className={styles.MintPrice}>
            <div className={styles.MintPriceContainer}>
              <div>
                { this.getTotal().toLocaleString('us') }
              </div>
              <div className={styles.MintPriceTitle}>
                TOTAL
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div>
                { this.getAvailable().toLocaleString('us') }
              </div>
              <div className={styles.MintPriceTitle}>
                REMAINING
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div className={styles.MintPriceIconContainer}>
                {!this.state.currency &&
                  <div className={styles.MintPriceIcon}>
                    <svg width={"15px"} height={"18px"} viewBox={"0 0 15 18"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
                      <g stroke={"none"} stroke-width={"1"} fill={"none"} fill-rule={"evenodd"}>
                        <g transform={"translate(-889.000000, -497.000000)"} stroke={"#707071"}>
                          <g transform={"translate(528.000000, 492.000000)"}>
                            <g transform={"translate(361.000000, 6.000000)"}>
                              <g>
                                <line x1={"3.84615385"} y1={"6.58823529"} x2={"13.0769231"} y2={"6.58823529"}></line>
                                <line x1={"-9.60769925e-17"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"1.53846154"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"13.0769231"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"14.6153846"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"2.30769231"} y1={"0.411764706"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"}></line>
                                <line x1={"8.46153846"} y1={"0.411764706"} x2={"12.3076923"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"} transform={"translate(10.384615, 3.294118) scale(-1, 1) translate(-10.384615, -3.294118) "}></line>
                              </g>
                              <g transform={"translate(7.500000, 12.500000) scale(1, -1) translate(-7.500000, -12.500000) translate(0.000000, 9.000000)"}>
                                <line x1={"3.84615385"} y1={"6.58823529"} x2={"13.0769231"} y2={"6.58823529"}></line>
                                <line x1={"-9.60769925e-17"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"1.53846154"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"13.0769231"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"14.6153846"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"2.30769231"} y1={"0.411764706"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"}></line>
                                <line x1={"8.46153846"} y1={"0.411764706"} x2={"12.3076923"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"} transform={"translate(10.384615, 3.294118) scale(-1, 1) translate(-10.384615, -3.294118) "}></line>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                }
                <div>
                  { this.getFloorPrice().toLocaleString('us') }
                </div>
                {this.state.currency == 'MIA' &&
                  <div className={styles.MintPriceText}>
                    MIA
                  </div>
                }
              </div>
              <div className={styles.MintPriceTitle}>
                MINT PRICE
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div className={styles.MintPriceVolumeContainer}>
                { this.nFormatter(this.getVolumeSold(), 3) }
                {this.state.currency == 'MIA' &&
                  <div className={styles.MintPriceText}>
                    MIA
                  </div>
                }
              </div>
              <div className={styles.MintPriceTitle}>
                VOLUME SOLD
              </div>
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={this.getLength()}
          next={this.getBlocksNext.bind(this)}
          hasMore={this.hasMore()}
          loader={<Loading />}
          className={styles.MintPriceCardContainer}
          scrollableTarget={"page-wrapper"}>
          {this.renderCard()}
        </InfiniteScroll>
      </div>
    )
  }
}

export default Arties

import React, {Component} from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme'

type ColorProps = {}
type ColorState = {
    colors: Array<string>
}

class ColorPalette extends Component<ColorProps, ColorState> {
  state = {
    colors: [
      "#48484A",
      "#999999",
      "#676769",
      "#DC751F",
      "#808080",
      "#7C5E3C",
      "#F6921D",
      "#FFFFFF",
      "#232325",
      "#E3E3E3",
      "#666666",
      "#D8D8D8",
      "#CCCCCC",
      "#7F7F7F",
      "#979797",
      "#5F5CE6",
      "#0084FF",
      "#3C3C3C",
      "#F6921D",
      "#FFFFFF",
      "#999999",
      "#2C2C2E"
    ]
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark'])
    console.log(styles)
  }

  render() {
    return this.state.colors.map((x: string) => 
      <div style={{'background': x}}>
        {x}
      </div>
    )
  }
}

export default ColorPalette;


// "#FFFFFF"
// "#999999"
// "#E0E0E0"
// "#0071E2"
// "#808080"
// "#D6F1FE"
// "#5AC8FA"
// "#757575"
// "#F0F0F0"
// "#E3E3E3"
// "#666666"
// "#D8D8D8"
// "#CCCCCC"
// "#7F7F7F"
// "#979797"
// "#5F5CE6"
// "#0084FF"
// "#3C3C3C"
// "#2081E2"
// "#FFFFFF"
// "#666666"
// "#FFFFFF"

import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

import { convertIPFS } from '../../utils'

type RendererProps = {
  video?: string
  src?: string
  type?: string
  imageClasses: string
  text?: string
}
type RendererState = {}

class Renderer extends Component<RendererProps, RendererState> {
  static defaultProps = {
    imageClasses: []
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <div className={styles.Renderer}>
      {this.props.video &&
        <video
          controls
          className={styles.Video}
          poster={this.props.src}>
          <source src={convertIPFS(this.props.video)}
                  type={this.props.type}/>
        </video>
      }
      {this.props.src && this.props.video == undefined &&
        <img
          className={`${styles.Image} ${this.props.imageClasses ?? ''}`}
          src={convertIPFS(this.props.src)} />
      }
      {this.props.text && this.props.src == undefined &&
        <div className={styles.Text}>
          <div>
            {this.props.text}
          </div>
        </div>
      }
      {this.props.text == undefined && this.props.src == undefined &&
        <div className={styles.UnknownText}>
          ?
        </div>
      }
      </div>
    )
  }
}

export default Renderer;

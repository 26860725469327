import React, { Component } from 'react';
import styles from './index.module.scss'

import * as H from "history";

import { darkmodeListener } from '../../theme';

import TopBar from '../TopBar'

type ReactText = string | number;
type ReactChild = React.ReactElement<any> | ReactText;
type PageWrapperProps = {
  location: H.Location;
  history: H.History;
  children?: any;
  stats?: any;
}

type PageWrapperState = {}

class PageWrapper extends Component<PageWrapperProps, PageWrapperState> {
  componentDidMount() {
    try {
      // console.log(localStorage.getItem('userData'));
    } catch {}
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <div
        className={styles.PageWrapper}
        id={"page-wrapper"}>
        <div
          className={styles.PageWrapperContainer}
          id={"page-wrapper-container"}>
          <TopBar stats={this.props.stats}/>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default PageWrapper;


          // React.cloneElement(this.props.children, {
          //   history: this.props.history,
          //   location: this.props.location
          // })
import React, { Component } from 'react'
import styles from './index.module.scss'

import { AppConfig, UserSession, showConnect } from '@stacks/connect';

import { darkmodeListener } from '../../theme';
import { gaSend } from '../../Analytics';

import icon from './assets/icon.svg'

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

type LoginProps = {}
type LoginState = {
  userData: any
}

class Login extends Component<LoginProps, LoginState> {
  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  authenticate() {
    showConnect({
      appDetails: {
        name: 'Stacks NFT',
        icon: icon,
      },
      redirectTo: '/',
      onFinish: (payload) => {
        console.log(payload);
        gaSend('User', 'authenticate', 'User Authenticated');
        let userData = userSession.loadUserData();
        console.log(userData);
        this.setState({ userData });
        localStorage.setItem('userData', JSON.stringify(userData));
        window.location.reload();
      },
      userSession: userSession,
    });
  }

  render() {
    return (
      <div className={styles.Login} onClick={this.authenticate.bind(this)}>
        <div className={styles.LoginIcon}>
          <svg width={"16px"} height={"16px"} viewBox={"0 0 16 16"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
            <g stroke={"none"} strokeWidth={"1"} fillRule={"evenodd"}>
              <g transform={"translate(-755.000000, -1308.000000)"} fillRule={"nonzero"}>
                <g transform={"translate(730.000000, 1286.000000)"}>
                  <g transform={"translate(25.000000, 18.000000)"}>
                    <g transform={"translate(0.000000, 4.000000)"}>
                      <polygon points={"9.52342327 10.1349085 13.7222204 16 11.9831816 16 7.99326464 10.0227465 4.00334768 16 2.27774761 16 6.47656073 10.1481971 0 10.1481971 0 9 16 9 16 10.1349085"}></polygon>
                      <polygon points={"16 5.82739746 16 6.9866433 16 7 0 7 0 5.82739746 6.38252166 5.82739746 2.22400872 0 3.96304751 0 7.99326464 6.09160129 12.0369365 0 13.7759753 0 9.61741435 5.82739746"}></polygon>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={styles.LoginText}>
          LOGIN
        </div>
      </div>
    )
  }
}

export default Login;

import React, { Component } from 'react'
import styles from './index.module.scss'

import Actions from './Actions'
import Details from './Details'
import Renderer from '../../Renderer'

import Button from '../../Button'
import Select from '../Select'

import { darkmodeListener } from '../../../theme';

type FrontProps = {
  attributes?: any
  attributesAction?: (...args: any[]) => void
  bid?: number
  buy?: number
  crop: 'COVER' | 'CONTAIN' | 'FILL',
  renderer?: Renderer
  bidFunction?: (...args: any[]) => void
  buyFunction?: (...args: any[]) => void
  sendFunction?: (...args: any[]) => void
  sellFunction?: (...args: any[]) => void
  unlistFunction?: (...args: any[]) => void
  link?: (...args: any[]) => void
  profile?: (...args: any[]) => void
  bidStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  buyStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  sellStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  sendStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  badges?: Array<'VERIFIED' | 'ERROR' | 'FEATURED'>
  collection?: string
  name?: string
  sold?: number
  soldFlag?: boolean
  currency?: 'STX' | 'MIA' | 'BTC'
}

type FrontState = {}

class Front extends Component<FrontProps, FrontState> {
  static defaultProps = {
    'crop': 'COVER',
  }

  setCrop() {
    if (this.props.crop ==  'COVER') {
      return { "object-fit": "cover" }
    } else if (this.props.crop ==  'CONTAIN') {
      return { "object-fit": "contain" }
    } else {
      return { "object-fit": "fill" }
    }
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <div className={styles.Front}>
        {
          this.props.renderer && this.props.link &&
            <span className={styles.CardImgWrapper} onClick={this.props.link.bind(this)}>
              {this.props.renderer}
            </span>
        }
        {
          this.props.renderer && this.props.link == undefined &&
          this.props.renderer
        }
        <Select
          profile={this.props.profile}/>
        <Details
          attributes={this.props.attributes}
          attributesAction={this.props.attributesAction}
          badges={this.props.badges}
          collection={this.props.collection}
          name={this.props.name}/>
        <Actions
          bid={this.props.bid}
          bidFunction={this.props.bidFunction}
          buy={this.props.buy}
          buyFunction={this.props.buyFunction}
          sendFunction={this.props.sendFunction}
          sellFunction={this.props.sellFunction}
          unlistFunction={this.props.unlistFunction}
          bidStyle={this.props.bidStyle}
          buyStyle={this.props.buyStyle}
          sellStyle={this.props.sellStyle}
          sendStyle={this.props.sendStyle}
          sold={this.props.sold}
          soldFlag={this.props.soldFlag}
          currency={this.props.currency}/>
      </div>
    )
  }
}

export default Front;

export function setColor(mode, styles) {
  for (var x = 0; x < styles.length; x++) {
    if (x != mode) {
      document.documentElement.classList.remove(styles[x]);
    }
  }
  document.documentElement.classList.add(styles[mode]);
}

export function darkmodeListener(light, dark) {
  const theme_mode =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 1
      : 0;
  setColor(theme_mode, [light, dark]);

  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', (e) => {
      setColor(e.matches ? 1 : 0, [light, dark]);
    });
}

import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

import { renderPrice } from '../../utils'

type ButtonProps = {
  text: string
  onClick?: (...args: any[]) => void
}

type ButtonState = {}

class Button extends Component<ButtonProps, ButtonState> {
  static background = {
    PRIMARY: styles.Primary,
    SECONDARY: styles.Secondary,
    ERROR: styles.Error,
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }


  onClick() {
    if(this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    return (
      <div
        className={styles.StacculentsAdopt}
        onClick={this.onClick.bind(this)}
      >
        <span>
        {/* render text */}
        {this.props.text.toUpperCase()}
        </span>
      </div>
    )
  }
}

export default Button

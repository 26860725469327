import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

import Renderer from '../Renderer'
import Button from '../Button'

type TransferProps = {
  onClose?: (...args: any[]) => void
  image?: string
  imageClasses: string
  collection: string
  name: string
  error: string
  address: string
  changeAddress: (...args: any[]) => void
  onTransfer: (...args: any[]) => void
}

type TransferState = {}

class Transfer extends Component<TransferProps, TransferState> {
  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.changeAddress(event.target.value)
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  keyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode == 13) {
      this.props.onTransfer()
    }
  }

  inputCSS() {
    if (this.props.error != "") {
      return `${styles.TransferInput} ${styles.TransferInputError}`
    } else {
      return `${styles.TransferInput}`
    }
  }

  render() {
    return (
      <div className={styles.Transfer}>
        <div
          className={styles.TransferClose}
          onClick={this.onClose.bind(this)}>
          <svg width={"14px"} height={"14px"} viewBox={"0 0 14 14"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
            <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
              <g transform={"translate(-329.000000, -1101.000000)"} strokeWidth={"2"}>
                <g transform={"translate(41.000000, 1094.000000)"}>
                  <g transform={"translate(289.000000, 8.000000)"}>
                    <line x1={"12"} y1={"0"} x2={"0"} y2={"12"}></line>
                    <line x1={"0"} y1={"0"} x2={"12"} y2={"12"}></line>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={styles.TransferImage}>
          <Renderer src={this.props.image} imageClasses={this.props.imageClasses}/>
        </div>
        <div className={styles.TransferCollection}>
          {this.props.collection}
        </div>
        <div className={styles.TransferDescription}>
          {this.props.name}
        </div>
        <div className={styles.TransferInputContainer}>
          {this.props.error != "" &&
            <div className={styles.TransferError}>
              { this.props.error }
            </div>
          }
          <input
            type={"text"}
            className={this.inputCSS()}
            value={this.props.address}
            onChange={this.handleChange.bind(this)}
            onKeyDown={this.keyDown.bind(this)}
            placeholder={"STX or BNS Address"}>
          </input>
        </div>
        <div className={styles.TransferButton}>
          <Button
            text={"TRANSFER"}
            onClick={this.props.onTransfer.bind(this)}/>
        </div>
      </div>
    )
  }
}

export default Transfer
import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

type DropProps = {
  new?: boolean
  onClick: (...args: any[]) => void
  image: string
  name: string
  description: string
}
type DropState = {}

class Drop extends Component<DropProps, DropState> {
  renderNew() {
    if (this.props.new) {
      return (
        <div className={styles.DropsNew}>
          NEW
        </div>
      );
    }
  }

  render() {
    return (
      <div className={styles.DropsPadding}
          onClick={this.props.onClick.bind(this)}>
        <div className={styles.Drops}>
          <img className={styles.DropsImage}
           src={this.props.image}/>
           <div className={styles.DropsData}>
             <div  className={styles.DropsDataName}>
               {this.props.name}
             </div>
             <div  className={styles.DropsDataStats}>
               {this.props.name}
             </div>
             <div className={styles.DropsDataBackground}>
               {this.props.description}
             </div>
             { this.renderNew() }
           </div>
        </div>
      </div>
    );
  }
}

export default Drop;
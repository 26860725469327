import React, { Component } from 'react'
import styles from './index.module.scss'

import { AppConfig, UserSession, showConnect } from '@stacks/connect';
import { gaSend } from '../../Analytics';

import Filters from '../../components/Filters'
import Status from '../../components/Status'
import Collections from '../../components/Collections'
import Search from '../../components/Search'

import Card from '../../components/Card'
import Confirm from '../../components/Confirm'
import Dropdown from '../../components/Dropdown'
import List from '../../components/List'
import Modal from '../../components/Modal'
import Transfer from '../../components/Transfer'
import Renderer from '../../components/Renderer'

import icon from '../../components/Login/assets/icon.svg'

import ErrorPage from '../../pages/ErrorPage'

import makeEndpoint from '../../endpoints'
import { localPermalink, renderer } from '../../utils'

import {
  transfer,
  listAsset,
  unlistAsset,
  purchaseAsset
} from '../../Contracts'

import {
  uintCV,
  intCV,
  bufferCV,
  stringAsciiCV,
  stringUtf8CV,
  standardPrincipalCV,
  trueCV,
  makeStandardFungiblePostCondition,
  FungibleConditionCode,
  createAssetInfo,
  makeStandardNonFungiblePostCondition,
  NonFungibleConditionCode,
  makeStandardSTXPostCondition
} from '@stacks/transactions';


const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });


const whitelistedNFTs = [
'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.wasteland-apes-nft',
'SPXGFH9JTKPF2TQZJ2AH7NSMMMXJ72VMGH8PR654.itwillnotbetelevised',
'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.bitcoin-whales',
'SP2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG4V39WPY.stacks-roots-v2',
'SP39HFKW38EPPPRQ1R52GK02WCN8314DQAQHF6EZ6.Wolf-Pack-Academy-V1',
'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.ghost-rock',
'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.wgmiami',
'SP39HFKW38EPPPRQ1R52GK02WCN8314DQAQHF6EZ6.Bitcoin-Kitties',
'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.get-a-life',
'SP3QSAJQ4EA8WXEDSRRKMZZ29NH91VZ6C5X88FGZQ.crashpunks-v2',
'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.trubit-rose',
'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.trubit-domino',
'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.happy-welsh',
'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.magic-ape-school',
'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.megapont-robot-component-nft',
'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.megapont-robot-nft',
'SP2CNW7ZAQRXPP8KRXM6VCC8H2XS8QNETAMEMBEX9.citypack-airdrop',
'SP2RJP81KF3V6NJVZEZ2SR8DD73VQJC98EJSTQWDV.dcards-v4',
'SP176ZMV706NZGDDX8VSQRGMB7QN33BBDVZ6BMNHD.project-indigo-act1',
'SP2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG4V39WPY.special-ingredient',
'SP2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG4V39WPY.punk-donuts',
'SPS51PEXKRDZMR0NYPYMM1EH2Y054T3ND173N0NW.ag-airdrop',
'SPS51PEXKRDZMR0NYPYMM1EH2Y054T3ND173N0NW.ab-airdrop',
'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.macaws',
'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.fishy-business',
'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.theopetra-pawn',
'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.theopetra-knight',
'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.theopetra-rook',
'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.theopetra-king',
'SPKPXQ0X3A4D1KZ4XTP1GABJX1N36VW10D02TK9X.citypacks-nft-001',
'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-stacks-samurai',
'SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA.african-queen-mother',
'SP27SD3H5TTZXPBFXHN1ZNMFJ3HNE2070QWHH3BXX.cube',
'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.wolf-pack-academy-v1',
'SP2C2YFP12AJZB4MABJBAJ55XECVS7E4PMMZ89YZR.arkadiko-nft-shades',
'SPZW30K9VG6YCPYV4BX4V1FT0VJ66R1Q01W9DQ1W.commoners',
'SPZW30K9VG6YCPYV4BX4V1FT0VJ66R1Q01W9DQ1W.city-collection-nyc',
'SPZW30K9VG6YCPYV4BX4V1FT0VJ66R1Q01W9DQ1W.city-collection-mia',
'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.miamicoin-artweek',
'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.frontier',
'SP3N81TKV43PN24NPHNNM8BBNQJ51Q31HE9G0GC46.bubo',
'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.bitcoin-monkeys',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-bitcoin-bears',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.genesis-64',
  'SPZW30K9VG6YCPYV4BX4V1FT0VJ66R1Q01W9DQ1W.pokinometry',
  'SPZW30K9VG6YCPYV4BX4V1FT0VJ66R1Q01W9DQ1W.vaguearts-gods',
  'SPZW30K9VG6YCPYV4BX4V1FT0VJ66R1Q01W9DQ1W.borges-mia-btc-fam',
  'SPZW30K9VG6YCPYV4BX4V1FT0VJ66R1Q01W9DQ1W.other-worlds',
  'SPZW30K9VG6YCPYV4BX4V1FT0VJ66R1Q01W9DQ1W.htl',
  'SP2D5BGGJ956A635JG7CJQ59FTRFRB0893514EZPJ.bitgear-genesis',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-skaters',
  'SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA.helias',
  'SP1T4Y4WK9DGZ2EDWSNHRE5HRRBPVG7S46JAHW552.panda-nft',
  'SP32AEEF6WW5Y0NMJ1S8SBSZDAY8R5J32NBZFPKKZ.free-punks-v0',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.funky-donuts',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.future-of-money',
  'SP1QK1AZ24R132C0D84EEQ8Y2JDHARDR58R72E1ZW.boomboxes-cycle-18',
  'SP1QK1AZ24R132C0D84EEQ8Y2JDHARDR58R72E1ZW.boomboxes-cycle-20-v1',
  'SP1QK1AZ24R132C0D84EEQ8Y2JDHARDR58R72E1ZW.boomboxes-cycle-20-v2',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.cyber-angels',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-punks-v3',
  'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.megapont-ape-club-nft',
  'SP2BE8TZATXEVPGZ8HAFZYE5GKZ02X0YDKAN7ZTGW.arties',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.bitcoin-pizza',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-satoshi-knights',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-homagic',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.blocks',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-mandala',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.testing-liquidity',
  'SPQZF23W7SEYBFG5JQ496NMY0G7379SRYEDREMSV.Candy',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-bitcoin-kittens',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.owls-v2',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.belles-witches',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.citadels',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-pops',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.stacculents',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.pox-monks',
  'SP1SCEXE6PMGPAC6B4N5P2MDKX8V4GF9QDE1FNNGJ.nyc-degens',
  'SP1SCEXE6PMGPAC6B4N5P2MDKX8V4GF9QDE1FNNGJ.miami-degens',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-genesis',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.miami-vice',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-stacks-dragons',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.satoshis-team',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-stacks-foxes-community',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-matryoshka-dolls',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-bitcoin-tulips',
  'SPASZ0VJQTS22YR4HCCAGR05GGRF9V8TCM219YQ8.tiles',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.anime-girls',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.bulls',
  'SP3QSAJQ4EA8WXEDSRRKMZZ29NH91VZ6C5X88FGZQ.thisisnumberone-v2',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.zebras',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.picasso-magic-1',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-animal-stacks',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.fruits',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.spacewhales',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.deruptars',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.stacks-army',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-blurred-museum',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.anime-boys',
  'SP497E7RX3233ATBS2AB9G4WTHB63X5PBSP5VGAQ.boomboxes-cycle-12',
  'SP497E7RX3233ATBS2AB9G4WTHB63X5PBSP5VGAQ.boomboxes-cycle-14',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-sadoughshis-bitcoin-pizza',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-snowball-rabbits',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.dreamcatchers',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.bitcoin-birds',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.phases-of-satoshi',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-vampire-slayers',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.roads',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-fruitmen',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.hexstx',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.mandelbrots-v1',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.crypto-graffiti',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-cyborg',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.cybernetic-souls',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.byte-fighters',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.mother-of-satoshi',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.metaraffes',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-vague-art-paintings',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.mother-satoshi-2',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-english-hat-club',
  'SP3QSAJQ4EA8WXEDSRRKMZZ29NH91VZ6C5X88FGZQ.ruma-v1',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.mandelbrots',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-shroomies',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.rangoli',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.picasso-magic-2',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.zombie-wabbits',
  'SP2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG4V39WPY.stacks-roots',
  'SP1QK1AZ24R132C0D84EEQ8Y2JDHARDR58R72E1ZW.boomboxes-cycle-16',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.blue-ridge-biker',
  'SP497E7RX3233ATBS2AB9G4WTHB63X5PBSP5VGAQ.boom-nfts',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.saints',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.snacks',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-proof-of-puf',
  'SP32AEEF6WW5Y0NMJ1S8SBSZDAY8R5J32NBZFPKKZ.free-punks-v0',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.funky-donuts',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.future-of-money',
  'SP1QK1AZ24R132C0D84EEQ8Y2JDHARDR58R72E1ZW.boomboxes-cycle-18',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.cyber-angels',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-punks-v3',
  'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.megapont-ape-club-nft',
  'SP2BE8TZATXEVPGZ8HAFZYE5GKZ02X0YDKAN7ZTGW.arties',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.bitcoin-pizza',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-satoshi-knights',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-homagic',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.blocks',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-mandala',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.testing-liquidity',
  'SPQZF23W7SEYBFG5JQ496NMY0G7379SRYEDREMSV.Candy',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-bitcoin-kittens',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.belles-witches',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.citadels',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-pops',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.stacculents',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.pox-monks',
  'SP1SCEXE6PMGPAC6B4N5P2MDKX8V4GF9QDE1FNNGJ.miami-degens',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-genesis',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.miami-vice',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-stacks-dragons',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.satoshis-team',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-stacks-foxes-community',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-matryoshka-dolls',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-bitcoin-tulips',
  'SPASZ0VJQTS22YR4HCCAGR05GGRF9V8TCM219YQ8.tiles',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.anime-girls',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.bulls',
  'SP3QSAJQ4EA8WXEDSRRKMZZ29NH91VZ6C5X88FGZQ.thisisnumberone-v2',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.zebras',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.picasso-magic-1',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-animal-stacks',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.fruits',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.spacewhales',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.deruptars',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.stacks-army',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-blurred-museum',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.anime-boys',
  'SP497E7RX3233ATBS2AB9G4WTHB63X5PBSP5VGAQ.boomboxes-cycle-12',
  'SP497E7RX3233ATBS2AB9G4WTHB63X5PBSP5VGAQ.boomboxes-cycle-14',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-sadoughshis-bitcoin-pizza',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-snowball-rabbits',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.dreamcatchers',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.bitcoin-birds',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.phases-of-satoshi',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-vampire-slayers',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.roads',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-fruitmen',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.hexstx',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.mandelbrots-v1',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.crypto-graffiti',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-cyborg',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.cybernetic-souls',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.byte-fighters',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.mother-of-satoshi',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.metaraffes',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-vague-art-paintings',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.mother-satoshi-2',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-english-hat-club',
  'SP3QSAJQ4EA8WXEDSRRKMZZ29NH91VZ6C5X88FGZQ.ruma-v1',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.mandelbrots',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-shroomies',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.rangoli',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.picasso-magic-2',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.zombie-wabbits',
  'SP2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG4V39WPY.stacks-roots',
  'SP1QK1AZ24R132C0D84EEQ8Y2JDHARDR58R72E1ZW.boomboxes-cycle-16',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.blue-ridge-biker',
  'SP497E7RX3233ATBS2AB9G4WTHB63X5PBSP5VGAQ.boom-nfts',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.anime-girls',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.future-of-money',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.picasso-magic-1',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.picasso-magic-2',
  'SP2Z2BST4Z2RFSDTF7GJ4D07VXCFRBRTR1JPCCE7J.spooky-staccs',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.ueberkatzen',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.cyber-stx',
  'SP2BE8TZATXEVPGZ8HAFZYE5GKZ02X0YDKAN7ZTGW.arties',
  'SP2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG4V39WPY.stacks-roots',
  'SPASZ0VJQTS22YR4HCCAGR05GGRF9V8TCM219YQ8.tiles',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.mother-of-satoshi',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.spacewhales',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.satoshis-team',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.hexstx',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.testing-liquidity',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.mandelbrots-v1',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.pox-monks',
  'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.crypto-graffiti',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.phases-of-satoshi',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.blue-ridge-biker',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-pops',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.bitcoin-birds',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.belles-witches',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.byte-fighters',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.blocks',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stx-youth',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stx-jokers',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.virtual-tulips',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.funky-donuts',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.zombie-wabbits',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-mandala',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.citadels',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-snowball-rabbits',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-storm',
  'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.megapont-ape-club-nft',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-nakamoto-guardians',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-bitcutties',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-bitcoin-pixel-world-v1',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-vampire-slayers',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-cyborg',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-vague-art-paintings',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-homagic',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-sadoughshis-bitcoin-pizza',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-fruitmen',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-shroomies',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-satoshi-knights',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-bitcoin-kittens',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-the-himalayan-trinity',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-blurred-museum',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-bitcoin-tulips',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-stacks-foxes-community',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.bitcoin-birds',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-animal-stacks',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-stacks-dragons',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-english-hat-club',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-matryoshka-dolls',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-genesis',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.deruptars',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.mandelbrots-v1',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-stacksboi',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-african-royalty',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-bitzombies',
  'SP2X0TZ59D5SZ8ACQ6YMCHHNR2ZN51Z32E2CJ173.the-explorer-guild',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-mad-pandas',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-stacks-parrots',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-stacks-pixels',
  'SP3M7N9Q9HDRM7RVP1Q26P0EE69358PZZAX4MD19Q.nft-nyc-exclusive',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.entangled-flowers',
//'SP38FN88VZ97GWV0E8THXRM6Z5VMFPHFY4J1JEC5S.btc-badgers',
  'SP27F9EJH20K3GT6GHZG0RD08REZKY2TDMD6D9M2Z.btc-badgers-v2',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-groot',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-lost-explorers',
  'SP1CSHTKVHMMQJ7PRQRFYW6SB4QAW6SR3XY2F81PA.stxplates',
  'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-giantpandas',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-the-nostalgia-machine',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-hero-of-miami',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-bitcoin-bulls',
  'SP207ESW7AKHTPRYAAD9QP8Q1TE1F57D2S8RGPJCC.miami-beach',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-space-lambos',
  'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.openxis',
'SPZW30K9VG6YCPYV4BX4V1FT0VJ66R1Q01W9DQ1W.stacks-ludenz',
'SP2KAF9RF86PVX3NEE27DFV1CQX0T4WGR41X3S45C.byzantion-space-lambos',
'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.shapes',
]

type ProfileViewProps = {
  user: string
  open: boolean
  openCarret: (...args: any[]) => void
  status: boolean
  statusOpen: (...args: any[]) => void 
  statusPills: Array<string>
  statusSelected: Array<string>
  setStatusSelected: (...args: any[]) => void 
  collection: boolean
  collectionOpen: (...args: any[]) => void  
  collections: any
  setActive: (index?: string) => void
  activeCollection?: string
  filter: string
  setFilter: (filter: string) => void
  setSelected: (selected: string) => void
  listings: Array<any>
  raw: Array<any>
  sold: Array<any>
  data: any
}

type ProfileViewState = {
  success?: boolean
  message?: string
  userData?: any
  transfer?: any
  list?: any
  transferAddress: string
  transferError: string
  listPrice: string
  listError: string
  royalty?: number
  collections?: undefined
}

class ProfileView extends Component<ProfileViewProps, ProfileViewState> {
  state = {
    success: undefined,
    message: undefined,
    userData: undefined,
    transfer: undefined,
    list: undefined,
    transferAddress: "",
    transferError: "",
    listPrice: "",
    listError: "",
    royalty: undefined,
    collections: undefined
  }

  changeTransferAddress(address: string) {
    this.setState({
      transferAddress: address
    })
  }

  changeListPrice(price: string) {
    this.setState({
      listPrice: price
    })
  }

  getCollections() {
    fetch(makeEndpoint('/api/marketplace/v1/collections'))
      .then(res => res.json())
      .then((collections) => {
          this.setState({ collections });
      })
  }

  componentDidMount() {
    try {
      const userData = localStorage.getItem('userData');
      if (userData) {
        this.setState({ 'userData': JSON.parse(userData) })
      }
    } catch {}
    this.getCollections()
  }

  stxAddress() {
    if (this.state.userData) {
      const userData: any = this.state.userData
      return userData.profile.stxAddress.mainnet;
    } else {
      return null;
    }
  }

  async checkRecipient(value: string) {
        // First check to see if it's a BNS address
        if (value.indexOf('.') !== -1) {
            let res = await fetch(makeEndpoint('/api/bns?name=' + value));
            if (res.status == 200) {
                let text = await res.text();
                return text;
            } else {
                return null;
            }
        } else {
            try {
                const principal = standardPrincipalCV(value);
                return value;
            } catch (e) {
                return false;
            }
        }
    }

  async sendTransferRequest() {
        const value = this.state.transferAddress
        const recipient = await this.checkRecipient(this.state.transferAddress);
        if (recipient && this.state.transfer) {
            const onFinish = (data: any) => {
                console.log('Stacks Transaction:', data.stacksTransaction);
                console.log('Transaction ID:', data.txId);
                console.log('Raw transaction:', data.txRaw);
                gaSend('Transaction', 'transfer', 'NFT Transferred');
                this.setState({
                  transfer: undefined,
                  transferError: "",
                  transferAddress: "",
                  success: true,
                  message: data.txId
                });
            }
            const onCancel = () => {
              this.setState({
                transfer: undefined,
                transferError: "",
                transferAddress: "",
                success: false,
                message: "Transaction Failed"
              });
            }
            const transferParams: any = this.state.transfer
            transfer(
                transferParams.contractAddress,
                transferParams.contractName,
                transferParams.assetName,
                transferParams.tokenID,
                this.stxAddress(),
                recipient,
                onFinish,
                onCancel
            );
            this.setState({
              transfer: undefined,
              transferError: "",
              transferAddress: "",
              success: undefined,
              message: undefined
            });
        } else {
          this.setState({
            transferError: "Invalid Recipient"
          });
        }
    }

  listIt() {
        const price = this.state.listPrice;
        if (!isNaN(parseFloat(price))) {
          const onFinish = (data: any) => {
              console.log('Stacks Transaction:', data.stacksTransaction);
              console.log('Transaction ID:', data.txId);
              console.log('Raw transaction:', data.txRaw);
              gaSend('Transaction', 'list', 'NFT Listed', parseInt(price)/1e6);
              this.setState({
                  list: undefined,
                  listPrice: "",
                  listError: "",
                  success: true,
                  message: data.txId,
              });
          }
          const onCancel = () => {
              gaSend('Transaction', 'list-cancel', 'NFT List Cancelled', parseInt(price)/1e6);
              this.setState({
                  list: undefined,
                  listPrice: "",
                  listError: "",
                  success: false,
                  message: "Transaction Failed"
              });
          }
          const list: any = this.state.list
          listAsset(
              list.contractAddress,
              list.contractName,
              list.assetName,
              list.tokenID,
              parseInt(parseFloat(price) * 1e6 + "", 10),
              100,
              this.stxAddress(),
              onFinish,
              onCancel
          );
        } else {
          this.setState({
            listError: "Price Must Be A Number"
          });
        }
    }

  authenticate() {
    showConnect({
      appDetails: {
        name: 'Stacks NFT',
        icon: icon,
      },
      redirectTo: '/',
      onFinish: () => {
        gaSend('User', 'authenticate', 'User Authenticated');
        let userData = userSession.loadUserData();
        localStorage.setItem('userData', JSON.stringify(userData));
        window.location.reload();
      },
      userSession: userSession,
    });
  }

  unlistNFT = (x: any) => {
        const onFinish = (data: any) => {
            console.log('Stacks Transaction:', data.stacksTransaction);
            console.log('Transaction ID:', data.txId);
            console.log('Raw transaction:', data.txRaw);
            gaSend('Transaction', 'unlist', 'NFT Unlisted');
            this.setState({ 
                success: true,
                message: data.txId,
            });
        };
        const onCancel = () => {
            this.setState({ 
                success: false,
                message: "Unlisting Canceled"
            });
        };
        unlistAsset(x, onFinish, onCancel);
    }

  purchaseNFT = (x: any) => {
        if (!this.stxAddress()) {
            this.authenticate();
            return;
        }

        const onFinish = (data: any) => {
            console.log('Stacks Transaction:', data.stacksTransaction);
            console.log('Transaction ID:', data.txId);
            console.log('Raw transaction:', data.txRaw);
            this.setState({ 
                success: true,
                message: data.txId,
            });
        };
        const onCancel = () => {
            this.setState({ 
                success: false,
                message: "Purchase canceled"
            });
        }

        purchaseAsset(x, this.stxAddress(), onFinish, onCancel);
    }

  unlistFunction(nft: any) {
    const removable = (nft.listing && (this.stxAddress() == nft.listing.owner) && !nft.listing.sold);
    var unlistFunction = () => {
      this.unlistNFT(nft)
    }

    if (removable) {
      return unlistFunction
    }

    return undefined
  }

  getRoyalty(x: any) {
    const royaltyEndpoint = '/api/marketplace/royalty?address=' + x.contractAddress + '&name=' + x.contractName;
    fetch(makeEndpoint(royaltyEndpoint))
        .then(res => res.json())
        .then((royalty: any) => {
            this.setState({ 'royalty': parseFloat(royalty.royalty) });
        });
  }

  buyFunction(nft: any) {
    const buyable = (nft.listing && (nft.listing.owner != this.stxAddress()) && !nft.listing.sold)
    var buyFunction = () => {
      this.purchaseNFT(nft)
    }

    if (buyable) {
      return buyFunction
    }

    return undefined
  }


  sellFunction(nft: any) {
    var transferable = (this.props.user == this.stxAddress()) && (nft.contractName !== 'bns') && !nft.listing && (whitelistedNFTs.indexOf(nft.contractAddress + '.' + nft.contractName) !== -1);

    transferable = transferable || ((nft.contractAddress + '.' + nft.contractName == 'SP38FN88VZ97GWV0E8THXRM6Z5VMFPHFY4J1JEC5S.btc-badgers') && (this.props.user == this.stxAddress()) && (this.stxAddress() == 'SP25VWGTPR19E344S4ENTHQT8651216EPNABRYE51'));

    var sellFunction = () => {
      try {
        this.getRoyalty(nft)
      } catch {}
      this.setState({
        list: nft
      });
    }
    if (transferable) {
      return sellFunction
    }

    return undefined
  }

  sendFunction(nft: any) {
    const transferable = (this.props.user == this.stxAddress()) && (nft.contractName !== 'bns') && !nft.listing;

    var sendFunction = () => {
      this.setState({
        transfer: nft
      });
    }
    if (transferable) {
      return sendFunction
    }
    return undefined
  }

  onClose() {
    this.setState({
      transfer: undefined,
      transferError: "",
      transferAddress: "",
      list: undefined,
      success: undefined,
      message: undefined,
      listPrice: "",
      listError: ""
    })
  }

  renderList() {
    if (this.state.list) {
      const list: any = this.state.list
      const key = list.contractAddress + '.' + list.contractName + ':' + list.tokenID
      const nftData = this.props.data[key]
      return (
        <List
          price={this.state.listPrice}
          royalty={this.state.royalty}
          error={this.state.listError}
          changePrice={this.changeListPrice.bind(this)}
          onList={this.listIt.bind(this)}
          collection={nftData.collection}
          name={nftData.name}
          image={nftData.image || nftData.imageUrl}
          imageClasses={(nftData.collection && nftData.collection.indexOf('Punks') !== -1)? 'pixelated': ''}
          onClose={this.onClose.bind(this)}/>
      )
    }
  }

  renderTransfer() {
    if (this.state.transfer) {
      const transfer: any = this.state.transfer
      const key = transfer.contractAddress + '.' + transfer.contractName + ':' + transfer.tokenID
      const nftData = this.props.data[key]
      return (
        <Transfer
          collection={nftData.collection}
          name={nftData.name}
          address={this.state.transferAddress}
          error={this.state.transferError}
          changeAddress={this.changeTransferAddress.bind(this)}
          image={nftData.image || nftData.imageUrl}
          imageClasses={(nftData.collection && nftData.collection.indexOf('Punks') !== -1)? 'pixelated': ''}
          onTransfer={this.sendTransferRequest.bind(this)}
          onClose={this.onClose.bind(this)}/>
      )
    }
  }

  renderConfirm() {
    if (this.state.success != undefined && !this.state.success) {
      return (
        <Confirm
          onClose={this.onClose.bind(this)}
          errorMessage={this.state.message}
          success={this.state.success} />
      )
    } else if(this.state.success) {
      return (
        <Confirm
          onClose={this.onClose.bind(this)}
          transactionId={this.state.message}
          success={this.state.success} />
      )
    }
  }

  badges(nft: any) {
    var badgeArr: any = []
    if (this.state.collections) {
      const collections: any = this.state.collections
      for (var index = 0; index < collections.length; index++) {
        if (this.state.collections[index]['contractAddress'] == nft.contractAddress &&  this.state.collections[index]['contractName'] == nft.contractName &&  this.state.collections[index]['verified']) {
          badgeArr.push('VERIFIED')
        }
      }
    }
    return badgeArr
  }

  renderer(nft: any) {
    return (
      <Renderer src={nft.image || nft.imageUrl} imageClasses={(nft.collection && nft.collection.indexOf('Punks') !== -1)? 'pixelated': ''}/>
    )
  }

  getAttributes(nft: any) {
    if (nft.attributes && Array.isArray(nft.attributes)) {
      return nft.attributes
    }
  }

  render() {
    if (this.props.raw.length != 0 || this.props.sold.length != 0 || this.props.listings.length != 0) {
      return (
        <div className={styles.ProfileView}>
          {this.state.list &&
            <Modal onClose={this.onClose.bind(this)}>
              {this.renderList()}
            </Modal>
          }
          {this.state.transfer &&
            <Modal onClose={this.onClose.bind(this)}>
              {this.renderTransfer()}
            </Modal>
          }
          {this.state.success != undefined &&  this.state.message != undefined &&
            <Modal onClose={this.onClose.bind(this)}>
              {this.renderConfirm()}
            </Modal>
          }
          {/*<div className={styles.ProfileViewTopBar}>
            <div className={styles.ProfileViewSearch}>
            <Search
                filter={this.props.filter}
                setFilter={this.props.setFilter.bind(this)}/>
            </div>
            <Dropdown setSelected={this.props.setSelected}/>
          </div>*/}
          <div className={styles.ProfileViewContainer}>
            {/*<div className={styles.ProfileViewFilters}>
              <Filters
                open={this.props.open}
                onClick={this.props.openCarret.bind(this)}/>
              {this.props.open &&
                <Status
                  open={this.props.status}
                  onOpen={this.props.statusOpen.bind(this)}
                  statusPills={this.props.statusPills}
                  statusSelected={this.props.statusSelected}
                  setStatusSelected={this.props.setStatusSelected.bind(this)}/>
              }
              {this.props.open &&
                <Collections
                  open={this.props.collection}
                  onOpen={this.props.collectionOpen.bind(this)}
                  collections={this.props.collections}
                  setActive={this.props.setActive.bind(this)}
                  active={this.props.activeCollection}
                  data={{}}/>
              }
            </div>*/}
            <div className={styles.ProfileViewCardContainer}>
              {(this.props.listings && this.props.listings.length > 0) &&
                  this.props.listings.map((x: any ) => {
                    const key = x.contractAddress + '.' + x.contractName + ':' + x.tokenID
                    const nftData = x.data ?? this.props.data[key]
                    if (nftData) {
                      return (
                        <Card
                          badges={this.badges(x)}
                          renderer={renderer(nftData)}
                          collection={nftData.collection || nftData.name}
                          name={nftData.name}
                          rarityRank={nftData.rarityRank}
                          attributes={this.getAttributes(nftData)}
                          link={()=>{localPermalink(x)}}
                          buy={x.listing.price}
                          buyFunction={this.buyFunction(x)}
                          sellFunction={this.sellFunction(x)}
                          sendFunction={this.sendFunction(x)}
                          unlistFunction={this.unlistFunction(x)}/>
                      );
                    } else {
                      const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
                      return (
                        <Card
                          badges={this.badges(x)}
                          renderer={<Renderer />}
                          collection={titled}
                          name={titled + ' #' + x.tokenID} />
                      );
                    }
                  })
              }
              {(this.props.raw && this.props.raw.length > 0) &&
                  this.props.raw.map((x: any ) => {
                    const key = x.contractAddress + '.' + x.contractName + ':' + x.tokenID
                    const nftData = x.data ?? this.props.data[key]
                    if (nftData) {
                      return (
                        <Card
                          badges={this.badges(x)}
                          renderer={renderer(nftData)}
                          collection={nftData.collection || nftData.name}
                          name={nftData.name}
                          rarityRank={nftData.rarityRank}
                          attributes={this.getAttributes(nftData)}
                          link={()=>{localPermalink(x)}}
                          sellFunction={this.sellFunction(x)}
                          sendFunction={this.sendFunction(x)}
                          unlistFunction={this.unlistFunction(x)}/>
                      );
                    } else {
                      const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
                      return (
                        <Card
                          badges={this.badges(x)}
                          renderer={<Renderer />}
                          collection={titled}
                          name={titled + ' #' + x.tokenID} />
                      );
                    }
                  })
              }
              {(this.props.sold && this.props.sold.length > 0) &&
                  this.props.sold.map((x: any ) => {
                    const key = x.contractAddress + '.' + x.contractName + ':' + x.tokenID
                    const nftData = this.props.data[key]
                    if (nftData) {
                      return (
                        <Card
                          badges={this.badges(x)}
                          renderer={renderer(nftData)}
                          collection={nftData.collection || nftData.name}
                          name={nftData.name}
                          rarityRank={nftData.rarityRank}
                          attributes={this.getAttributes(nftData)}
                          link={()=>{localPermalink(x)}}
                          sold={x.listing.price}
                          soldFlag={true}
                          sellFunction={this.sellFunction(x)}
                          sendFunction={this.sendFunction(x)} />
                      );
                    } else {
                      const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
                      return (
                        <Card
                          badges={this.badges(x)}
                          renderer={<Renderer />}
                          collection={titled}
                          name={titled + ' #' + x.tokenID} />
                      );
                    }
                  })
              }
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <ErrorPage />
      )
    }
  }
}

export default ProfileView;

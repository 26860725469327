import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../../../theme';

type BadgeProps = {
  type: 'VERIFIED' | 'ERROR' | 'FEATURED'
}

type BadgeState = {}

class Badge extends Component<BadgeProps, BadgeState> {
  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <div className={styles.Badge}>
        {/* render error */}
        { this.props.type == 'ERROR' &&
          <svg width={"14px"} height={"14px"} viewBox={"0 0 14 14"} version={"1.1"} >
            <g stroke={"none"} strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
              <g transform={"translate(-962.000000, -549.000000)"}>
                <g transform={"translate(721.000000, 542.000000)"}>
                  <g transform={"translate(241.000000, 7.000000)"}>
                    <g fill={"#FF3B30"}>
                      <circle cx={"7"} cy={"7"} r={"7"}></circle>
                    </g>
                    <g transform={"translate(6.500000, 4.000000)"} stroke={"#FFFFFF"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                      <line x1={"0.5"} y1={"0"} x2={"0.5"} y2={"4"}></line>
                      <line x1={"0.495"} y1={"6.5"} x2={"0.505"} y2={"6.5"}></line>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        }

        {/* render featured */}
        { this.props.type == 'FEATURED' &&
          <svg width={"14px"} height={"14px"} viewBox={"0 0 14 14"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
            <g stroke={"none"} strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
              <g transform={"translate(-942.000000, -549.000000)"}>
                <g transform={"translate(721.000000, 542.000000)"}>
                  <g transform={"translate(221.000000, 7.000000)"}>
                    <g fill={"#FFCC00"}>
                      <circle cx={"7"} cy={"7"} r={"7"}></circle>
                    </g>
                    <g transform={"translate(4.000000, 4.000000)"} fill={"#FFFFFF"} stroke={"#FFFFFF"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                      <polygon points={"3 0 3.927 1.97476341 6 2.29337539 4.5 3.829653 4.854 6 3 4.97476341 1.146 6 1.5 3.829653 0 2.29337539 2.073 1.97476341"}></polygon>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        }

        {/* render verified */}
        { this.props.type == 'VERIFIED' &&
          (<svg width={"14px"} className={styles.Verified} height={"14px"} viewBox={"0 0 14 14"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
            <g stroke={"none"} strokeWidth={"1"} fillRule={"evenodd"}>
              <g transform={"translate(-922.000000, -549.000000)"}>
                <g transform={"translate(721.000000, 542.000000)"}>
                  <g transform={"translate(201.000000, 7.000000)"}>
                    <circle cx={"7"} cy={"7"} r={"7"}></circle>
                    <g transform={"translate(4.000000, 5.000000)"} stroke={"#FFFFFF"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                      <polyline points={"6 0 1.875 4 0 2.18181818"}></polyline>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>)
        }
      </div>
    )
  }
}

export default Badge;
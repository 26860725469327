/* global ga */

function gaSend(category: string, action: string, label: string, value?: any) {
    try {
        if (!ga) return;
        let gaInstance = ga.getAll()[0];
        if (gaInstance) {
            gaInstance.send('event', category, action, label, value);
        }
    } catch (e) {
        console.log(e);
    }
}

export {
    gaSend,
}

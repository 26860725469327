import React, { Component } from 'react'
import Trait from '../../components/Card/Back/Trait'
import Renderer from '../../components/Renderer'
import { localPermalink, renderer } from '../../utils'
import styles from './index.module.scss'

import { Link } from 'react-router-dom';
import { darkmodeListener } from '../../theme';
import makeEndpoint from '../../endpoints'

type IndividualViewProps = {
    raw: Array<any>
    data: any
    owner?: any
}

type IndividualViewState = {
}

class IndividualView extends Component<IndividualViewProps, IndividualViewState> {

    componentDidMount() {
        darkmodeListener(styles['theme-light'], styles['theme-dark']);
    }

    getTokenId(nft: any){
        console.log(nft);
        return (
            <h2>{nft ? nft.name : ''}</h2>
        )
        if(nft && nft.tokenID) {
            let tokenId = nft.tokenID;
            if(!tokenId.includes('#')){
                tokenId = "#" + tokenId;
            }
            return (
                <h2>{tokenId}</h2>
            )
        }
    }

    getOwner() {
        const owner: string = this.props.owner;
        if (owner) {
            <h3>Owner</h3>
            if(owner.includes("marketplace-v4") || owner.includes("marketplace-v3")){
                return (
                    <div className={styles.ownerWrapper}>
                        <h3>Owner</h3>
                        <p>NFT temporarily owned by marketplace contract until it is purchased or unlisted.</p>
                    </div>
                )
            } else if(owner.includes("loading")) {
                return (
                    <div className={styles.ownerWrapper}>
                        <h3>Owner</h3>
                        <p>{owner}</p>
                    </div>
                )
            } else {
                return (
                    <div className={styles.ownerWrapper}>
                        <h3>Owner</h3>
                        <div className={styles.ownerLink}><Link to={"/" + owner}>{owner}</Link></div>
                    </div>
                )
            }
        }
        return "";
    }

    getRarityRank(nftData: any){
        console.log("rarity");
        console.log(nftData);
        if (nftData && nftData.rarityRank) {
            const rarityRank = nftData.rarityRank;
            var totalInCollection;
            if (nftData.attributes && nftData.attributes.length) {
                totalInCollection = nftData.attributes[0].total;
            }
            return (
                <div className={styles.rarityRankWrapper}>
                    <h3>Rarity</h3>
                    <Trait
                        type="STXNFT Rarity Rank"
                        collection={rarityRank + (totalInCollection ? '/' + totalInCollection : '')}/>
                </div>
            )
        } else {
            return "";
        }
    }

    getTraits(nftData: any) {
        if(nftData && nftData.attributes && nftData.attributes.length > 0) {
        return(
            <div className={styles.traitsWrapper}>
                <h3>Traits</h3>
                {
                    nftData.attributes.filter((x: any) => ((x.trait_type != undefined || x.type != undefined || x.trait != undefined )&& x.value != undefined)).map((x: any) =>
                        <span>
                        {
                            <Trait
                            type={x.trait_type || x.type || x.trait}
                            rarity={x.percentage}
                            collection={x.value}/>
                        }
                        </span>
                    )
                }
            </div>
        )
        } else {
            return ""
        }
    }

    getDescription(nftData: any) {
        if(nftData && nftData.description) {
            return (
                <div className={styles.descriptionWrapper}>
                    <h3>Description</h3>
                    <p>{nftData.description}
                    </p>
                </div>
            )
        }
    }

    render() {
        var nft = this.props.raw[0];
        var nftData: any = {};
        if(nft){
            const key = nft.contractAddress + '.' + nft.contractName + ':' + nft.tokenID;
            if(key) {
                nftData = this.props.data[key];
            }
        }
        console.log(nft);

        if(nftData && nftData.rarityRank) {
            console.log("rarity rank");
            console.log(nftData.rarityRank);
        }
        
        return (
            <div className={styles.individualNftWrapper}>
                <div className={styles.imgWrapper}>
                    {nftData && renderer(nftData, "largeImage")}
                </div>
                <div className={styles.metaWrapper}>
                    <h1>{nftData && nftData.collection}</h1>
                    <h2>{
                        /* TOKEN ID */
                        this.getTokenId(nftData)
                    }</h2>
                    <div className={styles.details}>
                        {
                            /* OWNER */
                            this.getOwner()
                        }
                        {
                            /* RARITY RANK */
                            this.getRarityRank(nftData)
                        }
                        {
                            /* TRAITS and ATTRIBUTES */
                            this.getTraits(nftData)
                        }
                        {
                            /* Collection Description */
                            this.getDescription(nftData)
                        }
                    </div>
                </div>
           
            </div>
        )
    }
}

export default IndividualView;

import React, { Component } from 'react'
import styles from './index.module.scss'

import Button from '../Button';

import { darkmodeListener } from '../../theme';

type ConfirmProps = {
  success?: boolean
  transactionId?: string
  errorMessage?: string
  onClose: () => void
}

type ConfirmState = {}

class Confirm extends Component<ConfirmProps, ConfirmState> {
  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  renderSuccess() {
    return (
      <svg width={"43px"} height={"43px"} viewBox={"0 0 43 43"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
        <g stroke={"none"} strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
          <g transform={"translate(-167.000000, -1130.000000)"}>
            <g transform={"translate(41.000000, 1094.000000)"}>
              <g transform={"translate(126.000000, 36.000000)"}>
                <circle fill={"#32D74B"} cx={"21.5"} cy={"21.5"} r={"21.5"}></circle>
                <g transform={"translate(13.000000, 16.000000)"} stroke={"#FFFFFF"} strokeLinecap={"round"} strokeLinejoin={"round"} strokeWidth={"3"}>
                  <polyline points={"18 0 5.625 12 0 6.54545455"}></polyline>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }

  renderError() {
    return (
      <svg width={"43px"} height={"43px"} viewBox={"0 0 43 43"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
        <g stroke={"none"} strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
          <g transform={"translate(-496.000000, -1127.000000)"}>
            <g transform={"translate(370.000000, 1094.000000)"}>
              <g transform={"translate(0.000000, 14.000000)"}>
                <g transform={"translate(126.000000, 19.000000)"}>
                  <g fill={"#FF3B30"}>
                    <circle cx={"21.5"} cy={"21.5"} r={"21.5"}></circle>
                  </g>
                  <g transform={"translate(20.000000, 12.000000)"} stroke={"#FFFFFF"} strokeLinecap={"round"} strokeLinejoin={"round"} strokeWidth={"3"}>
                    <line x1={"2"} y1={"0"} x2={"2"} y2={"12.5714286"}></line>
                    <line x1={"1.98"} y1={"20.4285714"} x2={"2.02"} y2={"20.4285714"}></line>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }

  render() {
    return (
      <div className={styles.Confirm}>
        <div className={styles.ConfirmClose} onClick={this.props.onClose.bind(this)}>
          <svg width={"14px"} height={"14px"} viewBox={"0 0 14 14"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
            <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
              <g transform={"translate(-329.000000, -1101.000000)"} strokeWidth={"2"}>
                <g transform={"translate(41.000000, 1094.000000)"}>
                  <g transform={"translate(289.000000, 8.000000)"}>
                    <line x1={"12"} y1={"0"} x2={"0"} y2={"12"}></line>
                    <line x1={"0"} y1={"0"} x2={"12"} y2={"12"}></line>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        {this.props.success &&
          <div className={styles.ConfirmMessageContainer}>
            {this.renderSuccess()}
            <div className={styles.ConfirmMessageText}>
              SUCCESS
            </div>
          </div>
        }
        {!this.props.success &&
          <div className={styles.ConfirmMessageContainer}>
            {this.renderError()}
            <div className={styles.ConfirmMessageText}>
              {this.props.errorMessage}
            </div>
          </div>
        }
        {this.props.success && this.props.transactionId &&
          <div className={styles.ConfirmContainerButton}>
            <Button
              text={"View Transaction"}
              onClick={() => { window.location.href = "https://explorer.stacks.co/txid/" + this.props.transactionId + "?chain=mainnet" }}/>
          </div>
        }
        {!this.props.success && 
          <div className={styles.ConfirmContainerButton}>
            <Button
              text={"CLOSE"}
              onClick={this.props.onClose.bind(this)}/>
          </div>
        }
      </div>
    )
  }
}

export default Confirm
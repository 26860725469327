import Renderer from './components/Renderer'

const convertIPFS = (url: string) => {
  if (url && url.startsWith('ipfs://ipfs/')) {
    const uri = url.slice(7);
    const cloudflareUrl = 'https://stxnft.mypinata.cloud/' + uri;
    return cloudflareUrl;
  } else if (url && url.startsWith('ipfs://')) {
    const uri = url.slice(7);
    const cloudflareUrl = 'https://stxnft.mypinata.cloud/ipfs/' + uri;
    return cloudflareUrl;
  } else if (url && url.startsWith('ar://')) {
    const uri = url.slice(5);
    const arweaveUrl = 'https://arweave.net/' + uri;
    return arweaveUrl;
  } else {
    url = url.replace('cloudflare-ipfs.com', 'stxnft.mypinata.cloud');
    url = url.replace('ipfs.io', 'stxnft.mypinata.cloud');
    return url;
  }
}

const renderer = (nft: any, imgClass?: string) => {
  if(nft.video && nft.video.src && nft.video.type && (nft.image || nft.imageUrl)){
    return (
      <Renderer video={nft.video.src} src={nft.image || nft.imageUrl} type={nft.video.type} />
    )
  } else if (nft.video) {
    return (
      <Renderer video={nft.video} src={nft.image || nft.imageUrl} />
    )
  } else if (nft.image || nft.imageUrl) {
    let imgClass2 = (nft.collection && (nft.collection.indexOf('Punks') !== -1 || nft.collection.indexOf('Bitgear') !== -1))? 'pixelated': ''
    if(imgClass){
      return (
        <Renderer src={nft.image || nft.imageUrl} imageClasses={[imgClass, imgClass2].join(' ')}/>
      )
    } else {
      return (
        <Renderer src={nft.image || nft.imageUrl} imageClasses={imgClass2}/>
      )
    }
  } else {
    return (
      <Renderer text={nft.title ?? nft.name}/>
    )
  }
}

const renderPrice = (price: number, currency?: string) => {
  if (currency == 'BTC') {
    return price.toFixed(5);
  }
  return parseFloat((price / 1e6).toFixed(2)).toLocaleString('us')
}

const localPermalink = (x: any) => {
  window.location.href = '/nft/' + x.contractAddress + '.' + x.contractName + ':' + x.tokenID;
}

export { convertIPFS, renderPrice, renderer, localPermalink };

import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

import makeEndpoint from '../../endpoints'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Loading from '../../components/Loading'
import Renderer from '../../components/Renderer'

import icon from '../../components/Login/assets/icon.svg'

import {
  AppConfig,
  UserSession,
  showConnect,
  openContractCall
} from '@stacks/connect';

import {
  uintCV,
  intCV,
  bufferCV,
  stringAsciiCV,
  stringUtf8CV,
  standardPrincipalCV,
  contractPrincipalCV,
  trueCV,
  makeStandardFungiblePostCondition,
  FungibleConditionCode,
  createAssetInfo,
  makeStandardNonFungiblePostCondition,
  NonFungibleConditionCode,
  makeStandardSTXPostCondition,
  makeContractSTXPostCondition,
} from '@stacks/transactions';

import { StacksTestnet, StacksMainnet } from '@stacks/network';

import BigNum from 'bn.js'

import InfiniteScroll from 'react-infinite-scroll-component';

import { renderer } from '../../utils'

declare const lnswap: any;

const testnet = new StacksTestnet();
const mainnet = new StacksMainnet();

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

type RootsProps = {
  name: string
}

type RootsState = {
  name: string
  contractAddress: string
  contractName: string
  description: string
  image: string
  total: number
  lastTokenID: number
  prices: number
  currency?: string
  saleData: Record<string, any>
  sale: Array<any>
  active: boolean
  paused: boolean
  userData?: any
  pairs?: any
  offset: number
  currencySelected: string
  numberSelected: number
  mempool?: number
}

const fetchSize = 25;
class Roots extends Component<RootsProps, RootsState> {
  state = {
    name: "",
    contractAddress: "",
    contractName: "",
    description: "",
    image: "",
    total: 0,
    saleData: {},
    sale: [],
    lastTokenID: 0,
    prices: 0,
    currency: undefined,
    active: false,
    paused: false,
    userData: undefined,
    offset: 1,
    pairs: undefined,
    numberSelected: 1,
    currencySelected: 'BTC',
    mempool: undefined,
  }

  componentDidMount() {
    try {
      const userData = localStorage.getItem('userData');
      if (userData) {
        this.setState({
          'userData': JSON.parse(userData)
        })
      }
    } catch {}

    fetch('https://api.lnswap.org:9002/getpairs')
      .then(res => {
        console.log(res);
        console.log('response received');
        return res.json();
      })
      .then(pairs => {
        console.log('parsed JSON');
        console.log(pairs);
        this.setState({ pairs });
      });

    darkmodeListener(styles['theme-light'], styles['theme-dark']);
    fetch(makeEndpoint('/api/drop?name=' + this.props.name))
      .then(res => {
          return res.json()
      })
      .then((artistData) => {
        console.log(artistData)
        this.setState({
          name: artistData.name,
          contractAddress: artistData.contractAddress,
          contractName: artistData.contractName,
          description: artistData.description,
          image: artistData.image,
          total: artistData.total,
          prices: artistData.prices,
          active: artistData.active,
          paused: artistData.paused,
          currency: artistData.currency,
        }, () => {
          const alreadyClaimed = localStorage.getItem('claimed-' + artistData.contractName);
          if (alreadyClaimed && artistData.contractName.includes('305')) {
            this.setState({
              paused: true
            })
          }
          fetch(makeEndpoint('/api/nft/lastTokenID?reload=true&address='+artistData.contractAddress+'&name=' + artistData.contractName))
          fetch(makeEndpoint('/api/nft/lastTokenID?address=' + artistData.contractAddress + '&name=' + artistData.contractName))
          .then(res => { return res.text() })
          .then((lastTokenStr) => {
            const lastToken = parseInt(lastTokenStr, 10)
            this.setState({
              lastTokenID: lastToken,
            }, () => {
              this.fetchNFTs()
            })
          })
          fetch('https://stacks-node-api.mainnet.stacks.co/extended/v1/tx/mempool?sender_address=SP13R6D5P5TYE71D81GZQWSD9PGQMQQN54A2YT3BY')
            .then(res => {
                return res.json()
            })
            .then(mempool => {
                this.setState({ mempool: mempool.total });
            })
        })
    });
  }

  fetchNFTs() {
    var nfts: Array<any> = []
    const lastToken = this.state.lastTokenID;
    var numNFTs = Math.min(lastToken, fetchSize)
    if (lastToken < (this.state.offset + numNFTs)) {
      const diff = (this.state.offset + numNFTs) - lastToken
      numNFTs -= diff;
    }

    for (var x = this.state.offset; x <= this.state.offset + numNFTs; x++) {
      nfts.push({
        "contractAddress": this.state.contractAddress,
        "tokenID": x,
        "contractName": this.state.contractName,
        "sold": true
      });
    }

    var sale: Array<any>  = this.state.sale;
    const filtered_nfts = nfts.filter((r: any) => {
        let filtered_list = sale.filter((l: any) => l.contractAddress == r.contractAddress && l.contractName == r.contractName && String(l.tokenID) == String(r.tokenID));
        return filtered_list.length == 0
    })

    sale.push(...filtered_nfts)
    this.setState({
      'sale': sale
    }, () => {
      this.fetchData(nfts)
    })
  }

  callLnswap = () => {
    lnswap('swap', 'mintnft', this.stxAddress(), 25, 'SP2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG4V39WPY.stacks-roots-v2', 'claim-for'); // @ts-ignore
  }

  getLength() {
    return this.state.sale.length
  }

  fetchData(nfts: any) {
    for (var nft of nfts) {
      var params = '';
      params += 'address=' + nft.contractAddress;
      params += '&name=' + nft.contractName;
      params += '&id=' + nft.tokenID;
      const nftId = nft.contractAddress + '.' + nft.contractName + ':' + nft.tokenID;
      var saleDataRecord: any = this.state.saleData
      if (saleDataRecord[nftId]) {
          continue;
      }
      fetch(makeEndpoint('/api/nft?' + params))
        .then(res => res.json())
        .then((nftData) => {
          if (nftData.name) {
            var saleData = saleDataRecord;
            saleData[nftId] = nftData;
            this.setState({ saleData });
          }
        })
    }
  }

  getTotal() {
    return this.state.total;
  }

  getAvailable() {
    return this.getTotal() - this.state.lastTokenID;
  }

  getVolumeSold() {
    return this.state.lastTokenID * this.amount();
  }

  getFloorPrice() {
    if (!this.state.currency) {
      return (this.state.prices / 1e6);
    } else {
      return this.state.prices;
    }
  }

  nFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  renderCard() {
    return this.state.sale.map((x: any) => {
      const saleData: any = this.state.saleData
      const fullData: any = saleData[x.contractAddress + '.' + x.contractName + ':' + x.tokenID]
      if (fullData) {
        return (
          <Card
            renderer={renderer(fullData)}
            collection={fullData.collection || fullData.name}
            name={fullData.name}
            currency={x.tokenID <= 50 ? 'STX' : 'BTC'}
            sold={x.tokenID <= 50 ? 25000000 : this.amount()}/>
        )
      }  else if (x.contractName) {
          const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
          return (
              <Card
                collection={titled}
                renderer={<Renderer />}
                currency={this.state.currency}
                name={titled + ' #' + x.tokenID}/>
          );
      }
    })
  }

  amount(): number {
    if (!this.state.pairs) {
      return 1
    }
    return 25 * 1.02 / this.state.pairs['pairs']['BTC/STX']['rate'];
  }

  fiveAmount() {
    return this.state.prices * 5
  }

  tenAmount() {
    return this.state.prices * 10
  }

  mintText() {
    let mintText;
    if (this.state.pairs == undefined || this.state.mempool == undefined) {
      mintText = 'LOADING...';
    } else if (this.state.active && this.state.paused || (this.state.mempool != undefined && this.state.lastTokenID + this.state.mempool >= this.state.total)) {
      mintText = 'MINTING CLOSED';
    } else if (!this.state.active) {
      mintText = 'COMING SOON';
    } else if (this.state.lastTokenID >= this.state.total) {
      mintText = "VISIT MARKETPLACE";
    } else if (this.state.userData) {
      mintText = "MINT " + this.state.numberSelected;
    } else {
      mintText = "CONNECT WALLET TO MINT"
    }
    return mintText
  }

  renderPrice() {
    return (!((this.state.active && this.state.paused) || (!this.state.active) || (this.state.lastTokenID >= this.state.total))) && (this.state.userData) && this.state.pairs != undefined && this.state.mempool != undefined;
  }

  stxAddress() {
    if (this.state.userData) {
      const userData: any = this.state.userData
      return userData.profile.stxAddress.mainnet;
    } else {
      return null;
    }
  }

  authenticate() {
        showConnect({
            appDetails: {
                'name': 'Stacks NFT',
                'icon': icon,
            },
            redirectTo: '/',
            onFinish: () => {
                let userData = userSession.loadUserData();
                this.setState({ userData: userData });
                localStorage.setItem('userData', JSON.stringify(userData));
                window.location.reload();
            },
            userSession: userSession,
        });
    }

  openClaim(contractAddress: string, contractName: string, salePrice: number, tokenID: number) {
      if (this.state.userData) {
//        this.claim(contractAddress, contractName, salePrice, tokenID)
      } else {
          this.authenticate()
      }
  }

  mintButton() {
    if (this.amount() >= 0 && this.renderPrice()) {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            price={this.amount()}
            currency={this.state.currencySelected}
            onClick={this.callLnswap}/>
        </div>
      )
    } else {
      return (
        <div className={styles.MintButton}>
          <Button text={this.mintText()} />
        </div>
      )
    }
  }

  nextBlocks(offset: number) {
    this.setState({
      'offset': offset
    }, () => {
      this.fetchNFTs()
    })
  }

  getBlocksNext() {
    this.nextBlocks(this.getLength())
  }

  hasMore() {
    return this.state.lastTokenID > this.state.offset
  }

  render() {
    return (
      <div className={styles.Collections}>
        <div className={styles.MintTopBar}>
          <div className={styles.MintImageBorder}>
            <div className={styles.MintImage}>
              <img
                src={this.state.image}
                height={122}
                width={122}
                className={styles.MintImageSrc}/>
            </div>
          </div>
        </div>
        <div className={styles.MintContent}>
          <div className={styles.MintTitle}>
            {this.state.name}
          </div>
          <div className={styles.MintSummary} dangerouslySetInnerHTML={{__html: this.state.description}}>
          </div>
          {this.mintButton()}
          <div className={styles.MintPrice}>
            <div className={styles.MintPriceContainer}>
              <div>
                { this.getTotal().toLocaleString('us') }
              </div>
              <div className={styles.MintPriceTitle}>
                TOTAL
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div>
                { this.getAvailable().toLocaleString('us') }
              </div>
              <div className={styles.MintPriceTitle}>
                REMAINING
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div className={styles.MintPriceIconContainer}>
                <div>
                  { this.state.pairs && this.amount().toFixed(5) }
                </div>
                <div className={styles.MintPriceText}>
                  BTC
                </div>
                {this.state.currency == 'MIA' &&
                  <div className={styles.MintPriceText}>
                    MIA
                  </div>
                }
                {this.state.currency == 'NYC' &&
                  <div className={styles.MintPriceText}>
                    NYC
                  </div>
                }
              </div>
              <div className={styles.MintPriceTitle}>
                MINT PRICE
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div className={styles.MintPriceVolumeContainer}>
                { this.state.pairs && this.getVolumeSold().toFixed(5) }
                  <div className={styles.MintPriceText}>
                    BTC
                  </div>
                {this.state.currency == 'MIA' &&
                  <div className={styles.MintPriceText}>
                    MIA
                  </div>
                }
              </div>
              <div className={styles.MintPriceTitle}>
                VOLUME SOLD
              </div>
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={this.getLength()}
          next={this.getBlocksNext.bind(this)}
          hasMore={this.hasMore()}
          loader={<Loading />}
          className={styles.MintPriceCardContainer}
          scrollableTarget={"page-wrapper"}>
          {this.renderCard()}
        </InfiniteScroll>
      </div>
    )
  }
}

export default Roots

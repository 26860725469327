import React, { Component } from 'react'
import { Helmet } from 'react-helmet' 
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';
import { gaSend } from '../../Analytics';

import makeEndpoint from '../../endpoints'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Loading from '../../components/Loading'
import Renderer from '../../components/Renderer'

import icon from '../../components/Login/assets/icon.svg'

import {
  AppConfig,
  UserSession,
  showConnect,
  openContractCall
} from '@stacks/connect';

import {
  uintCV,
  intCV,
  bufferCV,
  stringAsciiCV,
  stringUtf8CV,
  standardPrincipalCV,
  trueCV,
  makeStandardFungiblePostCondition,
  FungibleConditionCode,
  createAssetInfo,
  makeStandardNonFungiblePostCondition,
  NonFungibleConditionCode,
  makeStandardSTXPostCondition
} from '@stacks/transactions';

import { StacksTestnet, StacksMainnet } from '@stacks/network';

import BigNum from 'bn.js'

import InfiniteScroll from 'react-infinite-scroll-component';

import { renderer } from '../../utils'

const testnet = new StacksTestnet();
const mainnet = new StacksMainnet();

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

type CollectionsProps = {
  name: string
}

type CollectionsState = {
  name: string
  contractAddress: string
  contractName: string
  description: string
  image: string
  total: number
  lastTokenID: number
  prices: number
  currency?: string
  saleData: Record<string, any>
  sale: Array<any>
  active: boolean
  paused: boolean
  userData?: any
  offset: number
}

const fetchSize = 25;
class Collections extends Component<CollectionsProps, CollectionsState> {
  state = {
    name: "",
    contractAddress: "",
    contractName: "",
    description: "",
    image: "",
    total: 0,
    saleData: {},
    sale: [],
    lastTokenID: 0,
    prices: 0,
    currency: undefined,
    active: false,
    paused: false,
    userData: undefined,
    offset: 1
  }

  componentDidMount() {
    try {
      const userData = localStorage.getItem('userData');
      if (userData) {
        this.setState({
          'userData': JSON.parse(userData)
        })
      }
    } catch {}

    darkmodeListener(styles['theme-light'], styles['theme-dark'])
    fetch(makeEndpoint('/api/drop?name=' + this.props.name))
      .then(res => {
          return res.json()
      })
      .then((artistData) => {
        console.log(artistData)
        this.setState({
          name: artistData.name,
          contractAddress: artistData.contractAddress,
          contractName: artistData.contractName,
          description: artistData.description,
          image: artistData.image,
          total: artistData.total,
          prices: artistData.prices,
          active: artistData.active,
          paused: artistData.paused,
          currency: artistData.currency,
        }, () => {
          var contractName = artistData.contractName.replace('panda-mint', 'panda-nft');
          contractName = contractName.replace('-mint', '');
          fetch(makeEndpoint('/api/nft/lastTokenID?address='+artistData.contractAddress+'&name='+contractName+'&reload=true'))
          fetch(makeEndpoint('/api/nft/lastTokenID?address='+artistData.contractAddress+'&name='+contractName))
          .then(res => { return res.text() })
          .then((lastTokenStr) => {
            if (lastTokenStr === 'null') {
               return;
            }
            const lastToken = parseInt(lastTokenStr, 10)
            this.setState({
              lastTokenID: lastToken,
            }, () => {
              this.fetchNFTs()
            })
          })
        })
    });
  }

  fetchNFTs() {
    if (this.state.contractName.includes('panda-mint')) {
      return;
    }
    var nfts: Array<any> = []
    var numNFTs = Math.min(this.state.lastTokenID, fetchSize)
    if (this.state.lastTokenID < (this.state.offset + numNFTs)) {
      const diff = (this.state.offset + numNFTs) - this.state.lastTokenID
      numNFTs -= diff;
    }

    var contractName = this.state.contractName.replace('panda-mint', 'panda-nft');
    contractName = contractName.replace('-mint', '');
    for (var x = this.state.offset; x <= this.state.offset + numNFTs; x++) {
      nfts.push({
        "contractAddress": this.state.contractAddress,
        "tokenID": x,
        "contractName": contractName,
        "sold": true
      });
    }

    var sale: Array<any>  = this.state.sale;
    const filtered_nfts = nfts.filter((r: any) => {
        let filtered_list = sale.filter((l: any) => l.contractAddress == r.contractAddress && l.contractName == r.contractName && String(l.tokenID) == String(r.tokenID));
        return filtered_list.length == 0
    })

    sale.push(...filtered_nfts)
    this.setState({
      'sale': sale
    }, () => {
      this.fetchData(nfts)
    })
  }


  getLength() {
    return this.state.sale.length
  }

  fetchData(nfts: any) {
    for (var nft of nfts) {
      var contractName = nft.contractName.replace('panda-mint', 'panda-nft');
      contractName = contractName.replace('-mint', '');
      var params = '';
      params += 'address=' + nft.contractAddress;
      params += '&name=' + contractName;
      params += '&id=' + nft.tokenID;
      const nftId = nft.contractAddress + '.' + contractName + ':' + nft.tokenID;
      var saleDataRecord: any = this.state.saleData
      if (saleDataRecord[nftId]) {
          continue;
      }
      fetch(makeEndpoint('/api/nft?' + params))
        .then(res => res.json())
        .then((nftData) => {
          if (nftData.name) {
            var saleData = saleDataRecord;
            saleData[nftId] = nftData;
            this.setState({ saleData });
          }
        })
    }
  }

  getTotal() {
    return this.state.total;
  }

  getAvailable() {
    return this.getTotal() - this.state.lastTokenID;
  }

  getVolumeSold() {
    return this.state.lastTokenID * this.getFloorPrice();
  }

  getFloorPrice() {
    if (!this.state.currency) {
      return (this.state.prices / 1e6);
    } else {
      return this.state.prices;
    }
  }

  nFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  renderCard() {
    return this.state.sale.map((x: any) => {
      const saleData: any = this.state.saleData
      const fullData: any = saleData[x.contractAddress + '.' + x.contractName + ':' + x.tokenID]
      if (fullData) {
        return (
          <Card
            renderer={renderer(fullData)}
            collection={fullData.collection || fullData.name}
            name={fullData.name}
            currency={this.state.currency}
            sold={this.state.prices}/>
        )
      }  else if (x.contractName) {
          const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
          return (
              <Card
                collection={titled}
                renderer={<Renderer />}
                currency={this.state.currency}
                name={titled + ' #' + x.tokenID}/>
          );
      }
    })
  }


  amount() {
    return this.state.prices
  }

  fiveAmount() {
    return this.state.prices * 5
  }

  tenAmount() {
    return this.state.prices * 10
  }

  mintText() {
    let mintText;
    if (this.state.active && this.state.paused) {
      mintText = 'MINTING CLOSED';
    } else if (!this.state.active) {
      mintText = 'COMING SOON';
    } else if (this.state.lastTokenID >= this.state.total) {
      mintText = "VISIT MARKETPLACE";
    } else if (this.state.userData) {
      mintText = "MINT"
    } else {
      mintText = "CONNECT WALLET TO MINT"
    }
    return mintText
  }

  renderPrice() {
    return (!((this.state.active && this.state.paused) || (!this.state.active) || (this.state.lastTokenID >= this.state.total))) && (this.state.userData)
  }

  stxAddress() {
    if (this.state.userData) {
      const userData: any = this.state.userData
      return userData.profile.stxAddress.mainnet;
    } else {
      return null;
    }
  }

  authenticate() {
        showConnect({
            appDetails: {
                'name': 'Stacks NFT',
                'icon': icon,
            },
            redirectTo: '/',
            onFinish: () => {
                gaSend('User', 'authenticate', 'User Authenticated');
                let userData = userSession.loadUserData();
                this.setState({ userData: userData });
                localStorage.setItem('userData', JSON.stringify(userData));
                window.location.reload();
            },
            userSession: userSession,
        });
    }

  claim(contractAddress: string, contractName: string, salePrice: number, claimNumber: number) {
        const functionArgs: any[] = [];

        let functionName;
        if (claimNumber == 10) {
            functionName = 'claim-ten';
        } else if (claimNumber == 5) {
            functionName = 'claim-five';
        } else {
            functionName = 'claim';
        }

        let standardFungiblePostCondition;
        if (!this.state.currency) {
            standardFungiblePostCondition = makeStandardSTXPostCondition(
                this.stxAddress(),
                FungibleConditionCode.LessEqual,
                new BigNum(salePrice * (claimNumber ?? 1))
            );
        } else if (this.state.currency == 'MIA') {
            const fungibleAssetInfo = createAssetInfo('SP466FNC0P7JWTNM2R9T199QRZN1MYEDTAR0KP27', 'miamicoin-token', 'miamicoin');

            standardFungiblePostCondition = makeStandardFungiblePostCondition(
                this.stxAddress(),
                FungibleConditionCode.LessEqual,
                salePrice,
                fungibleAssetInfo
            );
        }

        const options: any = {
            contractAddress: contractAddress,
            contractName: contractName,
            functionName: functionName,
            network: mainnet,
            functionArgs,
            postConditions: [standardFungiblePostCondition],
            appDetails: {
                'name': 'Stacks NFT',
                'icon': icon,
            },
            onFinish: (data: any) => {
                // this.setState({ 
                //     success: true,
                //     successTransaction: data.txId,
                // });
            },
            onCancel: () => {
                // this.setState({ 
                //     success: false,
                //     errorMessage: "Transaction Failed"
                // });
            }
        };

        openContractCall(options);
    }

  openClaim(contractAddress: string, contractName: string, salePrice: number, tokenID: number) {
      if (this.state.userData) {
          this.claim(contractAddress, contractName, salePrice, tokenID)
      } else {
          this.authenticate()
      }
  }

  mintingBehavior(claimNumber: number) {
        if (this.state.contractAddress && this.state.contractName) {
            if (!this.state.active || this.state.paused) {
                // no op
            } else if (this.state.lastTokenID >= this.state.total) {
                window.location.href = '/marketplace/' + this.state.contractName
            } else {
                this.openClaim(this.state.contractAddress, this.state.contractName, this.state.prices, claimNumber)
            }
        }
    }

  mintButton() {
    if (this.amount() >= 0 && this.renderPrice()) {
      return (
        <Button
          text={this.mintText()}
          price={this.amount()}
          currency={this.state.currency}
          onClick={this.mintingBehavior.bind(this, 1)}/>
      )
    } else {
      return (
        <Button text={this.mintText()}
                onClick={this.mintingBehavior.bind(this, 1)}/>
      )
    }
  }

  nextBlocks(offset: number) {
    this.setState({
      'offset': offset
    }, () => {
      this.fetchNFTs()
    })
  }

  getBlocksNext() {
    this.nextBlocks(this.getLength())
  }

  hasMore() {
    return this.state.lastTokenID > this.state.offset
  }

  render() {
    return (
      <div className={styles.Collections}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.name ?? 'Collection'} | STXNFT</title>
          <meta name="description" content={this.state.description.slice(0, 160) ?? 'The best place to find, collect, and sell NFTs secured by Bitcoin. STXNFT is the first open marketplace for Stacks NFTs, with the lowest commission anywhere.'} />
        </Helmet>
        <div className={styles.MintTopBar}>
          <div className={styles.MintImageBorder}>
            <div className={styles.MintImage}>
              <img
                src={this.state.image}
                height={122}
                width={122}
                className={styles.MintImageSrc}/>
            </div>
          </div>
        </div>
        <div className={styles.MintContent}>
          <div className={styles.MintTitle}>
            {this.state.name}
          </div>
          <div className={styles.MintSummary} dangerouslySetInnerHTML={{__html: this.state.description}}>
          </div>
          <div className={styles.MintButton}>
            {this.mintButton()}
          </div>
          <div className={styles.MintPrice}>
            <div className={styles.MintPriceContainer}>
              <div>
                { this.getTotal().toLocaleString('us') }
              </div>
              <div className={styles.MintPriceTitle}>
                TOTAL
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div>
                { this.getAvailable().toLocaleString('us') }
              </div>
              <div className={styles.MintPriceTitle}>
                REMAINING
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div className={styles.MintPriceIconContainer}>
                {!this.state.currency &&
                  <div className={styles.MintPriceIcon}>
                    <svg width={"15px"} height={"18px"} viewBox={"0 0 15 18"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
                      <g stroke={"none"} stroke-width={"1"} fill={"none"} fill-rule={"evenodd"}>
                        <g transform={"translate(-889.000000, -497.000000)"} stroke={"#707071"}>
                          <g transform={"translate(528.000000, 492.000000)"}>
                            <g transform={"translate(361.000000, 6.000000)"}>
                              <g>
                                <line x1={"3.84615385"} y1={"6.58823529"} x2={"13.0769231"} y2={"6.58823529"}></line>
                                <line x1={"-9.60769925e-17"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"1.53846154"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"13.0769231"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"14.6153846"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"2.30769231"} y1={"0.411764706"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"}></line>
                                <line x1={"8.46153846"} y1={"0.411764706"} x2={"12.3076923"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"} transform={"translate(10.384615, 3.294118) scale(-1, 1) translate(-10.384615, -3.294118) "}></line>
                              </g>
                              <g transform={"translate(7.500000, 12.500000) scale(1, -1) translate(-7.500000, -12.500000) translate(0.000000, 9.000000)"}>
                                <line x1={"3.84615385"} y1={"6.58823529"} x2={"13.0769231"} y2={"6.58823529"}></line>
                                <line x1={"-9.60769925e-17"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"1.53846154"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"13.0769231"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"14.6153846"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"2.30769231"} y1={"0.411764706"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"}></line>
                                <line x1={"8.46153846"} y1={"0.411764706"} x2={"12.3076923"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"} transform={"translate(10.384615, 3.294118) scale(-1, 1) translate(-10.384615, -3.294118) "}></line>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                }
                <div>
                  { this.getFloorPrice().toLocaleString('us') }
                </div>
                {this.state.currency == 'MIA' &&
                  <div className={styles.MintPriceText}>
                    MIA
                  </div>
                }
              </div>
              <div className={styles.MintPriceTitle}>
                MINT PRICE
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div className={styles.MintPriceVolumeContainer}>
                { this.nFormatter(this.getVolumeSold(), 3) }
                {this.state.currency == 'MIA' &&
                  <div className={styles.MintPriceText}>
                    MIA
                  </div>
                }
              </div>
              <div className={styles.MintPriceTitle}>
                VOLUME SOLD
              </div>
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={this.getLength()}
          next={this.getBlocksNext.bind(this)}
          hasMore={this.hasMore()}
          loader={<Loading />}
          className={styles.MintPriceCardContainer}
          scrollableTarget={"page-wrapper"}>
          {this.renderCard()}
        </InfiniteScroll>
      </div>
    )
  }
}

export default Collections

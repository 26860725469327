import React, { Component } from 'react'
import styles from './index.module.scss'

import Button from '../Button';
import CollectionElement from '../Collections/CollectionElement';
import MenuItem from '../MenuItem';

import { convertIPFS } from '../../utils'

import { darkmodeListener } from '../../theme';

type CollectionData = {
  id: string,
  text: string
  image: string
  verified: boolean
}

type MobileSelectorProps = {
  collections: Array<CollectionData>
  collection: any
  setActive:(x?: any) => void
  setSelected: (selected: string) => void
}
type MobileSelectorState = {
  renderCollections: boolean
  renderFilter: boolean
  items: Array<string>
}

class MobileSelector extends Component<MobileSelectorProps, MobileSelectorState> {
  state = {
    renderCollections: false,
    renderFilter: false,
    items: [
      "RECENTLY LISTED",
      "RECENTLY SOLD",
      "PRICE: LOW TO HIGH",
      "PRICE: HIGH TO LOW",
      "OLDEST",
      "RARITY: LOW TO HIGH",
      "RARITY: HIGH TO LOW",
    ]
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  renderCollections() {
    return (
      <div className={styles.CollectionModal}>
        <CollectionElement
          text={'ALL'}
          image={undefined}
          onClick={() => { this.props.setActive(undefined) }}
          active={this.props.collection == undefined}/>
        {this.props.collections.filter((x) => x && x.text).sort((a: any, b: any) => a.text.localeCompare(b.text)).map((x: any) =>
          <CollectionElement
            text={x.text}
            image={x.image}
            verified={x.verified}
            onClick={() => { this.props.setActive(x) }}
            active={(this.props.collection)?(this.props.collection.contractAddress + '.' + this.props.collection.contractName == x.contractAddress + '.' + x.contractName):false}/>
        )}
      </div>
    )
  }

  selectFilter(x: any) {
    this.setState({
      renderCollections: false,
      renderFilter: false
    }, () => {
      this.props.setSelected(x)
    })
  }

  renderFilter() {
    return (
      <div className={styles.FilterModal}>
        {this.state.items.map((x: any) =>
          <div
            className={styles.FilterModalContainer}
            onClick={this.selectFilter.bind(this, x)}>
            {x}
          </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div className={styles.ModalContainer}>
        {this.state.renderCollections && this.renderCollections()}
        {this.state.renderFilter && this.renderFilter()}
        <div className={styles.ModalContainerCollection} onClick={() => { this.setState({'renderCollections': true})}}>
          <div className={styles.ModalContainerIcon}>
          {this.props.collection.image &&
            <img className={styles.ModalContainerIconSrc} src={convertIPFS(this.props.collection.image)} />
          }
          </div>
          <div className={styles.ModalContainerText}>
            {this.props.collection.text}
          </div>
        </div>
        <MenuItem
          type={"filter"}
          onClick={() => { this.setState({'renderFilter': true})}}/>
      </div>
    )
  }
}

export default MobileSelector;

import React, { Component } from 'react'
import styles from './index.module.scss'

import Button from '../Button';

import { darkmodeListener } from '../../theme';

type ModalProps = {
  onClose?: (...args: any[]) => void
}
type ModalState = {}

class Modal extends Component<ModalProps, ModalState> {
  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  onClose(e: React.MouseEvent<HTMLElement>) {
    const element: any = e.target
    if(element && element.className && element.className.includes && element.className.includes("ModalContainer") && this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <div
        className={styles.ModalContainer}
        onClick={this.onClose.bind(this)}>
        {this.props.children}
      </div>
    )
  }
}

export default Modal;

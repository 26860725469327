import React, { Component } from 'react'
import styles from './index.module.scss'

import Filters from '../../components/Filters'
import Status from '../../components/Status'
import Collections from '../../components/Collections'
import Search from '../../components/Search'

import Card from '../../components/Card'
import Dropdown from '../../components/Dropdown'

import makeEndpoint from '../../endpoints'

import ProfileView from '../../views/ProfileView'

type ProfileProps = {
  user: string
}

type ProfileState = {
  open: boolean
  status: boolean
  collection: boolean
  activeCollection?: string
  collections: any
  statusSelected: Array<string>
  statusPills: Array<string>
  filter: string
  order?: 'oldest' | 'less' | 'greater'
  filters?: 'recently' | 'sold' | 'oldest' | 'low' | 'high',
  listings: Array<any>
  raw: Array<any>
  sold: Array<any>
  data: Record<string, any>
}

class Profile extends Component<ProfileProps, ProfileState> {
  state = {
    open: false,
    status: false,
    collection: false,
    activeCollection: '1',
    statusSelected: [],
    filter: '',
    raw: [],
    listings: [],
    sold: [],
    data: {},
    statusPills: ["for sale", "featured", "new"],
    collections: [
      {
        id: '1',
        text: 'all',
        image: '',
        verified: true,
      },
      {
        id: '2',
        text: 'stacks art',
        image: '',
        verified: true,
      },
      {
        id: '3',
        text: 'stacks dragons',
        image: '',
        verified: true,
      }
    ]
  }

  componentDidMount() {
    try {
      // console.log(localStorage.getItem('userData'));
    } catch {}

    this.fetchUserNFTs()
  }

  fetchData(nfts: any) {
    for (var nft of nfts) {
      var params = '';
      params += 'address=' + nft.contractAddress;
      params += '&name=' + nft.contractName;
      params += '&id=' + nft.tokenID;
      const nftId = nft.contractAddress + '.' + nft.contractName + ':' + nft.tokenID;
      fetch(makeEndpoint('/api/nft?' + params))
        .then(res => res.json())
        .then((data) => {
          if (data.name) {
            const fullData: Record<string, any> = this.state.data;
            fullData[nftId] = data;
            this.setState({ 'data': fullData });
          }
        })
    }
  }

  fetchUserNFTs() {
    fetch(makeEndpoint('/api/balance?user=' + this.props.user))
      .then(res => res.json())
      .then((nfts) => {
          if (nfts.length && !nfts.message) {
            const list: Array<any> = this.state.listings;
            const filtered_raw = nfts.filter((r: any) => {
              let filtered_list = list.filter((l: any) => l.contractAddress == r.contractAddress && l.contractName == r.contractName && String(l.tokenID) == String(r.tokenID));
              return filtered_list.length == 0
            });

            this.setState({
              'raw': nfts
            })
            this.fetchData(nfts);
          }
      });

    fetch(makeEndpoint('/api/balance?reload=true&user=' + this.props.user))
      .then(res => res.json())
      .then((nfts) => {
        const list: Array<any> = this.state.listings;
        const filtered_raw = nfts.filter((r: any) => {
          let filtered_list = list.filter((l: any) => l.contractAddress == r.contractAddress && l.contractName == r.contractName && String(l.tokenID) == String(r.tokenID));
          return filtered_list.length == 0
        });

        if (nfts.length && !nfts.message) {
          this.setState({
            'raw': nfts
          })
          this.fetchData(nfts);
        }
      });

    fetch(makeEndpoint('/api/v1/marketplace?user=' + this.props.user))
      .then(res => res.json())
      .then((listings) => {
        if (listings.length && !listings.message) {
          const raw: Array<any> = this.state.raw;
          const list: Array<any> = this.state.listings;
          list.push(...listings);

          const filtered_raw = raw.filter((r: any) => {
            let filtered_list = list.filter((l: any) => l.contractAddress == r.contractAddress && l.contractName == r.contractName && String(l.tokenID) == String(r.tokenID));
            return filtered_list.length == 0
          });

          this.setState({
            'raw': filtered_raw,
            'listings': list
          })
          this.fetchData(list);
        }
      });

    fetch(makeEndpoint('/api/v1/marketplace?sold=true&user=' + this.props.user))
        .then(res => res.json())
        .then((soldEvents) => {
          if (soldEvents.length && !soldEvents.message) {
            this.setState({
              sold: soldEvents
            });
            this.fetchData(soldEvents);
          }
        });
  }

  openCarret() {
    this.setState({
      open: !this.state.open
    });
  }

  statusOpen() {
    this.setState({
      status: !this.state.status
    });
  }

  collectionOpen() {
    this.setState({
      collection: !this.state.collection
    });
  }

  setActive(index?: string) {
    this.setState({
      activeCollection: index
    })
  }

  setStatusSelected(selected: Array<string>) {
    this.setState({
      statusSelected: selected
    })
  }

  setFilter(filter: string) {
    this.setState({
      filter: filter
    })
  }

  resetList() {

  }

  resetSold() {
    
  }

  setSelected(selected: string) {
    if (selected == 'PRICE: LOW TO HIGH') {
      this.setState({
        order: 'less'
      }, () => {
        this.resetList()
      })
    } else if (selected == 'PRICE: HIGH TO LOW') {
      this.setState({
        order: 'greater'
      }, () => {
        this.resetList()
      })
    } else if (selected == 'RECENTLY LISTED') {
      this.setState({
        order: undefined
      }, () => {
        this.resetList()
      })
    } else if (selected == 'OLDEST') {
      this.setState({
        order: 'oldest'
      }, () => {
        this.resetList()
      })
    } else if (selected == 'RECENTLY SOLD') {
      this.setState({
        order: undefined
      }, () => {
        this.resetSold()
      })
    }
  }

  render() {
    return (
      <ProfileView
        open={this.state.open}
        openCarret={this.openCarret.bind(this)}
        status={this.state.status}
        statusOpen={this.statusOpen.bind(this)}
        statusPills={this.state.statusPills}
        statusSelected={this.state.statusSelected}
        setStatusSelected={this.setStatusSelected.bind(this)}
        collection={this.state.collection}
        collectionOpen={this.collectionOpen.bind(this)}
        collections={this.state.collections}
        setActive={this.setActive.bind(this)}
        setSelected={this.setSelected.bind(this)}
        activeCollection={this.state.activeCollection}
        filter={this.state.filter}
        setFilter={this.setFilter.bind(this)}
        listings={this.state.listings}
        raw={this.state.raw}
        sold={this.state.sold}
        data={this.state.data}
        user={this.props.user}/>
    )
  }
}

export default Profile;

import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

import Button from '../../components/Button'
import Drop from '../../components/Drop'

type HomePageProps = {}
type HomePageState = {
  value: string
}

class HomePage extends Component<HomePageProps, HomePageState> {
  state = {
    value: ""
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark'])
  }

  search() {
    if (this.state.value != "") {
      if (!this.state.value.startsWith('S') && this.state.value.indexOf('.') == -1) {
          this.state.value += '.btc';
      }
      window.location.href = '/' + this.state.value;
    }
  }

  onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      value: event.target.value
    })
  }

  keyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode == 13) {
      this.search();
    }
  }

  render() {
    return (
      <div className={styles.HomePage}>
        <div className={styles.HomePageView}>
          <h1>
            Explore, collect, and sell NFTs <span className={styles.Highlight}>secured by Bitcoin</span>
          </h1>
          <h2>
            The first open marketplace for Stacks NFTs, with the lowest commission anywhere
          </h2>
          <div className={styles.HomePageSpacer}>
          </div>
          <div className={styles.HomePageInputContainer}>
            <div>
              <Button
                text={"VISIT MARKETPLACE"}
                onClick={() => { window.location.href = '/marketplace' }} />
            </div>
            <hr />
            <div>
              <input
                className={styles.HomePageInput}
                type={"text"}
                placeholder={"STX or BNS Address"}
                onChange={this.onChange.bind(this)}
                onKeyDown={this.keyDown.bind(this)}
                value={this.state.value}/>
            </div>
            <div>
              <Button
                text={"GO TO ADDRESS"}
                style={'SECONDARY'}
                onClick={this.search.bind(this)} />
            </div>
            <hr />
            <div>
              <a href="https://blog.stxnft.com" target="_blank" style={{textDecoration: 'none'}}>
                  <Button
                    text={"BLOG"}
                    style={'SECONDARY'} />
                  </a>
            </div>
            <div>
              <a href="https://blog.stxnft.com/calendar" target="_blank" style={{textDecoration: 'none'}}>
                  <Button
                    text={"CALENDAR"}
                    style={'SECONDARY'} />
                  </a>
            </div>
            <div>
              <a href="https://stxnft.com/submit" target="_blank" style={{textDecoration: 'none'}}>
                  <Button
                    text={"SUBMIT YOUR WORK"}
                    style={'SECONDARY'} />
                  </a>
            </div>
            <div>
              <a href="https://stxnft.com/newsletter" target="_blank" style={{textDecoration: 'none'}}>
                  <Button
                    text={"NEWSLETTER"}
                    style={'SECONDARY'} />
                  </a>
            </div>
          </div>
        </div>
        <div className={styles.HomePageCollectionsView}>
          <Drop image={"/projects/roots.png"}
                 name={"Roots"}
                 description={"The first collection on Stacks to be minted with Bitcoin, via the Lightning Network and LNSwap.org."}
                 onClick={() => { window.location.href = '/collection/roots'}} />
          <Drop image={"https://stxnft.mypinata.cloud/ipfs/QmdsRZoXk8wsBsBL8xtx2q2m2eKaDMzwdWh6SdAXzdLMXr/images/_ITWILLNOTBETELEVISED_0001_S02_F20.gif"}
                 name={"ITWILLNOTBETELEVISED"}
                 new={true}
                 description={"ITWILLNOTBETELEVISED by dyle.btc"}
                 onClick={() => { window.location.href = 'https://create.stxnft.com/collection/itwillnotbetelevised'}} />
          <Drop image={"https://cdn.discordapp.com/attachments/919380497577177098/928856187179503616/GIF3fps.gif"}
                 name={"Bitcoin Whales"}
                 new={true}
                 description={"Stack Sats with Bitcoin Whales, a collection of 300 unique and randomly generated NFTs, that will help you to rise from simple Shrimp to legendary Humpback, Queen of the deep oceans!"}
                 onClick={() => { window.location.href = '/collection/bitcoin-whales'}} />
          <Drop image={"https://stxnft.mypinata.cloud/ipfs/QmbnR4yhooEhYJLoeLrUipSHhfBQ5ofjL1SP1jy7wBzagY/28.jpg"}
                 name={"TRUBIT: Domino"}
                 description={"The Domino Collection represents the power of chain reactions. Each individual who takes action and influences others has the ability to generate downstream impacts they can barely comprehend."}
                 onClick={() => { window.location.href = '/collection/trubit-domino'}} />
          <Drop image={"https://stxnft.mypinata.cloud/ipfs/QmSpu1tFB4zamGqKp9urEQsYpZ3ztq64vigXSdpBAFS4qi/1.png"}
                 name={"NYC Degens"}
                 description={"NYC Degens: a tribute to the Stacks blockchain and NYCCoin’s success."}
                 onClick={() => { window.location.href = '/collection/nyc-degens'}} />
          <Drop image={"/projects/crashpunks.png"}
                 name={"CrashPunks"}
                 description={"A collection of NFTs inspired by sci-fi, anime, and crypto culture."}
                 onClick={() => { window.location.href = '/marketplace/crashpunks-v2'}} />
          <Drop image={"https://byzantion.mypinata.cloud/ipfs/QmPhjntRpW4Rzgd7pv1dXybZUmgNVFbmGFk1PfhcNo2GDm?img-width=274&img-height=300&img-fit=cover&img-quality=85&img-format=webp&img-onerror=redirect"}
                 name={"Magic Ape School"}
                 description={"Do you want to become a student of the magic ape school? First grade of 2,500 apes, now minting."}
                 onClick={() => { window.location.href = '/collection/magic-ape-school'}} />
          <Drop image={"https://media.discordapp.net/attachments/917153624587653180/920750940783538206/sqbanner-pi.png"}
                 name={"Project Indigo"}
                 description={"A collection of 3000 wasteland NFT avatars, built on Stacks, involved in an epic interactive post-apocalyptic story."}
                 onClick={() => { window.location.href = '/marketplace/project-indigo-act1'}} />
          <Drop image={"https://stxnft.mypinata.cloud/ipfs/QmTysxTwP4hxJ5MFe3vc9rWQykjHRsbuzjrnTks9ipgRvJ/bitcoin_badger_9.gif"}
                 name={"Bitcoin Badgers"}
                 description={"Bitcoin Badgers is a STX NFT that rewards HODLers. It's a collaborative project between Miami-based artists, engineers, designers, & community members."}
                 onClick={() => { window.location.href = '/marketplace/btc-badgers-v2'}} />
          <Drop image={"https://i0.wp.com/blog.stxnft.com/wp-content/uploads/2021/11/Screen-Shot-2021-11-29-at-11.15.01-AM.png?w=2028&ssl=1"}
                 name={"Augie"}
                 description={"Augie, a Jack Russell Terrier, took my life by storm the day he arrived from Virginia at 10 weeks of age. He was a tiny but mighty puppy who learned to leverage his cuteness to get whatever he wanted."}
                 onClick={() => { window.location.href = '/collection/augie'}} />
          <Drop image={"https://media.discordapp.net/attachments/906763488196710420/914706687812841533/Untitled_1410_x_2000_px_6.png?width=733&height=1039"}
                 name={"Bitcoin Monkeys"}
                 description={"A limited collection of monkeys were found within a mysterious jungle – each unique in their own way and part of a monkey community. Each NFT giving access to a range of benefits and membership to a secret club found within the jungle."}
                 onClick={() => { window.location.href = '/marketplace/bitcoin-monkeys'}} />
          <Drop image={"https://stxnft.com/projects/commoners-homepage.gif"}
                 name={""}
                 description={"We are 300 individuals with common goals. The Commoner NFT evolves uniquely over time, depending on your own actions and custom attributes."}
                 onClick={() => { window.location.href = '/collection/commoners'}} />
{/*
          <Drop image={"https://stxnft.mypinata.cloud/ipfs/QmStnniaoVgxzDiNmqhQR66wMLRH9wMAC62P5RcUFNttGd/1.png"}
                 name={"Miami Degens"}
                 description={"A tribute to the Stacks blockchain and MiamiCoin’s success. 420 Unique Miami Degens."}
                 onClick={() => { window.location.href = '/collection/miami-degens'}} />
*/}
          <Drop image={"https://media.discordapp.net/attachments/915394298109915146/915663743428493372/120.png"}
                 name={"305"}
                 description={"A collection by Shaderverse, paying homage to the 305."}
                 onClick={() => { window.location.href = '/collection/305'}} />
          <Drop image={"https://media.discordapp.net/attachments/895185060121182268/903138022176989204/unknown.png"}
                 name={"WCH Presents: Fuji - Celestial"}
                 description={"The Celestial Collection is the result of Fujis’ creative journey into the cosmos, a collection originally stemming from a photograph."}
                 onClick={() => { window.location.href = '/collection/celestial'}} />
          <Drop image={"https://stxnft.mypinata.cloud/ipfs/QmQDzcEVcTujGP3iDy867dvA4DdQ5APx1qPnsp9RpuJmLa"}
                 name={"Memes Become Movements Become Markets"}
                 description={"CityCoins allow you to vote with your coin and not just your ballot. This NFT created by Jack Butcher typifies the nature of CityCoins where you can vote as quickly as you can allocate capital."}
                 onClick={() => { window.location.href = '/collection/memes-movements-markets'}} />
          <Drop image={"./projects/arties.gif"}
                 name={"Arties"}
                 description={"The Arties Cosmic Robot Club (#ACRC) isn’t just any old NFT collection. Our fun-loving Arties are transforming the elites-only art market into a transparent, democratized market for art lovers everywhere."}
                 onClick={() => { window.location.href = '/collection/arties'}} />
          <Drop image={"/projects/Stacculents_Marketing_Cover_Image.png"}
                 name={"Stacculents"}
                 description={"Stacculents are available to good homes. Adopt a unique & magical new friend today!"}
                 onClick={() => { window.location.href = '/collection/Stacculents'}} />
          <Drop image={"https://www.megapont.com/images/megapont_ape_club.svg"}
                 name={"Megapont"}
                 description={"Megapont Ape Club is a celebration of the more scenic members of the Megapont world. Due to accelerated evolution, they have inherited many behaviours of the closely related human species. Both the good and bad."}
                 onClick={() => { window.location.href = '/marketplace/megapont-ape-club-nft'}} />
          <Drop image={"https://media.discordapp.net/attachments/908830055956889642/909202132622786571/STXNFT_PFP.png"}
                 name={"HODL The Line"}
                 description={"A collection of 300 of the blockchain's most skilled and brave warriors. First in and last out. This group fears nothing and is prepared to HODL the line until death."}
                 onClick={() => { window.location.href = '/collection/hodl'}} />
          <Drop image={"https://stxnft.mypinata.cloud/ipfs/QmVKaAYt3uKukty5dY87G6xyTfmnzdNJWUQ7Esp7t1cChq/0.png"}
                 name={"Pokinometry"}
                 description={"A collection by Yannick Scott, Pokinometry is a vibrant collection of 250 poke bowls topped with an assortment of mouth-watering ingredients to be consumed in the metaverse."}
                 onClick={() => { window.location.href = '/collection/pokinometry'}} />
          <Drop image={"https://helias.garden/static/media/helias.9531f165.gif"}
                 name={"Helias"}
                 description={"https://helias.garden/static/media/helias.9531f165.gif"}
                 onClick={() => { window.location.href = '/collection/helias'}} />
          <Drop image={"https://cloudflare-ipfs.com/ipfs/QmZ6zV11a841kPffJ5a4RjCdQx3vEEUfrPMk4uPvpM14za/OtherWorlds1.JPG"}
                 name={"Other Worlds"}
                 description={"Working with AR, AI and in the metaverse for the last two decades has opened my eyes to the possibilities for ‘other worlds’ that can be created through the dreams of those who are willing to open their minds to the possibilities that exist beyond and deep within themselves."}
                 onClick={() => { window.location.href = '/collection/other-worlds'}} />
          <Drop image={"https://stxnft.mypinata.cloud/ipfs/QmcUN233m5qhjFR3VBAzCA3jNuyEYBoM95fWXB9Fy9XfkB"}
                 name={"MiamiCoin is Part of the Bitcoin Family"}
                 description={"“MiamiCoin is part of the Bitcoin Family” embodies the spirit of the CityCoins project, its growing community, and how the marriage between Bitcoin and Stacks gives birth to new forms of civic engagement through blockchain technologies."}
                 onClick={() => { window.location.href = '/marketplace/SPZW30K9VG6YCPYV4BX4V1FT0VJ66R1Q01W9DQ1W.borges-mia-btc-fam'}} />
          <Drop image={"https://www.explorerguild.io/placeholder-image.png"}
                 name={"The Explorer Guild"}
                 description={"10 000 collectible NFTs generated using 255 hand drawn attributes. The collection is inspired by Jules Verne, the french Sci-fi writer from 200 years ago."}
                 onClick={() => { window.location.href = '/marketplace/the-explorer-guild'}} />
          <Drop image={"https://stxnft.mypinata.cloud/ipfs/QmSjoRt73XnfY57FWdDHauJmNiqND2VPJEnCZzk6ZhxvQZ/104.jpg"}
                 name={"Nebula"}
                 description={"The combination of mysterious and beautiful nebula and modern digital art, each one is unique. Here can bring you unlimited imagination, every picture has its own story."}
                 onClick={() => { window.location.href = '/collection/nebula'}} />
          <Drop image={"https://www.cubenft.art/images/fulls/cube_1.png"}
                 name={"cube"}
                 description={"We like the Cube. Cube is heavy. Heavy is good."}
                 onClick={() => { window.location.href = '/collection/Cube'}} />
         <Drop image={"./SatoshisTeam.png"}
                 name={""}
                 description={"Heavy hitters, all-stars and legends of the game join forces to create a collection of unique varsity jackets featuring the ultimate team: Satoshi's Team."}
                 onClick={() => { window.location.href = '/collection/SatoshisTeam'}} />
          <Drop image={"https://stxnft.mypinata.cloud/ipfs/QmT9uBjDcWsbQosFehnJ8bxLAi22p71eE9652CFiJxQe9e/Cyber%20STX%20%2307.gif"}
                 name={"Cyber STX"}
                 description={"Cyber STX is a collection of 26 unique GIFs that aims to merge your favourite Cryptocurrency with the emerging Metaverse."}
                 onClick={() => { window.location.href = '/collection/cyber-stx'}} />
          <Drop image={"https://cdn.discordapp.com/attachments/895214476478013440/908974087148306512/Screen_Shot_2021-11-12_at_5.04.35_PM.png"}
                 name={"Stacks Pandas"}
                 description={"StacksPandas is the first limited edition NFT collection by Stacks China Chapter."}
                 onClick={() => { window.location.href = '/collection/pandas'}} />
          <Drop image={"https://stxnft.mypinata.cloud/ipfs/QmdcBZnzSUwAKQdnVMKSkbVYoDD6DBkghPPUAwtVQjpwgq/155.jpg"}
                 name={"Anime Boys"}
                 description={"Anime Boys is a collection of 200 unique handcrafted avatars with famous heroes."}
                 onClick={() => { window.location.href = '/collection/anime-boys'}} />
        </div>
      </div>
    )
  }
}

export default HomePage

import React, { Component } from 'react'
import { Helmet } from 'react-helmet' 
import styles from './index.module.scss'

import { AppConfig, UserSession, showConnect } from '@stacks/connect';
import { gaSend } from '../../Analytics';

import Price from '../../components/Price'
import Collections from '../../components/Collections'
import Search from '../../components/Search'

import Card from '../../components/Card'
import Dropdown from '../../components/Dropdown'
import Loading from '../../components/Loading'

import Confirm from '../../components/Confirm'
import MobileSelector from '../../components/MobileSelector'
import Modal from '../../components/Modal'
import Renderer from '../../components/Renderer'

import icon from '../../components/Login/assets/icon.svg'

import InfiniteScroll from 'react-infinite-scroll-component';

import { localPermalink, renderer } from '../../utils'

import {
    transfer,
    listAsset,
    unlistAsset,
    purchaseAsset
} from '../../Contracts'

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

type MarketplaceViewProps = {
  active?: string
  modal?: any
  open: boolean
  openCarret: (...args: any[]) => void
  status: boolean
  statusOpen: (...args: any[]) => void 
  statusPills: Array<string>
  statusSelected: Array<string>
  setStatusSelected: (...args: any[]) => void 
  collection: boolean
  collectionOpen: (...args: any[]) => void  
  collections: any
  setActive: (x?: any) => void
  activeCollection?: string
  filter: string
  setFilter: (filter: string) => void
  data: Record<string, any>
  listings: Array<any>
  sold: boolean
  selected: string
  hasMore: boolean
  setSelected: (selected: string) => void
  getBlocksNext: (offset: number) => void
  setModal: (...args: any[]) => void
  modalClose: () => void 
}

type MarketplaceViewState = {
  userData?: any
}

class MarketplaceView extends Component<MarketplaceViewProps, MarketplaceViewState> {
  state = {
    userData: undefined,
  }

  getLength() {
    if (this.props.listings)  {
      return this.props.listings.length;
    } else {
      return 0;
    }
  }

  componentDidMount() {
    try {
      const userString = localStorage.getItem('userData')
      if (userString) {
        const userData = JSON.parse(userString);
        this.setState({
          'userData': userData
        })
      }
    } catch {}
  }

  getBlocksNext() {
    this.props.getBlocksNext(this.getLength())
  }

  authenticate() {
    showConnect({
      appDetails: {
        name: 'Stacks NFT',
        icon: icon,
      },
      redirectTo: '/',
      onFinish: () => {
        let userData = userSession.loadUserData();
        localStorage.setItem('userData', JSON.stringify(userData));
        gaSend('User', 'authenticate', 'User Authenticated');
        window.location.reload();
      },
      userSession: userSession,
    });
  }

  stxAddress() {
    if (this.state.userData) {
      const userData: any = this.state.userData;
      return userData.profile.stxAddress.mainnet;
    } else {
      return null;
    }
  }

  purchaseNFT = (nft: any) => {
    if (this.state.userData) {
      if (this.stxAddress() === nft.listing.owner) {
        return;
      }
      
      const onFinish = (data: any) => {
        gaSend('Transaction', 'sale', 'NFT Sold', parseInt(nft.listing.price)/1e6);
        this.props.setModal({ 
          success: true,
          successTransaction: data.txId,
        });
      }

      const onCancel = () => {
        gaSend('Transaction', 'sale-cancel', 'NFT Sale Cancelled', parseInt(nft.listing.price)/1e6);
        this.props.setModal({ 
          success: false,
          errorMessage: "Transaction Failed"
        });
      }

      purchaseAsset(nft, this.stxAddress(), onFinish, onCancel);
    } else {
      this.authenticate()
    }
  }

  gotoProfile(x: any) {
    window.location.href = '/' + x.listing.owner
  }

  badges(nft: any) {
    var badgeArr: any = []
    if (this.props.collections) {
      const collections: any = this.props.collections
      for (var index = 0; index < collections.length; index++) {
        if (this.props.collections[index]['contractAddress'] == nft.contractAddress &&  this.props.collections[index]['contractName'] == nft.contractName &&  this.props.collections[index]['verified']) {
          badgeArr.push('VERIFIED')
        }
      }
    }
    return badgeArr
  }

  getAttributes(nft: any) {
    if (nft.attributes && Array.isArray(nft.attributes)) {
      return nft.attributes
    }
  }

  checkBlacklisted(nftData: any, x: any) {
    if (nftData && nftData.collection && nftData.name && nftData.collection.includes('Boom NFT') && nftData.name.includes('Bitcoin Rock')) {
      if (![5193, 5201, 5202, 5426, 5204, 5203, 5236, 5237, 5238, 5239, 5240, 5241, 5242, 5243, 5244, 5351, 5352, 5439, 5329, 5330, 5403, 5404, 5405, 5423, 5422, 5406, 5459, 5424, 5425, 5458, 5460, 5461, 5462, 5463, 5464, 5536, 5537, 5538, 5539, 5540, 5541, 5542, 5543, 5544, 5545, 5546, 5559, 5560, 5593, 5592].includes(x.tokenID)) {
        return true;
      }
    }

    if (x && x.listing && x.listing.owner == 'SP16KYF36BV4BSP8FTGPM240XYMRB94S8QSPBX85G') {
      return true;
    }

    const blacklistedBoomNames = ['Megapont', 'Bitcoin Bird', 'APE #', 'BitcoinBird', 'StacksPunk', 'Stacks Punk', 'PhasesOf', 'Phases of', 'Bitcoin bird', 'BitcoinMonk', 'Bitcoin Monk', 'Explorer G', 'ExplorerG'];
    if (nftData && nftData.collection && nftData.name && nftData.collection.includes('Boom NFT')) {
      for (var blacklistedName of blacklistedBoomNames) {
        if (nftData.name.includes(blacklistedName)) return true;
      }
    }
    return false;
  }

  render() {
    let hasRarity = this.props.listings && this.props.listings.length && this.props.listings[0] && this.props.listings[0].data && this.props.listings[0].data.rarityRank;

    var marketplaceTitle = '';
    if (this.props && this.props.activeCollection && this.props.listings && this.props.listings[0] && this.props.listings[0].data && this.props.listings[0].data.collection) {
      marketplaceTitle = this.props.listings[0].data.collection;
    }

    return (
      <div className={styles.MarketplaceView}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{marketplaceTitle ? marketplaceTitle + ' Marketplace' : 'Marketplace' } | STXNFT</title>
        <meta name="description" content={'Buy and list any ' + marketplaceTitle + ' NFT today, plus see NFTs listed across any Stacks marketplace.'} />
      </Helmet>
      {this.props.modal &&
        <Modal onClose={this.props.modalClose}>
          <Confirm
            success={this.props.modal.success}
            transactionId={this.props.modal.successTransaction}
            errorMessage={this.props.modal.errorMessage}
            onClose={this.props.modalClose} />
        </Modal>
      }
        {/*<div className={styles.MarketplaceTopBar}>
          <div className={styles.MarketplaceSearch}>
          <Search
              filter={this.props.filter}
              setFilter={this.props.setFilter.bind(this)}/>
          </div>
          
        </div>*/}
        <div className={styles.MarketplaceViewSelectorMobile}>
          <MobileSelector
            setSelected={this.props.setSelected}
            collections={this.props.collections}
            collection={this.props.activeCollection ?? {'image': undefined, 'text': "ALL"}}
            setActive={this.props.setActive.bind(this)}/>
        </div>
        <div className={styles.MarketplaceContainer}>
          <div className={styles.MarketplaceViewSelector}>
            <Dropdown
              selected={this.props.selected}
              rarity={hasRarity}
              setSelected={this.props.setSelected}/>
            {/*<Price
              open={this.props.status}
              onOpen={this.props.statusOpen.bind(this)}/>*/}
            <Collections
              open={this.props.collection}
              onOpen={this.props.collectionOpen.bind(this)}
              data={this.props.data}
              collections={this.props.collections}
              setActive={this.props.setActive.bind(this)}
              active={this.props.activeCollection}/>
          </div>
          <div
            className={styles.MarketplaceCardContainer}
            id={"block-list"}>
          {(this.props.listings && this.props.listings.length > 0) &&
            <InfiniteScroll
              dataLength={this.getLength()}
              next={this.getBlocksNext.bind(this)}
              hasMore={this.props.hasMore}
              loader={<Loading />}
              className={styles.ScrollContainer}
              scrollableTarget={"block-list"}>
              {
                this.props.listings.map((x: any ) => {
                  const key = x.contractAddress + '.' + x.contractName + ':' + x.tokenID
                  const nftData = x.data ?? this.props.data[key]
                  if (this.checkBlacklisted(nftData, x)) {
                    return ('');
                  }
                  if (nftData) {
                    return (
                      <Card
                        badges={this.badges(x)}
                        renderer={renderer(nftData)}
                        collection={nftData.collection || nftData.name}
                        name={nftData.name}
                        rarityRank={nftData.rarityRank}
                        attributes={this.getAttributes(nftData)}
                        sold={this.props.sold ? x.listing.price : undefined}
                        buy={this.props.sold ? undefined : x.listing.price}
                        buyFunction={()=>{ this.purchaseNFT(x) }}
                        profile={()=>{this.gotoProfile(x)}}
                        link={()=>{localPermalink(x)}}/>
                    );
                  } else if (x.contractName && x.contractName.length) {
                    const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
                    return (
                      <Card
                        badges={this.badges(x)}
                        renderer={<Renderer />}
                        collection={titled}
                        name={titled + ' #' + x.tokenID} />
                    );
                  }
                })
              }
            </InfiniteScroll>
          }

          {(this.props.listings.length  == 0) &&
            <Loading />
          }
          </div>
        </div>
      </div>
    )
  }
}

export default MarketplaceView;

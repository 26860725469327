import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

import { renderPrice } from '../../utils'

type ButtonProps = {
  text: string
  style: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  currency: string
  price?: number
  onClick?: (...args: any[]) => void
}

type ButtonState = {}

class Button extends Component<ButtonProps, ButtonState> {
  static background = {
    PRIMARY: styles.Primary,
    SECONDARY: styles.Secondary,
    ERROR: styles.Error,
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  static defaultProps = {
    text: '',
    style: 'PRIMARY',
    currency: 'STX'
  }

  onClick() {
    if(this.props.onClick) {
      this.props.onClick()
    }
  }

  amount() {
    if (this.props.price !== undefined) {
      if (this.props.currency == 'STX' || this.props.currency == 'BTC') {
        return this.props.price
      } else {
        return this.props.price * 1e6
      }
    }
  }

  render() {
    const price = this.amount()
    return (
      <div
        className={`${Button.background[this.props.style]}
         ${styles.Button}`}
        onClick={this.onClick.bind(this)}
      >
        {/* render text */}
        <span>{this.props.text.toUpperCase()}</span>

        {/* render spacer */}
        {this.props.price !== undefined && <div className={styles.PriceSpacer}></div>}

        {/* render price */}
        {price !== undefined && (
          <div className={styles.Price}>
            {this.props.currency == 'STX' && 
              <div className={styles.PriceSymbol}>
                          <svg
                            width={'21px'}
                            height={'21px'}
                            viewBox={'0 0 21 21'}
                            version={'1.1'}
                            xmlns={'http://www.w3.org/2000/svg'}
                          >
                            <g strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
                              <g transform={'translate(-398.000000, -268.000000)'}>
                                <g transform={'translate(399.000000, 269.000000)'}>
                                  <g>
                                    <line x1={'5'} y1={'7.5'} x2={'17'} y2={'7.5'}></line>
                                    <line
                                      x1={'-1.2490009e-16'}
                                      y1={'7'}
                                      x2={'8'}
                                      y2={'7'}
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1={'2'}
                                      y1={'7'}
                                      x2={'8'}
                                      y2={'7'}
                                      strokeWidth={'2'}
                                      strokeLinecap={'round'}
                                    ></line>
                                    <line
                                      x1={'11'}
                                      y1={'7'}
                                      x2={'17'}
                                      y2={'7'}
                                      strokeWidth={'2'}
                                      strokeLinecap={'round'}
                                    ></line>
                                    <line
                                      x1={'11'}
                                      y1={'7'}
                                      x2={'19'}
                                      y2={'7'}
                                      strokeWidth={'2'}
                                    ></line>
                                    <line
                                      x1={'3'}
                                      y1={'0'}
                                      x2={'8'}
                                      y2={'7'}
                                      strokeWidth={'2'}
                                      strokeLinejoin={'round'}
                                    ></line>
                                    <line
                                      x1={'11'}
                                      y1={'0'}
                                      x2={'16'}
                                      y2={'7'}
                                      strokeWidth={'2'}
                                      strokeLinejoin={'round'}
                                      transform={
                                        'translate(13.500000, 3.500000) scale(-1, 1) translate(-13.500000, -3.500000) '
                                      }
                                    ></line>
                                  </g>
                                  <g
                                    transform={
                                      'translate(9.500000, 14.500000) scale(-1, 1) rotate(-180.000000) translate(-9.500000, -14.500000) translate(0.000000, 10.500000)'
                                    }
                                  >
                                    <line x1={'5'} y1={'7.5'} x2={'17'} y2={'7.5'}></line>
                                    <line
                                      x1={'-1.2490009e-16'}
                                      y1={'7'}
                                      x2={'8'}
                                      y2={'7'}
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1={'2'}
                                      y1={'7'}
                                      x2={'8'}
                                      y2={'7'}
                                      strokeWidth={'2'}
                                      strokeLinecap={'round'}
                                    ></line>
                                    <line
                                      x1={'11'}
                                      y1={'7'}
                                      x2={'17'}
                                      y2={'7'}
                                      strokeWidth={'2'}
                                      strokeLinecap={'round'}
                                    ></line>
                                    <line
                                      x1={'11'}
                                      y1={'7'}
                                      x2={'19'}
                                      y2={'7'}
                                      strokeWidth={'2'}
                                    ></line>
                                    <line
                                      x1={'3'}
                                      y1={'0'}
                                      x2={'8'}
                                      y2={'7'}
                                      strokeWidth={'2'}
                                      strokeLinejoin={'round'}
                                    ></line>
                                    <line
                                      x1={'11'}
                                      y1={'0'}
                                      x2={'16'}
                                      y2={'7'}
                                      strokeWidth={'2'}
                                      strokeLinejoin={'round'}
                                      transform={
                                        'translate(13.500000, 3.500000) scale(-1, 1) translate(-13.500000, -3.500000) '
                                      }
                                    ></line>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
            }
            <div className={styles.PriceValue}>{renderPrice(price, this.props.currency)}</div>
            {this.props.currency != 'STX' &&
              <div className={styles.PriceText}>
                {this.props.currency}
              </div>
            }
          </div>
        )}
      </div>
    )
  }
}

export default Button

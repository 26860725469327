import React, { Component } from 'react'
import { Helmet } from 'react-helmet' 
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

import makeEndpoint from '../../endpoints'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Loading from '../../components/Loading'
import Renderer from '../../components/Renderer'

import icon from '../../components/Login/assets/icon.svg'

import {
  AppConfig,
  UserSession,
  showConnect,
  openContractCall
} from '@stacks/connect';

import {
  uintCV,
  intCV,
  bufferCV,
  stringAsciiCV,
  stringUtf8CV,
  standardPrincipalCV,
  trueCV,
  makeStandardFungiblePostCondition,
  FungibleConditionCode,
  createAssetInfo,
  makeStandardNonFungiblePostCondition,
  NonFungibleConditionCode,
  makeStandardSTXPostCondition
} from '@stacks/transactions';

import { StacksTestnet, StacksMainnet } from '@stacks/network';

import BigNum from 'bn.js'

import InfiniteScroll from 'react-infinite-scroll-component';

import { renderer } from '../../utils'

const testnet = new StacksTestnet();
const mainnet = new StacksMainnet();

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

type TheopetraProps = {
  name: string
}

type TheopetraState = {
  name: string
  contractAddress: string
  contractName: string
  description: string
  image: string
  total: number
  lastTokenID: number
  prices: number
  currency?: string
  saleData: Record<string, any>
  sale: Array<any>
  active: boolean
  paused: boolean
  userData?: any
  offset: number
}

const enable_whitelist = false;

const whitelist = [
'SP281EDFY54S0GCDTKPEKP6K32Y8VTJ6DC4KXTFY3',
'SP12BEEDG31J0AH68DFDJJYZ36D002PKDZCP1DZQE',
'SP9N0STYJBRMR4XWJE67WAT7YE9ZV47F7H8MWBV5',
'SPY0WW5B1BXWRBTDN3G3MR43SN04QYRKZ0Y8EEKN',
'SP2HX7247FTWB9FEM3J5CKJ3H3FKZ51C4D3XABFT',
'SPD1FGYTNQE7AZ3CRTH7FTZNC0QHGHJJW56KSTS9',
'SP37XZWB0DWXNKMT0172HHMWCK35VBBEK19DN2YYT',
'SPHKPJXTXJ67E195R4Q7R5XWY4AJFNGW7300F5SP',
'SP1NF7E8BWQ4F1WD9ZV2FQDQ8V79JFQR6YFVTBYHY',
'SP3MYM8YGM8MVT10WKQ3A19E3MAG2H6PHCW8PMZWT',
'SP240VMERT7ZW7QEGS2C0A3NPRDVSEVEBFB7AV4H7',
'SP2W3ZFMA5CPFA21FW15ZYD1J0QMR96RWZQEJ2Z00',
'SP24VEVX68QXJ8HV8TKKV5PTQX7Y14TXDM4E2MJAF',
'SP1BS2VHJMAVNQD2V8XNDMX78QTXCZJ8XMMVN9PCS',
'SP1V5JHZK64JB2RD2VXVH2T5MS5WE8J3T33YEJTQJ',
'SP2V4ZRDWZE82W0JMYW3Y3Z80PK8V9RAJCGK9W7HP',
'SP2V8JF9VFW4DWWBKGNSF245T7RFZVY4S3VHP03R0',
'SP2BT3EQ9K4BQ4H6JEDJB6FRAZFF24WJBYZJ0ATZ',
'SP2WYX7CAFFM8J51BT49SV8MM1NXYF1ZK0NXNVRSV',
'SPCGYWGKWZ9P21Y31H3GC1BYDEFQ1MJJYM3G34EK',
'SP1E4798MP7RNHPVSBM954MSS5EJNM1AC3R53DC31',
'SP2PPYXC7B0G5Y7JXJZ3QA2KY4657HAQTTS5KJ5HQ',
'SP3T7SA543GTWEPD3022B66RYN2WZ4SQW64S686AA',
'SP3CCSZ71MCFRKRASSTKT7HY5Z4ENWRT3YZZ2EZT4',
'SP3SK8XV6YQJK9J5E9SCXWS062EVKH61147A54BJE',
'SP3FM5J0X9W962E8QPT4TQ8E29HJMQE508RSZMCF4',
'SPFERFF3QKF0Q6WBC4Y2Y6RQWEGN3DTDD5Y7S0NY',
'SP343PX7QRZMPH7X4WD4R15YZ99HRB0P6P50RGGEM',
'SPBCFZ1ZQ6G0K4B9EQ16ATE6WA0PF9PF6ANV9431',
'SP3SGG4P7W61ZVSQNGMTDXNHMVF6ZFFKB7J6NSZ9',
'SP1SWEP0BRXGYRKDSS83XTZVHH883JH1TZ7TQS8XZ',
'SPSGP0XHF7VFJQ5193N2SV9MY87EWGVJXAZK35K2',
'SP3QFME3CANQFQNR86TYVKQYCFT7QX4PRXN0JJ4B8',
'SPCBWRT7P9J1A2S3FPGAVZ5032EDX762ZNDMXV17',
'SP3EM74GQYGDCQCXKJ46DNXNMMJYGG7SNJP8FMWEK',
'SP2891F1DM9QJ14F711C62NQS3RQG4VFV6ZTEEN60',
'SPXCJ8D2GM0Z40BDR97T6R1K0XKAVV845276WMW0',
'SP3E74W94D7DCMBFJ1CHJF30QAWTZECJQBFPKB1SS',
'SPR0X40NTGQY097TAMHCWQGPQ0QGZ5CC5XYY6272',
'SP2XWV003E8463HPEQY8D8GPM5AYW4GFWFHY6CGJH',
'SP3Z372Q2VCJCKX270666ZMAA6R29DW3YXZHPJDQ',
'SP3N6EZPTSX8ZV2RGPY9NR9A8CA0QET39CY978H5E',
'SP3NH26Q1KYANP3CYBV5ZWRJVXBPBE8GK33S8M20F',
'SP2KAF1XBP9Y59HFCZ3JSVRXEBG44R6ZM9BZ4NHT4',
'SP2DQPND6S9FX1JRBADKW0P5QQYNR1YCFXMEC92AD',
'SP1M9Y29PB5S56HW4VV8KZ1B6TSSXMF8Q5KQGRRZ2',
'SP1XPCCJE4NR82X6D8PX32NF1KAYYM36B5T83J6GP',
'SPKCN7FXPWGKN6NSBHYA2EJG8PHF451B5BMRXGP1',
'SP1C6WQ9KTV3769S8X8YNAWBXKDG2Y65P5EEDRWR6',
'SP3RSG9RRWBWER26KYYEB8HTPHD01RX21WHSBTQ35',
'SP36QNXX8T9MFAR7DJJA2RH3YCXFFQZ5YSG9QP61S',
'SPTNCGD0W11HJ3A0K6KEV5JPZATZ47GP3F73HRR4',
'SP2Z0P8R47V1R0BKBM92QPJ1QTKS2C762C8713P1F',
'SP37WC4F9RG4DYRCV8H318SW5EBVMC5B2441JE05R',
'SP1G9HS8CXVMFJPGREJVKWE0RDH3V5DZPFJW7KNYJ',
'SP3GDVJ93E47GXZVV963BY1S8GR7HNGQN316SPTF5',
'SP1PKK6KJPM826D0X6AMCJ63KEH2M456M4T22WAPQ',
'SP3R10QNJD36V1R2BQTJGD6N81YY6AFABCNYA3YB5',
'SP2XSH77HP616VKM6SV83KAV0G5D3M6ZTM3WM7PFZ',
'SP3ZARDMD7Z92S3KNXZXSWFSN7513N0GP67Z2RAXE',
'SPYZWGYD9XAGVH5BNGW9T5V4QM65QZSKGJJZMC1Q',
'SP1EN4DH7PR2DH084AESK4YX12YFVZ9RJ62ABJNBR',
'SP198A59ZSGSS8W6J7DSQVZYD168YXKRKDJKRFWCG',
'SP3NT7FVEY8J2Z4SQF704S1S36HV7WXFTHT800MW3',
'SP2GR2QTQB5CK15JRM909M32B8C300DJW4823B7QM',
'SP1ZQKWA1M06FEKHVWQWK9611PCFKGDDHFY79SBDA',
'SP2VA74PKCDFYDVWZFZDNY72PZBWKX9TNB8YXXET5',
'SP2ZKEYFP4TS99FVNMA7A6BDY9T6QK91BNPBTJFPT',
'SPB19RHT7WEX8H52V5KGAT891SZ5MFAE9GE156WJ',
'SP2JCTGX5VAR22K2VGTHPBPV70BBAYJ0W5X58YJFN',
'SP3AFG83SCBMPGDX24GM7A4C5XMF74MN79E3SDRXM',
'SP2RJ1Z3Q34HCQE5JAWKZFZ8EBVCKY77J39CBZFVG',
'SP13M3AG1C419Q58PBN3NQZVA4F780YYV74ECSD1T',
'SPKDKYWGT1P3DHYVRMAB9T7EZVY7KGN8JTWKED08',
'SP3QW2CSY0ZX5AQ4HAA6CNZFCFSN0ZP81XVDEDCHH',
'SP344GD7G06J6KH2JTXBF1GWF3WE5DXJK202NNT4C',
'SP3SKQXRJCZ1HPJV4JCH4EC78RRTQP3Y3M33TARHQ',
'SP10N28YFDZZ7HQ6SKCVFHDZA4TTWHE3E64ZH9F8N',
'SPTWXM5VZREENGTFJQ1Z0P1EWGDMP2PF1NHS9ZBT',
'SP21JJ0KVPTGAA1PD8RG1QR0ZSCFX39Q86T5NNFCY',
'SP1CXKQZXC91PHPT52EHW083G5XX26SA3JC5TRNCS',
'SP3W208N82WD0JFW942H39BQBTMF0E01C86FJSPV4',
'SP15BTX5TE117YB9T5CPJR13YNKQGXTY14VR20K7D',
'SP3N66VSF1HAH9BP36XEAT2JZWZ45TDJXWENGS7Y5',
'SP3KFQESZRC8F93KMPVN55JT0HETYVYAPX94V1P5B',
'SP27BZ397AMETRKT1T91F2D2QNGB5NXH7VKN9EC4M',
'SPJA1XTXND0KP8ERSMTES0E50APXE9Q79NHZY6WQ',
'SP11TKK3E22DD66R54CA0V1BFPS2RRZ7766NKAPYW',
'SP25PPD2NR9SM8GA9W9J8PGNFVYXJ504BBRQXEX33',
'SPHN749Q2W9AFC03AN7CRDYC4J4AJJ96JC224ED8',
'SP2RJ0FXZN7KFH2REBKPFY11C773DKJ97PYVETX8',
'SP26AM7HQHVCDR9PGFFEZ0013W7C1ERS7YTYZTGPG',
'SP3BWAHYMTHQZHSB8N49AXQNTYWBACQBAN8Z4QFRD',
'SP3C9STH5RTJJRSM9CXPQ88125Y77CD6MGHMJ3R65',
'SP1ZQSWQ9QNNW388VFG45HYX1H592147V2FZZJY8V',
'SP328SGYQN7TBPDF2DQFQ2X43WN4C89PRNDE3KPE9',
'SPRHV84ZSQQ45XZXE6GHP1S8KVWCSFTXJCE05QJ3',
'SPFS69WZA0Z14HGZ3RTGREF1B0BC1RXS81YMK7ET',
'SP3VX2CG8SRQRKD6J3QHPDVNKGPBXDWEA636KF2G4',
'SP2DX0CM1SD1XEABQRWZTW8T6NJSTWV8CS60ZRRHQ',
'SPFCWK0JGPAD33SK7SM4NHWW2S9BX87214291MCD',
'SP23DNEW4KX1KE90AX1BDM74XPJHREBBSMBV2B7NF',
'SPPGVZBJ95PP3HCBNRFS6QG8SV5CQQ4F6EC66DQJ',
'SP1WQGAGQZJQ35PW7X7XWK85XAC1TEJN0R2D1B3T2',
'SP1YXC7JS15B02MCME3K2X491NXN3YKJAZDGCFTB7',
'SP21A5K31071ZMHCM389CTK8DSBX2HPZ5ECTSE5AN',
'SP34WC5TGHXZWEG9KMN9MXNMYK2V03QHMXJ7ZF6YF',
'SP156PKFGDHNQ5322FWE631WR391K7NMZ091G3J6A',
'SP5S62PCAJAGX6ZS99ABXZSEWYMARB1WC55X4G4C',
'SPN6VDWZJZC9FM1N5Q94B823FP52GB1JZGHB8W8',
'SPBJ5AHDPMSN93NGXFSRY5ZWSN2K01PY2JEENGZZ',
'SP2AH54833B025ERE21DE0XRDEN2X3GCEMGRWRMSZ',
'SP7EXA8E3W1J0XBAKE2REQDXHE0DAF8RCXBXZYCA',
'SP38QZ9JK1HG0YJCKX8XXYSMPDC961EEFFVWDZ4W8',
'SP2V8BJNRC5CYM7J1S3CVPSG4904TSKXJDQ44CA2B',
'SP1EZV5WBY2ZME3Y6AGAG0RB7PBN1H7JFBEHEC0GM',
'SP1FAEF7P8AMFB69WC53YV6SPHZ763WZDF8B42SWD',
'SP3XPCXMVN31VBBH95YHPZPRXTN3WT8SAV4JR48KF',
'SPWVJP01MFDSED9CJXD6X0PFEE5YWZXXHPZSPC6S',
'SP307BQHCHH036TZ2SYQG0C01AR5YMYJ9J1XHNNR4',
'SPQXNKDBPDK3378YG4GAM3EW109T6Y8M67J5Z510',
'SP2FA23Y0T6G75KAV9F450GXN546RCC1NY5PGNKMM',
'SPH6RM0XKSKDY9XAMJ91QAM9BW60P1AM4BPYY01E',
'SP27JJS3774KPR0MMB5FH77VWBD8KD0SVNZ4QNX5X',
'SP1V8JV53A65VMGXM15C09ZD6BMS2ACH1AKPAZBGE',
'SP2HKV036P976Y8W5X5WHQV42XM1FKEXWJ3BYH4F0',
'SPYQ829C0KWNEVETW09C93CCBHV3AGM60AKS66AJ',
'SP3P8E93GDG0RKFD7195NK7P0FCWV0AS7TBEFKZ9H',
'SP15F5TG1BZ10CC60YVGNJJNRVDK2848KKFF758XV',
'SP31H0WM99Q64MNDJFPHS12W3TFYZNAST4QBS3P20',
'SP3AZD0R8FW6XWTHZRY06KCJ5MHRAVJGMH6QTT4YP',
'SP3HTPAZ4SMN9RJ8K690TDVZ68X4JFZZJT10JWG7G',
'SP3HXJJMJQ06GNAZ8XWDN1QM48JEDC6PP6W3YZPZJ',
'SP3380ZQEQJVS6H83A166ZXRCNJQR2P6R3KAC8FGM',
'SP2BZQ48MADDN62X044NNJCNXF5BA33C3BFQ3TZJW',
'SP38J10VQRENJWK6NY2CX95D8RKXSYTCDJT8EDYYX',
'SP36KZRX2ET7W215D568EDTA4A392EWN14FT12TAN',
'SP5KEYTM4GCEPXJ7T3QD58TJNSMM4Y12H2ABA2J0',
'SPS2FZ3K6N2CZPBM4BSQCEQV23V2334E7MJ4CHZT',
'SP6G0W08DYNY0FA74NE8GYQ8PSX31BD3N0XMHMBC',
'SPJ3XAPS4BN9G95SVQPAFSS9N8STS53NF6DYZWT0',
'SP8EQY91FBXSE6YXX4VJNYB70PS7MWB4YPA727XT',
'SP1R5B0T65JNE11R59PCG5QTVDBCKBG0K35Q2Q4MF',
'SP1W6GK034ADAWPHNZE1MDA68R7GY7F1V14NQ3NQX',
'SP16RJG4X53JQ981839220E3198ZFG06CW7TPA3D5',
'SP2AYJHP9H3JM3T26ZBW0SKBCXJ9S4JW03VQBP7K1',
'SP1D5V7TQBHP7KR7VC9FTABXNH5XCHZMYSZ0G2CZX',
'SP3NQW36MXVDM1HEEX91KJFRDGBBB78XPH7TKF5KH',
'SP2ZZNY1A490YB8TNV6CP7ZW5AG3R9A2ZH0769TM9',
'SP31F56GFT7VSV6HCEE3NW2XNNRVQ8TRSK8MQABEJ',
'SP2NHZDAMMEEASE4DKHYYCVAG8RF8PA7YHPPW40BX',
'SP1DF301EQC86SK9M0TYD50WTR48A4K261B3ZFQC6',
'SP2EFHG2729NAJ0YAMVKXTQV22HJ5ZW5SC9BVWHJ0',
'SPSX317MQWBPGD6PR343XEV09Y7X6M6DBPP2S1WK',
'SP1K8ZADQJ7GPXBND02S2V3ZCAC8W1YMGVR7DP99P',
'SP4BK08PVYFGEBX3H1CFWTCRGWTRNMQ9FBQZNH0M',
'SP9G4HMV8BBYF06K5TQ15VKMTYQJNJ62MFC70E2A',
'SP1C6T0KAS0CFWZ9WKKJSB8BCCX9J737VWTPTCABN',
'SP71N7X6G8KYGQPHZW7TB4PD1JZ6ND9AESF9JPZ8',
'SP1NYHBF7GNF9CE7P5KB27VZTHK3V8XANTMXNHD2',
'SP1YBE598WP9MQTFEQYBT547QSR0F4BVX65Q5M11X',
'SP2MKX906QA037V1WXTF75A5825DB7FZTZJ8DXPT9',
'SP1W6KTB2478W5M6Q45DRAN5BCAH5PAPQHK4T6JSQ',
'SP3J0Z8YSJD20TGEBE6M992CWFDG18VB0PR599VY9',
'SPN9SQPATME5FN0JGHHGP9MW1WVW26663D5YN2SS',
'SP3CV247X924392Z5SK0DFRVPHV2Y9XTNEE1ACFPZ',
'SP2PTWTSENNT8FZCMZ91W5X6PMK2TBK1CNSDH7KT',
'SP2NGHE6V2DV8SSAY9SDCMBWPFW9REVFRJF9T4C5D',
'SP3HFFPG6NCKN8W0FF4V500HXBY4BT7G84DJPZR66',
'SP2F96FXVT8ZZKSE0T1XKAZ7YKV3AD08X8PQWD8Q0',
'SPQXB5YY3PE0K99Q0KB378SG0N6GG4N9ZWM2ZGHR',
'SP3N56EKWSNN2SQ0WJ8MJGXPFSE9TRGCVSTSFG50J',
'SP1T2BVJJY86G2YETGD8D1X2XBGMNNYDEKREM6G4S',
'SP30QJQ7FT7KWSMN1V468E2767FHC1BQRCKDV97MQ',
'SP36H93H2CWD6JFX19JA9XCTBKDFBMDKCSP4X4Z80',
'SP9XKY1WP621NESM1RZV75E6K3WVVKKQ1Z7YFCFZ',
'SPFKC8AZ0Y5D0PHAB3NC2B7RZ0GD3PDRER170HE8',
'SPDNCXP39TYK3900TZ7WWZ87B8FNJMR6ZF8JEA1C',
'SP1HKNNAJHDSNRQH92FKADE7N54KVQWSAME19QXZV',
'SP2QDBR94GGCZMFF81S1FW3JM5D0212F2VSAXM18M',
'SPYDDJYP8F76DM7X6M0J342MBRV1F99JG2RVP327',
'SP3ACGVXD58D7WCH3TMMC85SMC8KY7HNWKMTR527W',
'SP16YT5H0XFSCMMG996SNXSY9TJHKDE214GMA5JTH',
'SP29DF71KEW6933MG5W2WDE2BWSSVRHPYN9VB5KMT',
'SP2YQ25XYJ2XJXVJE4R46VHQXMVZP6RR4MYAHXNKX',
'SPKDKYWGT1P3DHYVRMAB9T7EZVY7KGN8JTWKED08',
'SP2GEW094DE4XT7D45T9J0W1CFVT1KVSBYAKFCKG6',
'SP1CSP7FJR4TAZADS93NCAYMP5BXW77QDB42Y9SYC',
'SP1AD2B85VBHKJBVZMC8PWT0M85X0RJNBAMPAG739',
'SP3KY4RP48Z0P30N9596TDVC0VWSVB609PF8Z67CW',
'SP3T7WAB5DMJ3JSRMCQF6SC7CG50DYYJVS4C303CN',
'SP2GQ4PEV10EGXMR8ZV1PSS97AX2NMXQ398BZ8YPM',
'SP1555M4HGVK76M1JP7ARKCSCCB6NB9ZTG95AGBJV',
'SP236M6Y96JXAMQ8PK674M31TFRHYWFY62YZ4ZNFF',
'SPYJ9RP13800CBP5FVHTBGD7CZFJPDJYDS62G0XS',
'SP25T77P9V9RC8JN1PPAGPWQYP2QWW92EGW0W7W1A',
]

const fetchSize = 25;
class Theopetra extends Component<TheopetraProps, TheopetraState> {
  state = {
    name: "",
    contractAddress: "",
    contractName: "",
    description: "",
    image: "",
    total: 0,
    saleData: {},
    sale: [],
    lastTokenID: 0,
    lastTokenFetched: false,
    prices: 0,
    currency: undefined,
    active: false,
    paused: false,
    userData: undefined,
    offset: 1
  }

  componentDidMount() {
    try {
      const userData = localStorage.getItem('userData');
      if (userData) {
        this.setState({
          'userData': JSON.parse(userData)
        })
      }
    } catch {}

    darkmodeListener(styles['theme-light'], styles['theme-dark'])
    fetch(makeEndpoint('/api/drop?name=' + this.props.name))
      .then(res => {
          return res.json()
      })
      .then((artistData) => {
        this.setState({
          name: artistData.name,
          contractAddress: artistData.contractAddress,
          contractName: artistData.contractName,
          description: artistData.description,
          image: artistData.image,
          total: artistData.total,
          prices: artistData.prices,
          active: artistData.active,
          paused: artistData.paused,
          currency: artistData.currency,
        }, () => {
          fetch(makeEndpoint('/api/nft/lastTokenID?address='+artistData.contractAddress+'&name='+artistData.contractName))
          .then(res => { return res.text() })
          .then((lastTokenStr) => {
            const lastToken = parseInt(lastTokenStr, 10)
            this.setState({
              lastTokenID: lastToken,
            }, () => {
              this.fetchNFTs()
            })
          })
          fetch(makeEndpoint('/api/nft/lastTokenID?address='+artistData.contractAddress+'&name='+artistData.contractName+'&reload=true'));
        })
    });
  }

  fetchNFTs() {
    var nfts: Array<any> = []
    var numNFTs = Math.min(this.state.lastTokenID, fetchSize)
    if (this.state.lastTokenID < (this.state.offset + numNFTs)) {
      const diff = (this.state.offset + numNFTs) - this.state.lastTokenID
      numNFTs -= diff;
    }

    for (var x = this.state.offset; x <= this.state.offset + numNFTs; x++) {
      nfts.push({
        "contractAddress": this.state.contractAddress,
        "tokenID": x,
        "contractName": this.state.contractName,
        "sold": true
      });
    }

    var sale: Array<any>  = this.state.sale;
    const filtered_nfts = nfts.filter((r: any) => {
        let filtered_list = sale.filter((l: any) => l.contractAddress == r.contractAddress && l.contractName == r.contractName && String(l.tokenID) == String(r.tokenID));
        return filtered_list.length == 0
    })

    sale.push(...filtered_nfts)
    this.setState({
      'sale': sale
    }, () => {
      this.fetchData(nfts)
    })
  }


  getLength() {
    return this.state.sale.length
  }

  fetchData(nfts: any) {
    for (var nft of nfts) {
      var params = '';
      params += 'address=' + nft.contractAddress;
      params += '&name=' + nft.contractName;
      params += '&id=' + nft.tokenID;
      const nftId = nft.contractAddress + '.' + nft.contractName + ':' + nft.tokenID;
      var saleDataRecord: any = this.state.saleData
      if (saleDataRecord[nftId]) {
          continue;
      }
      fetch(makeEndpoint('/api/nft?' + params))
        .then(res => res.json())
        .then((nftData) => {
          if (nftData.name) {
            var saleData = saleDataRecord;
            saleData[nftId] = nftData;
            this.setState({ saleData });
          }
        })
    }
  }

  getTotal() {
    return this.state.total;
  }

  getAvailable() {
    return this.getTotal() - this.state.lastTokenID;
  }

  getVolumeSold() {
    return this.state.lastTokenID * this.getFloorPrice();
  }

  getFloorPrice() {
    if (!this.state.currency) {
      return (this.state.prices / 1e6);
    } else {
      return this.state.prices;
    }
  }

  nFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  renderCard() {
    return this.state.sale.map((x: any) => {
      const saleData: any = this.state.saleData
      const fullData: any = saleData[x.contractAddress + '.' + x.contractName + ':' + x.tokenID]
      if (fullData) {
        return (
          <Card
            renderer={renderer(fullData)}
            collection={fullData.collection || fullData.name}
            name={fullData.name}
            currency={this.state.currency}
            sold={this.state.prices}/>
        )
      }  else if (x.contractName) {
          const titled = x.contractName[0].toUpperCase() + x.contractName.substring(1);
          return (
              <Card
                collection={titled}
                renderer={<Renderer />}
                currency={this.state.currency}
                name={titled + ' #' + x.tokenID}/>
          );
      }
    })
  }


  amount() {
    return this.state.prices
  }

  fiveAmount() {
    return this.state.prices * 5
  }

  tenAmount() {
    return this.state.prices * 10
  }

  mintText() {
    let whitelisted = whitelist.includes(this.stxAddress());

    if (!enable_whitelist) {
      whitelisted = true;
    }

    let mintText;
    if (this.state.active && this.state.paused) {
      mintText = 'MINTING CLOSED';
    } else if (!this.state.active) {
      mintText = 'COMING SOON';
    } else if (!whitelisted) {
      mintText = 'NOT WHITELISTED';
    } else if (this.state.lastTokenID >= this.state.total) {
      mintText = "VISIT MARKETPLACE";
    } else if (this.state.userData) {
      mintText = "MINT"
    } else {
      mintText = "CONNECT WALLET TO MINT"
    }
    return mintText
  }

  renderPrice() {
    let whitelisted = whitelist.includes(this.stxAddress());
    return (!enable_whitelist || whitelisted) && (!((this.state.active && this.state.paused) || (!this.state.active) || (this.state.lastTokenID >= this.state.total))) && (this.state.userData) 
  }

  stxAddress() {
    if (this.state.userData) {
      const userData: any = this.state.userData
      return userData.profile.stxAddress.mainnet;
    } else {
      return null;
    }
  }

  authenticate() {
        showConnect({
            appDetails: {
                'name': 'Stacks NFT',
                'icon': icon,
            },
            redirectTo: '/',
            onFinish: () => {
                let userData = userSession.loadUserData();
                this.setState({ userData: userData });
                localStorage.setItem('userData', JSON.stringify(userData));
                window.location.reload();
            },
            userSession: userSession,
        });
    }

  claim(contractAddress: string, contractName: string, salePrice: number, claimNumber: number) {
        const functionArgs: any[] = [];

        let functionName;
        if (claimNumber == 10) {
            functionName = 'claim-ten';
        } else if (claimNumber == 5) {
            functionName = 'claim-five';
        } else {
            functionName = 'claim';
        }

        let standardFungiblePostCondition;
        if (!this.state.currency) {
            standardFungiblePostCondition = makeStandardSTXPostCondition(
                this.stxAddress(),
                FungibleConditionCode.LessEqual,
                new BigNum(salePrice * (claimNumber ?? 1))
            );
        } else if (this.state.currency == 'MIA') {
            const fungibleAssetInfo = createAssetInfo('SP466FNC0P7JWTNM2R9T199QRZN1MYEDTAR0KP27', 'miamicoin-token', 'miamicoin');

            standardFungiblePostCondition = makeStandardFungiblePostCondition(
                this.stxAddress(),
                FungibleConditionCode.LessEqual,
                salePrice,
                fungibleAssetInfo
            );
        }

        const options: any = {
            contractAddress: contractAddress,
            contractName: contractName,
            functionName: functionName,
            network: mainnet,
            functionArgs,
            postConditions: [standardFungiblePostCondition],
            appDetails: {
                'name': 'Stacks NFT',
                'icon': icon,
            },
            onFinish: (data: any) => {
                // this.setState({ 
                //     success: true,
                //     successTransaction: data.txId,
                // });
            },
            onCancel: () => {
                // this.setState({ 
                //     success: false,
                //     errorMessage: "Transaction Failed"
                // });
            }
        };

        openContractCall(options);
    }

  openClaim(contractAddress: string, contractName: string, salePrice: number, tokenID: number) {
      if (this.state.userData !== undefined) {
          this.claim(contractAddress, contractName, salePrice, tokenID)
      } else {
          this.authenticate()
      }
  }

  mintingBehavior(claimNumber: number) {
    let allowed = whitelist.includes(this.stxAddress())
    if (!enable_whitelist) {
      allowed = true
    }

    if (this.state.contractAddress && this.state.contractName) {
        if (!this.state.active || this.state.paused || (!allowed)) {
            // no op
        } else if (this.state.lastTokenID >= this.state.total) {
            window.location.href = '/marketplace/' + this.state.contractName
        } else {
            this.openClaim(this.state.contractAddress, this.state.contractName, this.state.prices, claimNumber)
        }
    }
  }

  mintButton() {
    if (this.amount() >= 0 && this.renderPrice()) {
      return (
        <div className={styles.MintButton}>
          <Button
            text={this.mintText()}
            price={this.amount()}
            currency={this.state.currency}
            onClick={this.mintingBehavior.bind(this, 1)}/>
        </div>
      )
    } else {
      return (
        <div className={styles.MintButton}>
          <Button text={this.mintText()}
                  onClick={this.mintingBehavior.bind(this, 1)}/>
        </div>
      )
    }
  }

  nextBlocks(offset: number) {
    this.setState({
      'offset': offset
    }, () => {
      this.fetchNFTs()
    })
  }

  getBlocksNext() {
    this.nextBlocks(this.getLength())
  }

  hasMore() {
    return this.state.lastTokenID > this.state.offset
  }

  render() {
    return (
      <div className={styles.Collections}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.name ?? 'Collection'} | STXNFT</title>
          <meta name="description" content={this.state.description.slice(0, 160) ?? 'The best place to find, collect, and sell NFTs secured by Bitcoin. STXNFT is the first open marketplace for Stacks NFTs, with the lowest commission anywhere.'} />
        </Helmet>
        <div className={styles.MintTopBar}>
          <div className={styles.MintImageBorder}>
            <div className={styles.MintImage}>
              <img
                src={this.state.image}
                height={122}
                width={122}
                className={styles.MintImageSrc}/>
            </div>
          </div>
        </div>
        <div className={styles.MintContent}>
          <div className={styles.MintTitle}>
            {this.state.name}
          </div>
          <div className={styles.MintSummary} dangerouslySetInnerHTML={{__html: this.state.description}}>
          </div>
          {this.mintButton()}
          <div className={styles.MintPrice}>
            <div className={styles.MintPriceContainer}>
              <div>
                { this.getTotal().toLocaleString('us') }
              </div>
              <div className={styles.MintPriceTitle}>
                TOTAL
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div>
                { this.getAvailable().toLocaleString('us') }
              </div>
              <div className={styles.MintPriceTitle}>
                REMAINING
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div className={styles.MintPriceIconContainer}>
                {!this.state.currency &&
                  <div className={styles.MintPriceIcon}>
                    <svg width={"15px"} height={"18px"} viewBox={"0 0 15 18"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
                      <g stroke={"none"} stroke-width={"1"} fill={"none"} fill-rule={"evenodd"}>
                        <g transform={"translate(-889.000000, -497.000000)"} stroke={"#707071"}>
                          <g transform={"translate(528.000000, 492.000000)"}>
                            <g transform={"translate(361.000000, 6.000000)"}>
                              <g>
                                <line x1={"3.84615385"} y1={"6.58823529"} x2={"13.0769231"} y2={"6.58823529"}></line>
                                <line x1={"-9.60769925e-17"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"1.53846154"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"13.0769231"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"14.6153846"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"2.30769231"} y1={"0.411764706"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"}></line>
                                <line x1={"8.46153846"} y1={"0.411764706"} x2={"12.3076923"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"} transform={"translate(10.384615, 3.294118) scale(-1, 1) translate(-10.384615, -3.294118) "}></line>
                              </g>
                              <g transform={"translate(7.500000, 12.500000) scale(1, -1) translate(-7.500000, -12.500000) translate(0.000000, 9.000000)"}>
                                <line x1={"3.84615385"} y1={"6.58823529"} x2={"13.0769231"} y2={"6.58823529"}></line>
                                <line x1={"-9.60769925e-17"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"1.53846154"} y1={"6.17647059"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"13.0769231"} y2={"6.17647059"} stroke-width={"2"} stroke-linecap={"round"}></line>
                                <line x1={"8.46153846"} y1={"6.17647059"} x2={"14.6153846"} y2={"6.17647059"} stroke-width={"2"}></line>
                                <line x1={"2.30769231"} y1={"0.411764706"} x2={"6.15384615"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"}></line>
                                <line x1={"8.46153846"} y1={"0.411764706"} x2={"12.3076923"} y2={"6.17647059"} stroke-width={"2"} stroke-linejoin={"round"} transform={"translate(10.384615, 3.294118) scale(-1, 1) translate(-10.384615, -3.294118) "}></line>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                }
                <div>
                  { this.getFloorPrice().toLocaleString('us') }
                </div>
                {this.state.currency == 'MIA' &&
                  <div className={styles.MintPriceText}>
                    MIA
                  </div>
                }
              </div>
              <div className={styles.MintPriceTitle}>
                MINT PRICE
              </div>
            </div>
            <div className={styles.MintPriceContainer}>
              <div className={styles.MintPriceVolumeContainer}>
                { this.nFormatter(this.getVolumeSold(), 3) }
                {this.state.currency == 'MIA' &&
                  <div className={styles.MintPriceText}>
                    MIA
                  </div>
                }
              </div>
              <div className={styles.MintPriceTitle}>
                VOLUME SOLD
              </div>
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={this.getLength()}
          next={this.getBlocksNext.bind(this)}
          hasMore={this.hasMore()}
          loader={<Loading />}
          className={styles.MintPriceCardContainer}
          scrollableTarget={"page-wrapper"}>
          {this.renderCard()}
        </InfiniteScroll>
      </div>
    )
  }
}

export default Theopetra

import React, { Component } from 'react'
import styles from './index.module.scss'

import Trait from './Trait'
import Actions from '../Front/Actions'

import { darkmodeListener } from '../../../theme';

type BackProps = {
  bid?: number
  buy?: number
  sold?: number
  rarityRank?: number
  attributes?: Record<string, any>
  bidFunction?: (...args: any[]) => void
  buyFunction?: (...args: any[]) => void
  sendFunction?: (...args: any[]) => void
  sellFunction?: (...args: any[]) => void
  backAction: (...args: any[]) => void
  link: (...args: any[]) => void
  bidStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  buyStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  sellStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
  sendStyle?: 'PRIMARY' | 'SECONDARY' | 'ERROR'
}
type BackState = {}

class Back extends Component<BackProps, BackState> {
  static defaultProps = {
    backAction: () => {},
    link: () => {}
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    var totalInCollection;
    if (this.props.attributes && this.props.attributes.length) {
      totalInCollection = this.props.attributes[0].total;
    }
    return (
      <div className={styles.Back}>
        <div className={styles.SelectorContainer}>
          <div
            className={styles.Selector}
            onClick={this.props.backAction.bind(this)}>
            <svg width={"16px"} height={"16px"} viewBox={"0 0 16 16"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
              <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                <g transform={"translate(-397.000000, -506.000000)"} strokeWidth={"2"}>
                  <g transform={"translate(398.000000, 507.000000)"}>
                    <line x1={"14"} y1={"7"} x2={"0"} y2={"7"}></line>
                    <polyline points={"7 14 0 7 7 0"}></polyline>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div
            className={styles.Selector}
            onClick={this.props.link.bind(this)}>
            <svg width={"24px"} height={"12px"} viewBox={"0 0 24 12"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
              <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                <g transform={"translate(-287.000000, -957.000000)"} strokeWidth={"2"}>
                  <g transform={"translate(288.000000, 958.000000)"}>
                    <path d={"M14,8.8817842e-16 L17,8.8817842e-16 C18.3260824,8.8817842e-16 19.597852,0.526784202 20.5355339,1.46446609 C21.4732158,2.40214799 22,3.67391755 22,5 C22,7.76142375 19.7614237,10 17,10 L14,10 M8,10 L5,10 C3.67391755,10 2.40214799,9.4732158 1.46446609,8.53553391 C0.526784202,7.59785201 0,6.32608245 0,5 C0,2.23857625 2.23857625,2.66453526e-15 5,2.66453526e-15 L8,2.66453526e-15"}></path>
                    <line x1={"7"} y1={"5"} x2={"15"} y2={"5"}></line>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className={styles.TraitsContainer}>
        {
          this.props.rarityRank ? (
            <Trait
              type="STXNFT Rarity Rank"
              collection={this.props.rarityRank + (totalInCollection ? '/' + totalInCollection : '')}/>
          ) : ''
        }
        {this.props.attributes && 
          this.props.attributes.filter((x: any) => ((x.trait_type != undefined || x.type != undefined || x.trait != undefined )&& x.value != undefined)).map((x: any) =>
            <div>
              {
                <Trait
                  type={x.trait_type || x.type || x.trait}
                  rarity={x.percentage}
                  collection={x.value}/>
              }
            </div>
          )
        }
        </div>
        <div className={styles.TraitsActionContainer}>
          <Actions
            bid={this.props.bid}
            bidFunction={this.props.bidFunction}
            buy={this.props.buy}
            buyFunction={this.props.buyFunction}
            sendFunction={this.props.sendFunction}
            sellFunction={this.props.sellFunction}
            bidStyle={this.props.bidStyle}
            buyStyle={this.props.buyStyle}
            sellStyle={this.props.sellStyle}
            sendStyle={this.props.sendStyle}/>
        </div>
      </div>
    )
  }
}

export default Back;

import React, { Component } from 'react'
import styles from './index.module.scss'

import logo from './assets/logo.svg'
import halloween_logo from './assets/halloween-logo.svg'

import Button from '../Button';
import MenuItem from '../MenuItem'
import Login from '../Login'

import { darkmodeListener } from '../../theme';

import { renderPrice } from '../../utils'

type TopBarProps = {
  stats: any
}

type TopBarState = {
  logo: any
  userData: any
}

class TopBar extends Component<TopBarProps, TopBarState> {
  state = {
    logo: logo,
    userData: undefined
  }

  componentDidMount() {
    const theme_mode =
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 1
        : 0;

    if (theme_mode) {
      this.setState({
        logo: halloween_logo
      })
    } else {
      this.setState({
        logo: logo
      })
    }

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        if (e.matches) {
          this.setState({
            logo: halloween_logo
          })
        } else {
          this.setState({
            logo: logo
          })
        }
      });

    darkmodeListener(styles['theme-light'], styles['theme-dark']);

    try {
      const userData = localStorage.getItem('userData')
      if(userData) {
        this.setState({'userData': JSON.parse(userData)});
      }
    } catch {}
  }

  gotoHome() {
    window.location.href = '/'
  }

  gotoProfile() {
    if (this.state.userData) {
      const userData: any = this.state.userData;
      const stxAddress = userData.profile.stxAddress.mainnet;
      window.location.href = '/' + stxAddress;
    }
  }

  gotoMarketplace() {
    window.location.href = '/marketplace'
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  render() {
    return (
      <div className={styles.TopBar}>
        <div
          className={styles.Logo}
          onClick={this.gotoHome.bind(this)}>
          <img src={this.state.logo} />
        </div>

        {this.props.stats &&
          <div className={styles.TopSpacerM}>
            <div className={styles.TopSpacerMobile}>
              <div className={styles.TopSpacerHint}>
                FLOOR PRICE
              </div>
              <div className={styles.TopSpacerText}>
                <div className={styles.PriceSymbol}>
                  <svg
                    width={'21px'}
                    height={'21px'}
                    viewBox={'0 0 21 21'}
                    version={'1.1'}
                    xmlns={'http://www.w3.org/2000/svg'}
                  >
                    <g strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
                      <g transform={'translate(-398.000000, -268.000000)'}>
                        <g transform={'translate(399.000000, 269.000000)'}>
                          <g>
                            <line x1={'5'} y1={'7.5'} x2={'17'} y2={'7.5'}></line>
                            <line
                              x1={'-1.2490009e-16'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth="2"
                            ></line>
                            <line
                              x1={'2'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'17'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'19'}
                              y2={'7'}
                              strokeWidth={'2'}
                            ></line>
                            <line
                              x1={'3'}
                              y1={'0'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'0'}
                              x2={'16'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                              transform={
                                'translate(13.500000, 3.500000) scale(-1, 1) translate(-13.500000, -3.500000) '
                              }
                            ></line>
                          </g>
                          <g
                            transform={
                              'translate(9.500000, 14.500000) scale(-1, 1) rotate(-180.000000) translate(-9.500000, -14.500000) translate(0.000000, 10.500000)'
                            }
                          >
                            <line x1={'5'} y1={'7.5'} x2={'17'} y2={'7.5'}></line>
                            <line
                              x1={'-1.2490009e-16'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth="2"
                            ></line>
                            <line
                              x1={'2'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'17'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'19'}
                              y2={'7'}
                              strokeWidth={'2'}
                            ></line>
                            <line
                              x1={'3'}
                              y1={'0'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'0'}
                              x2={'16'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                              transform={
                                'translate(13.500000, 3.500000) scale(-1, 1) translate(-13.500000, -3.500000) '
                              }
                            ></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                {renderPrice(this.props.stats.floor)}
              </div>
            </div>
          </div>
        }
        {this.props.stats &&
          <div className={styles.TopSpacer}>
            <div className={styles.TopSpacerContainer}>
              <div className={styles.TopSpacerHint}>
                AVAILABLE
              </div>
              <div className={styles.TopSpacerText}>
                {this.props.stats.number}
              </div>
            </div>
            <div className={styles.TopSpacerContainer}>
              <div className={styles.TopSpacerHint}>
                FLOOR PRICE
              </div>
              <div className={styles.TopSpacerText}>
                <div className={styles.PriceSymbol}>
                  <svg
                    width={'21px'}
                    height={'21px'}
                    viewBox={'0 0 21 21'}
                    version={'1.1'}
                    xmlns={'http://www.w3.org/2000/svg'}
                  >
                    <g strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
                      <g transform={'translate(-398.000000, -268.000000)'}>
                        <g transform={'translate(399.000000, 269.000000)'}>
                          <g>
                            <line x1={'5'} y1={'7.5'} x2={'17'} y2={'7.5'}></line>
                            <line
                              x1={'-1.2490009e-16'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth="2"
                            ></line>
                            <line
                              x1={'2'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'17'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'19'}
                              y2={'7'}
                              strokeWidth={'2'}
                            ></line>
                            <line
                              x1={'3'}
                              y1={'0'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'0'}
                              x2={'16'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                              transform={
                                'translate(13.500000, 3.500000) scale(-1, 1) translate(-13.500000, -3.500000) '
                              }
                            ></line>
                          </g>
                          <g
                            transform={
                              'translate(9.500000, 14.500000) scale(-1, 1) rotate(-180.000000) translate(-9.500000, -14.500000) translate(0.000000, 10.500000)'
                            }
                          >
                            <line x1={'5'} y1={'7.5'} x2={'17'} y2={'7.5'}></line>
                            <line
                              x1={'-1.2490009e-16'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth="2"
                            ></line>
                            <line
                              x1={'2'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'17'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'19'}
                              y2={'7'}
                              strokeWidth={'2'}
                            ></line>
                            <line
                              x1={'3'}
                              y1={'0'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'0'}
                              x2={'16'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                              transform={
                                'translate(13.500000, 3.500000) scale(-1, 1) translate(-13.500000, -3.500000) '
                              }
                            ></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                {renderPrice(this.props.stats.floor)}
              </div>
            </div>
            <div className={styles.TopSpacerContainer}>
              <div className={styles.TopSpacerHint}>
                VOLUME SOLD
              </div>
              <div className={styles.TopSpacerText}>
                <div className={styles.PriceSymbol}>
                  <svg
                    width={'21px'}
                    height={'21px'}
                    viewBox={'0 0 21 21'}
                    version={'1.1'}
                    xmlns={'http://www.w3.org/2000/svg'}
                  >
                    <g strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
                      <g transform={'translate(-398.000000, -268.000000)'}>
                        <g transform={'translate(399.000000, 269.000000)'}>
                          <g>
                            <line x1={'5'} y1={'7.5'} x2={'17'} y2={'7.5'}></line>
                            <line
                              x1={'-1.2490009e-16'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth="2"
                            ></line>
                            <line
                              x1={'2'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'17'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'19'}
                              y2={'7'}
                              strokeWidth={'2'}
                            ></line>
                            <line
                              x1={'3'}
                              y1={'0'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'0'}
                              x2={'16'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                              transform={
                                'translate(13.500000, 3.500000) scale(-1, 1) translate(-13.500000, -3.500000) '
                              }
                            ></line>
                          </g>
                          <g
                            transform={
                              'translate(9.500000, 14.500000) scale(-1, 1) rotate(-180.000000) translate(-9.500000, -14.500000) translate(0.000000, 10.500000)'
                            }
                          >
                            <line x1={'5'} y1={'7.5'} x2={'17'} y2={'7.5'}></line>
                            <line
                              x1={'-1.2490009e-16'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth="2"
                            ></line>
                            <line
                              x1={'2'}
                              y1={'7'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'17'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinecap={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'7'}
                              x2={'19'}
                              y2={'7'}
                              strokeWidth={'2'}
                            ></line>
                            <line
                              x1={'3'}
                              y1={'0'}
                              x2={'8'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                            ></line>
                            <line
                              x1={'11'}
                              y1={'0'}
                              x2={'16'}
                              y2={'7'}
                              strokeWidth={'2'}
                              strokeLinejoin={'round'}
                              transform={
                                'translate(13.500000, 3.500000) scale(-1, 1) translate(-13.500000, -3.500000) '
                              }
                            ></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                {renderPrice(this.props.stats.volume)}
              </div>
            </div>
          </div>
        }

        {!this.props.stats &&
          <div className={styles.TopSpacer}>
          </div>
        }
        
        <MenuItem
          type={'marketplace'}
          onClick={this.gotoMarketplace.bind(this)}/>
        { this.state.userData != undefined &&
          <MenuItem
            type={'profile'}
            onClick={this.gotoProfile.bind(this)}/>
        }
        { this.state.userData != undefined &&
          <MenuItem
            type={'logout'}
            onClick={this.logout.bind(this)}/>
        }
        { this.state.userData == undefined &&
          <Login />
        }
      </div>
    )
  }
}

export default TopBar;
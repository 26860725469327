import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

type ErrorPageProps = {}
type ErrorPageState = {}

class ErrorPage extends Component<ErrorPageProps, ErrorPageState> {
  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark'])
  }

  render() {
    return (
      <div className={styles.ErrorPage}>
        <div>
          <svg width="323px" height="311px" viewBox="0 0 323 311" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-402.000000, -253.000000)" stroke="#979797">
                <g transform="translate(402.000000, 254.000000)">
                  <g transform="translate(6.000000, 0.000000)">
                    <rect x="0" y="0" width="308" height="308" rx="39"></rect>
                    <g transform="translate(90.000000, 88.000000)" fillRule="nonzero">
                      <polygon points="92.463456 90.3563411 120.715957 133 99.610197 133 66.4440123 82.8965518 33.2778276 133 12.283777 133 40.536411 90.4677307 0 90.4677307 0 74.3235294 133 74.3235294 133 90.3563411"></polygon>
                      <polygon points="133 42.3276944 133 58.56451 133 58.6764706 0 58.6764706 0 42.3276944 39.7547113 42.3276944 11.8370725 0 32.9428324 0 66.4440123 51.061952 100.057035 0 121.162795 0 93.2447567 42.3276944"></polygon>
                    </g>
                  </g>
                  <circle cx="45" cy="269" r="38.5"></circle>
                  <circle cx="275" cy="269" r="38.5"></circle>
                  <circle cx="275" cy="39" r="38.5"></circle>
                  <circle cx="45" cy="39" r="38.5"></circle>
                  <rect x="46.5" y="39.5" width="229" height="229"></rect>
                  <line x1="7.5" y1="308.5" x2="315.5" y2="0.5" strokeLinecap="square"></line>
                  <line x1="7.5" y1="308.5" x2="315.5" y2="0.5" strokeLinecap="square" transform="translate(161.500000, 154.500000) scale(-1, 1) translate(-161.500000, -154.500000) "></line>
                  <line x1="0.5" y1="154.5" x2="322.5" y2="154.5" strokeLinecap="square"></line>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={styles.ErrorPageText}>
          SORRY! THIS PAGE DOESN'T EXIST.
        </div>
      </div>
    )
  }
}

export default ErrorPage;
import BigNum from 'bn.js'
import { openContractCall } from '@stacks/connect';
import { uintCV, intCV, bufferCV, stringAsciiCV, stringUtf8CV, contractPrincipalCV, standardPrincipalCV, trueCV, makeStandardNonFungiblePostCondition, makeStandardSTXPostCondition, NonFungibleConditionCode, FungibleConditionCode, createAssetInfo, makeContractNonFungiblePostCondition } from '@stacks/transactions';
import { StacksTestnet, StacksMainnet } from '@stacks/network';

import title from './components/Login/assets/icon.svg'

const testnet = new StacksTestnet();
const mainnet = new StacksMainnet();

const megapontArguments = [
  { 
    'name': 'id',
    'value': '{id}',
    'type': uintCV,
  },
  { 
    'name': 'price',
    'value': '{price}',
    'type': uintCV,
  },
  { 
    'name': 'comm',
    'value': 'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.commission-robot-factory-stxnft',
    'type': contractPrincipalCV,
  },
]

const marketplaces: Record<string, any> = {
    'default': {
        address: 'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S',
        name: 'marketplace-v4'
    },
    'SP497E7RX3233ATBS2AB9G4WTHB63X5PBSP5VGAQ.boom-nfts': {
        address: 'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S',
        name: 'marketplace-v2-alt'
    },
    'SP176ZMV706NZGDDX8VSQRGMB7QN33BBDVZ6BMNHD.project-indigo-act1': {
        address: 'SP176ZMV706NZGDDX8VSQRGMB7QN33BBDVZ6BMNHD',
        name: 'project-indigo-act1',
        function: 'list-in-ustx',
        noncustodial: true,
        args: [
          { 
            'name': 'id',
            'value': '{id}',
            'type': uintCV,
          },
          { 
            'name': 'price',
            'value': '{price}',
            'type': uintCV,
          },
          { 
            'name': 'comm',
            'value': 'SP176ZMV706NZGDDX8VSQRGMB7QN33BBDVZ6BMNHD.commission-stxnft-v3',
            'type': contractPrincipalCV,
          },
        ],
    },
    'SP3QSAJQ4EA8WXEDSRRKMZZ29NH91VZ6C5X88FGZQ.crashpunks-v2': {
        address: 'SP3QSAJQ4EA8WXEDSRRKMZZ29NH91VZ6C5X88FGZQ',
        name: 'crashpunks-v2',
        function: 'list-in-ustx',
        noncustodial: true,
        args: [
          { 
            'name': 'id',
            'value': '{id}',
            'type': uintCV,
          },
          { 
            'name': 'price',
            'value': '{price}',
            'type': uintCV,
          },
          { 
            'name': 'comm',
            'value': 'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.crashpunks-v2-stxnft-commission',
            'type': contractPrincipalCV,
          },
        ],
    },
  'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.megapont-ape-club-nft': {
      address: 'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335',
      name: 'megapont-ape-club-nft',
      function: 'list-in-ustx',
      noncustodial: true,
      args: megapontArguments,
  },
  'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.megapont-robot-nft': {
      address: 'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335',
      name: 'megapont-robot-nft',
      function: 'list-in-ustx',
      noncustodial: true,
      args: megapontArguments,
  },
  'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335.megapont-robot-component-nft': {
      address: 'SP3D6PV2ACBPEKYJTCMH7HEN02KP87QSP8KTEH335',
      name: 'megapont-robot-component-nft',
      function: 'list-in-ustx',
      noncustodial: true,
      args: megapontArguments,
  },
}

function transfer(contractAddress: string, contractName: string, assetName: string, tokenID: string, from: string, to: string, onFinish: any, onCancel: any) {
    const functionArgs = [
        uintCV(tokenID),
        standardPrincipalCV(from),
        standardPrincipalCV(to),
    ];
    const nonFungibleAssetInfo = createAssetInfo(
        contractAddress,
        contractName,
        assetName,
    )
    const postCondition = makeStandardNonFungiblePostCondition(
        from,
        NonFungibleConditionCode.DoesNotOwn,
        nonFungibleAssetInfo,
        uintCV(tokenID)
    )
    const options: any = {
        contractAddress: contractAddress,
        contractName: contractName,
        functionName: 'transfer',
        network: mainnet,
        functionArgs,
        postConditions: [postCondition],
        appDetails: {
            name: 'Stacks NFT',
            icon: window.location.origin + title,
        },
        onFinish: onFinish,
        onCancel: onCancel,    
    };

    openContractCall(options);
}

function listAsset(contractAddress: string, contractName: string, assetName: any, tokenID: any, price: any, commission: any, address: any, onFinish: any, onCancel: any) {
    const marketplace: any = marketplaces[contractAddress + '.' + contractName] ?? marketplaces.default;
    var functionArgs = [
        contractPrincipalCV(contractAddress, contractName),
        uintCV(tokenID),
        uintCV(price),
        uintCV(commission),
    ];

    if (marketplace.args) {
      const params = {
        price,
        id: tokenID,
      }
      functionArgs = marketplace.args.map((x: any) => {
        var replaced = replaceArgument(x.value, params);

        let typed;
        if (x.type == contractPrincipalCV) {
          var contractAddress = replaced.split('.')[0];
          var contractName = replaced.split('.')[1];
          typed = x.type(contractAddress, contractName);
        } else {
          typed = x.type(replaced);
        }
        return typed;
      })
    }

    const nonFungibleAssetInfo = createAssetInfo(
        contractAddress,
        contractName,
        assetName,
    )
    const postCondition = makeStandardNonFungiblePostCondition(
        address,
        NonFungibleConditionCode.DoesNotOwn,
        nonFungibleAssetInfo,
        uintCV(tokenID)
    )

    const options: any = {
        contractAddress: marketplace.address,
        contractName: marketplace.name,
        functionName: marketplace.function ?? 'list-asset',
        network: mainnet,
        functionArgs,
        postConditions: marketplace.noncustodial ? [] : [postCondition],
        appDetails: {
            name: 'Stacks NFT',
            icon: window.location.origin + title,
        },
        onFinish: onFinish,
        onCancel: onCancel,
    };

    openContractCall(options);
}

function unlistAsset(nft: any, onFinish: any, onCancel: any) {
  const nonFungibleAssetInfo = createAssetInfo(
    nft.contractAddress,
    nft.contractName,
    nft.assetName,
  )
  const nftContractPostCondition = makeContractNonFungiblePostCondition(
    nft.marketplace.address, 
    nft.marketplace.name,
    NonFungibleConditionCode.DoesNotOwn,
    nonFungibleAssetInfo,
    uintCV(nft.tokenID)
  )

  // See which marketplace the NFT is from
  let functionName;
  let functionArgs;
  var postConditions: any[];
  if (nft.marketplace.name.includes('marketplace-v')) {
    functionName = 'unlist-asset';
    functionArgs = [
      contractPrincipalCV(nft.contractAddress, nft.contractName),
      uintCV(nft.tokenID),
    ];
    postConditions = [nftContractPostCondition];
  } else if (nft.marketplace.name.includes('byzantion-market-v5')) {
    functionName = 'unlist-item';
    functionArgs = [
      contractPrincipalCV(nft.contractAddress, nft.contractName),
      stringAsciiCV(nft.additional.collectionID.replaceAll('"', '')),
      uintCV(nft.tokenID),
    ];
    postConditions = [nftContractPostCondition];
  } else if (nft.marketplace.name.includes('stacks-art-') || nft.marketplace.name.includes('byzantion-market-v2')) {
    functionName = 'unlist-item';
    functionArgs = [
      contractPrincipalCV(nft.contractAddress, nft.contractName),
      uintCV(parseInt(nft.additional.collectionID.slice(1))),
      uintCV(nft.tokenID),
    ];
    postConditions = [nftContractPostCondition];
  } else {
    functionName = 'unlist-in-ustx';
    functionArgs = [
      uintCV(nft.tokenID),
    ];
    postConditions = [];
  }

  const options: any = {
    contractAddress: nft.marketplace.address,
    contractName: nft.marketplace.name,
    functionName: functionName,
    network: mainnet,
    functionArgs,
    postConditions: postConditions,
    appDetails: {
      name: 'Stacks NFT',
      icon: window.location.origin + title,
    },
    onFinish: onFinish,
    onCancel: onCancel,
  };

  openContractCall(options);
}

function purchaseAsset(nft: any, address: any, onFinish: any, onCancel: any) {
  // See which marketplace the NFT is from
  const nonFungibleAssetInfo = createAssetInfo(
    nft.contractAddress,
    nft.contractName,
    nft.assetName,
  )
  const nftPostCondition = makeStandardNonFungiblePostCondition(
    address,
    NonFungibleConditionCode.Owns,
    nonFungibleAssetInfo,
    uintCV(nft.tokenID)
  )
  const nftContractPostCondition = makeContractNonFungiblePostCondition(
    nft.marketplace.address, 
    nft.marketplace.name,
    NonFungibleConditionCode.DoesNotOwn,
    nonFungibleAssetInfo,
    uintCV(nft.tokenID)
  )
  const stxPostCondition = makeStandardSTXPostCondition(
    address,
    FungibleConditionCode.LessEqual,
    new BigNum(nft.listing.price)
  );
  let functionName;
  let functionArgs;
  var postConditions;
  if (nft.marketplace.name.includes('marketplace-v')) {
    functionName = 'purchase-asset';
    functionArgs = [
      contractPrincipalCV(nft.contractAddress, nft.contractName),
      uintCV(nft.tokenID),
    ];
    postConditions = [nftPostCondition, nftContractPostCondition, stxPostCondition];
  } else if (nft.marketplace.name.includes('byzantion-market-v5')) {
      console.log(nft);
    functionName = 'buy-item';
    functionArgs = [
      contractPrincipalCV(nft.contractAddress, nft.contractName),
      stringAsciiCV(nft.additional.collectionID.replaceAll('"', '')),
      uintCV(nft.tokenID),
    ];
    postConditions = [nftPostCondition, nftContractPostCondition, stxPostCondition];
  } else if (nft.marketplace.name.includes('stacks-art-') || nft.marketplace.name.includes('byzantion')) {
    functionName = 'buy-item';
    functionArgs = [
      contractPrincipalCV(nft.contractAddress, nft.contractName),
      uintCV(parseInt(nft.additional.collectionID.slice(1))),
      uintCV(nft.tokenID),
    ];
    postConditions = [nftPostCondition, nftContractPostCondition, stxPostCondition];
  } else if (nft.marketplace.name.includes('megapont') || nft.marketplace.name.includes('indigo') || nft.marketplace.name.includes('crashpunks-v2')) {
    functionName = 'buy-in-ustx';
    const nftTransferPostCondition = makeStandardNonFungiblePostCondition(
      nft.listing.owner,
      NonFungibleConditionCode.DoesNotOwn,
      nonFungibleAssetInfo,
      uintCV(nft.tokenID)
    )
    const commissionTraitAddress = nft.additional.commissionTrait.split('.')[0];
    const commissionTraitName = nft.additional.commissionTrait.split('.')[1];
    functionArgs = [
      uintCV(nft.tokenID),
      contractPrincipalCV(commissionTraitAddress, commissionTraitName),
    ];
    postConditions = [stxPostCondition, nftTransferPostCondition];
  }

  const options: any = {
    contractAddress: nft.marketplace.address,
    contractName: nft.marketplace.name,
    functionName: functionName,
    network: mainnet,
    functionArgs,
    postConditions: postConditions,
    appDetails: {
      name: 'Stacks NFT',
      icon: window.location.origin + title,
    },
    onFinish: onFinish,
    onCancel: onCancel,
  };

  openContractCall(options);
}

function replaceArgument(s: string, params: any) {
  for (var param in params) {
    if (s.includes(`{${param}}`)) {
      return s.replace(`{${param}}`, params[param])
    }
  }
  return s
}

export {
    transfer,
    listAsset,
    unlistAsset,
    purchaseAsset,
}

import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

type DropdownProps = {
  selected?: string
  rarity?: boolean
  setSelected: (selected: string) => void
}

type DropdownState = {
  items: Array<string>
  open: boolean
  selected: number
}

class Dropdown extends Component<DropdownProps, DropdownState> {
  state = {
    open: false,
    selected: 0,
    items: [
      "PRICE: LOW TO HIGH",
      "PRICE: HIGH TO LOW",
      "RECENTLY LISTED",
      "RECENTLY SOLD",
      "OLDEST",
      "RARITY: LOW TO HIGH",
      "RARITY: HIGH TO LOW",
    ]
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.selected !== prevProps.selected) {
      if (this.props.selected) {
        for (var idx = 0; idx < this.state.items.length; idx++) {
          var selected_element = this.state.items[idx]
          if (selected_element == this.props.selected) {
            this.setState({
              selected: idx
            });
            break
          }
        }
      }
    }
  }

  toggleOpen() {
    this.setState({
      open: !this.state.open
    })
  }

  setSelected(idx: number) {
    this.setState({
      selected: idx
    }, () => {
      this.props.setSelected(this.state.items[idx]!)
    })
  }

  renderDropdown() {
    if (this.state.open && !this.props.rarity) {
      return `${styles.DropdownFiter} ${styles.DropdownFiterOpen}`
    } else if (this.state.open && this.props.rarity) {
      return `${styles.DropdownFiter} ${styles.DropdownFiterOpenWithRarity}`
    } else {
      return `${styles.DropdownFiter} ${styles.DropdownFiterClosed}`
    }
  }

  render() {
    return (
      <div className={styles.Dropdown}>
        <div className={this.renderDropdown()}
             onClick={this.toggleOpen.bind(this)}>
          <div className={styles.DropdownFiterText}>
            { this.state.open &&
              this.state.items.map((x, idx) => 
                  <div
                    className={styles.DropdownSelected}
                    onClick={this.setSelected.bind(this, idx)}>
                    {x}
                  </div>
              )
            }
            { !this.state.open &&
              <div className={styles.DropdownDefault}>
                {this.state.items[this.state.selected]}
              </div>
            }
          </div>
          { !this.state.open &&
            <div className={styles.DropdownFiterCarretContainer}>
              <div className={styles.DropdownFiterCarret}>
                <svg width={"8px"} height={"14px"} viewBox={"0 0 8 14"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
                  <g stroke={"none"} strokeWidth={"1"} fill={"none"} fill-rule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                    <g transform={"translate(-426.000000, -200.000000)"} stroke={"#999999"} strokeWidth={"2"}>
                      <g transform={"translate(20.000000, 165.000000)"}>
                        <g transform={"translate(410.000000, 42.000000) rotate(180.000000) translate(-410.000000, -42.000000) translate(407.000000, 36.000000)"}>
                          <polyline points={"0 12 6 6 0 0"}></polyline>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

// { this.state.items[this.state.selected] }

export default Dropdown

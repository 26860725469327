import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../../theme';

import { convertIPFS } from '../../../utils'

type CollectionElementProps = {
  text: string
  image?: string
  active: boolean
  verified: boolean
  onClick: (...args: any[]) => void
}

type CollectionElementState = {}

class CollectionElement extends Component<CollectionElementProps, CollectionElementState> {
  static defaultProps = {
    active: false,
    verified: false
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  renderActive() {
    if(this.props.active) {
      return `${styles.CollectionElementIndividual} ${styles.Active}`
    } else {
      return `${styles.CollectionElementIndividual} ${styles.Inactive}`
    }
  }

  render() {
    return (
      <div
        className={this.renderActive()}
        onClick={this.props.onClick.bind(this)}>
        <div className={styles.CollectionElementIcon}>
        {this.props.image &&
          <img className={styles.CollectionElementIconSrc} src={convertIPFS(this.props.image)} />
        }
        </div>
        <div className={styles.CollectionElementText}>
          {this.props.text ? this.props.text.toUpperCase() : ''}
        </div>
        {this.props.verified &&
          <div className={styles.CollectionElementVerified}>
            <svg width={"14px"} height={"14px"} viewBox={"0 0 14 14"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
              <g stroke={"none"} strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
                <g transform={"translate(-622.000000, -517.000000)"}>
                  <g transform={"translate(501.000000, 165.000000)"}>
                    <g transform={"translate(121.000000, 352.000000)"}>
                      <circle fill={"#5AC8FA"} cx={"7"} cy={"7"} r={"7"}></circle>
                      <g transform={"translate(4.000000, 5.000000)"} stroke={"#FFFFFF"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                        <polyline points={"6 0 1.875 4 0 2.18181818"}></polyline>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        }
      </div>
    )
  }
}

export default  CollectionElement

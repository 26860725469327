import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../../theme';

type SelectProps = {
  link?: (...args: any[]) => void
  profile?: (...args: any[]) => void
}

type SelectState = {}

class Select extends Component<SelectProps, SelectState> {
  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <div className={styles.Select}>
        { this.props.link &&
          <div className={styles.Selector} onClick={this.props.link.bind(this)}>
            <svg width={"24px"} height={"12px"} viewBox={"0 0 24 12"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
              <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                <g transform={"translate(-287.000000, -957.000000)"} strokeWidth={"2"}>
                  <g transform={"translate(288.000000, 958.000000)"}>
                    <path d={"M14,8.8817842e-16 L17,8.8817842e-16 C18.3260824,8.8817842e-16 19.597852,0.526784202 20.5355339,1.46446609 C21.4732158,2.40214799 22,3.67391755 22,5 C22,7.76142375 19.7614237,10 17,10 L14,10 M8,10 L5,10 C3.67391755,10 2.40214799,9.4732158 1.46446609,8.53553391 C0.526784202,7.59785201 0,6.32608245 0,5 C0,2.23857625 2.23857625,2.66453526e-15 5,2.66453526e-15 L8,2.66453526e-15"}></path>
                    <line x1={"7"} y1={"5"} x2={"15"} y2={"5"}></line>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        }
        { this.props.profile &&
          <div className={styles.Selector} onClick={this.props.profile.bind(this)}>
            <svg width={"18px"} height={"20px"} viewBox={"0 0 18 20"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
              <g strokeWidth={"1"} fill={"none"} fillRule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                <g transform={"translate(-290.000000, -1002.000000)"} strokeWidth={"2"}>
                  <g transform={"translate(291.000000, 1003.000000)"}>
                    <path d={"M16,18 L16,16 C16,13.790861 14.209139,12 12,12 L4,12 C1.790861,12 0,13.790861 0,16 L0,18"}></path>
                    <circle cx={"8"} cy={"4"} r={"4"}></circle>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        }
      </div>
    )
  }
}

export default Select;
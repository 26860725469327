import React, { Component } from 'react'
import styles from './index.module.scss'

import { darkmodeListener } from '../../theme';

type LoadingProps = {}
type LoadingState = {}

class Loading extends Component<LoadingProps, LoadingState> {
  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  render() {
    return (
      <div className={styles.LoadingContainer}>
        <div className={styles.Loading}>
          <div className={`${styles.LoadingElement} ${styles.LoadingOne}`}>
          </div>
          <div className={`${styles.LoadingElement} ${styles.LoadingTwo}`}>
          </div>
          <div className={`${styles.LoadingElement} ${styles.LoadingThree}`}>
          </div>
        </div>
      </div>
    )
  }
}

export default Loading;
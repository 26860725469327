import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import styles from './index.module.scss'

import makeEndpoint from '../../endpoints'
import MarketplaceView from '../../views/MarketplaceView'

type ProfileProps = {
  collection: string
  setStats?: (...args: any[]) => void
}
type ProfileState = {
  hasMore: boolean
  open: boolean
  status: boolean
  collection: boolean
  activeCollection?: any
  collections: any
  offset: number
  statusSelected: Array<string>
  statusPills: Array<string>
  data: Record<string, any>
  filter: string
  listings: any
  sold: boolean
  order?: 'oldest' | 'less' | 'greater' | 'rarity-ascending' | 'rarity-descending'
  filters?: 'recently' | 'sold' | 'oldest' | 'low' | 'high',
  userData?: any
  modal?: any
  statistics?: any
  selected: string
}

const fetchSize = 25;
class Profile extends Component<ProfileProps, ProfileState> {
  static defaultProps = {
    'collection': ''
  }

  state = {
    open: false,
    hasMore: false,
    status: false,
    collection: true,
    activeCollection: undefined,
    statusSelected: [],
    data: {},
    filter: '',
    statusPills: ["for sale", "featured", "new"],
    collections: [],
    listings: [],
    sold: false,
    userData: undefined,
    offset: 0,
    order: undefined,
    filters: undefined,
    modal: undefined,
    statistics: undefined,
    selected: 'PRICE: LOW TO HIGH'
  }

  componentDidMount() {
    try {
      const userData = localStorage.getItem('userData');
      if (userData) {
        this.setState({
          'userData': JSON.parse(userData)
        })
      }
    } catch {}

    this.fetchCollections();
    if (this.props.collection == undefined || this.props.collection == "") {
      this.setSelected("RECENTLY LISTED");
    } else if (this.props.collection == "SP497E7RX3233ATBS2AB9G4WTHB63X5PBSP5VGAQ.boom-nfts") {
      this.setState({
        activeCollection: this.props.collection
      }, () => {
        this.setSelected("RECENTLY LISTED");
      })
    } else {
      this.setState({
        order: 'less',
        filters: 'low'
      })
    }
  }

  findActiveCollection() {
    let collections: any = this.state.collections
    if (this.props.collection) {
      for(var idx = 0; idx < collections.length; idx++) {
        if (this.props.collection == collections[idx].contractName && collections[idx].verified) {
          return collections[idx];
        } else {
          const splits = this.props.collection.split('.')
          if (splits.length == 2) {
            if (splits[0] == collections[idx].contractAddress && splits[1] == collections[idx].contractName) {
              return collections[idx];
            }
          }
        }
      }
    }
  }

  fetchCollections() {
    fetch(makeEndpoint('/api/v1/marketplace/collections'))
      .then(res => res.json())
      .then((listings) => {
        const collections = listings.map((x: any, idx: number) => {
          return {
            'contractAddress': x.contractAddress,
            'contractName': x.contractName,
            'text': x.name,
            'verified': x.verified
          }
        }).filter((x: any) => { return x.contractName !== 'boom-nfts' && x.contractName !== 'btc-badgers' && x.contractName !== 'crashpunks-v1' });
        this.setState({
          'collections': collections
        }, () => {
          this.fetchCollectionImages()
          const activeCollection = this.findActiveCollection()
          if (activeCollection) {
            this.setState({
              'activeCollection': activeCollection
            }, () => {
               this.fetchMarketplace()
               this.fetchMarketplaceStatistics()
            })
          } else {
            // this is already called initially for ALL via setSelected in ComponentDidMount
            //this.fetchMarketplace()
            this.fetchMarketplaceStatistics()
          }
        })
      });
  }

  fetchMarketplaceStatistics() {
    const activeCollection: any = this.state.activeCollection
    if (activeCollection) {
      fetch(makeEndpoint('/api/v1/marketplace/statistics?address=' + activeCollection.contractAddress + '&name=' + activeCollection.contractName))
        .then(res => res.json())
        .then((statistics) => {
          if (this.props.setStats) {
            this.props.setStats(statistics)
          }
        });
    }
  }

  resetList() {
    this.setState({
      listings:[],
      sold: false,
      offset: 0
    }, () => {
      this.fetchMarketplace()
    })
  }

  resetSold() {
    this.setState({
      listings:[],
      sold: true,
      offset: 0
    }, () => {
      this.fetchMarketplace()
    })
  }

  setSelected(selected: string) {
    if (selected == 'PRICE: LOW TO HIGH') {
      this.setState({
        order: 'less',
        selected: 'PRICE: LOW TO HIGH'
      }, () => {
        this.resetList()
      })
    } else if (selected == 'PRICE: HIGH TO LOW') {
      this.setState({
        order: 'greater',
        selected: 'PRICE: HIGH TO LOW'
      }, () => {
        this.resetList()
      })
    } else if (selected == 'RECENTLY LISTED') {
      this.setState({
        order: undefined,
        selected: 'RECENTLY LISTED'
      }, () => {
        this.resetList()
      })
    } else if (selected == 'OLDEST') {
      this.setState({
        order: 'oldest',
        selected: 'OLDEST'
      }, () => {
        this.resetList()
      })
    } else if (selected == 'RECENTLY SOLD') {
      this.setState({
        order: undefined,
        selected: 'RECENTLY SOLD'
      }, () => {
        this.resetSold()
      })
    } else if (selected == 'RARITY: LOW TO HIGH') {
      this.setState({
        order: 'rarity-ascending',
        selected: 'RARITY: LOW TO HIGH'
      }, () => {
        this.resetList()
      })
    } else if (selected == 'RARITY: HIGH TO LOW') {
      this.setState({
        order: 'rarity-descending',
        selected: 'RARITY: HIGH TO LOW'
      }, () => {
        this.resetList()
      })
    }
  }

  fetchMarketplace() {
    // fetch(makeEndpoint('/api/marketplace?count=' + fetchSize + '&offset=0'))
    var params = '';
    params += 'count=' + fetchSize;
    params += '&offset=' + this.state.offset;

    const activeCollection: any = this.state.activeCollection
    if (activeCollection) {
      params += '&address=' + activeCollection.contractAddress;
      params += '&name=' + activeCollection.contractName;
    }

    if (this.state.order) {
      params += '&order=' + this.state.order  
    }

    if (this.state.sold) {
      params += '&sold=true';
    }

    fetch(makeEndpoint('/api/v1/marketplace?' + params))
      .then(res => res.json())
      .then((listings) => {
        var list: Array<any> = this.state.listings
        if (listings != undefined && listings.length && listings.length > 0) {
          const filtered = listings.filter(function(x: any) { 
              if (!activeCollection) return x.contractName !== 'boom-nfts' && x.contractName !== 'btc-badgers' && x.contractName !== 'crashpunks-v1';
              return true;
            });
          list.push(...filtered);
          this.setState({
            'hasMore': true,
            'listings': list
          })
          this.fetchData(listings)
        }
        if (listings && listings.length == 0) {
          this.setState({
            'hasMore': false
          })
        }
      });
  }

  fetchSold() {
    var params = '';
    params += 'limit=' + 10;
    params += '&offset=' + 0;

    const activeCollection: any = this.state.activeCollection
    if (activeCollection) {
      params += '&address=' + activeCollection.contractAddress;
      params += '&name=' + activeCollection.contractName;
    }

    fetch(makeEndpoint('/api/v1/transactions/sold?' + params))
      .then(res => res.json())
      .then((sold) => {
        if (sold.length && !sold.message) {
          this.setState({ sold: sold });
          this.fetchData(sold.map((x: any) => x.nftSent));
        } 
      });
  }

  fetchData(nfts: any) {
    for (var nft of nfts) {
      var params = '';
      params += 'address=' + nft.contractAddress;
      params += '&name=' + nft.contractName;
      params += '&id=' + nft.tokenID;
      const nftId = nft.contractAddress + '.' + nft.contractName + ':' + nft.tokenID;
      fetch(makeEndpoint('/api/nft?' + params))
        .then(res => res.json())
        .then((data) => {
          if (data.name) {
            const fullData: Record<string, any> = this.state.data;
            fullData[nftId] = data;
            this.setState({ 'data': fullData });
          }
        })
    }
  }

  fetchCollectionImages() {
    fetch(makeEndpoint('/api/v1/marketplace/collections'))
      .then(res => res.json())
      .then((collection_image) => {
        if (collection_image) {
          collection_image.map((x: any) => {
            var params = '';
            params += 'address=' + x.contractAddress;
            params += '&name=' + x.contractName;
            params += '&id=' + 1;
            fetch(makeEndpoint('/api/nft?' + params))
              .then(res => res.json())
              .then((data) => {
                var collections: any = this.state.collections
                for (var idx = 0; idx < collections.length; idx++) {
                  if (collections[idx].contractAddress == x.contractAddress && collections[idx].contractName == x.contractName) {
                    collections[idx].image = data.image || data.imageUrl;
                    if (!x.verified) {
                      collections[idx].text = data.collection;
                      if (!data.collection && data.name) {
                        const regex = / #(\d)+/i;
                        collections[idx].text = data.name.replace(regex, '').replace(' - hodl.btc');
                      } else if (data.collection) {
                        collections[idx].text = data.collection.replace(' - hodl.btc', '');
                      }
                    }
                  }
                }
                this.setState({
                  'collections': collections
                })
              })
          })
        }
    });
  }

  setCollection(x: any) {
    if(x) {
      if (x.verified) {
        window.location.href = '/marketplace/' + x.contractName
        return;
      } else {
        window.location.href = '/marketplace/' + x.contractAddress + "." + x.contractName
        return;
      }
    }
    window.location.href = '/marketplace/'
  }

  openCarret() {
    this.setState({
      open: !this.state.open
    });
  }

  statusOpen() {
    this.setState({
      status: !this.state.status
    });
  }

  collectionOpen() {
    this.setState({
      collection: !this.state.collection
    });
  }

  setActive(x?: any) {
    this.setState({
      activeCollection: x
    }, () => {
      this.setCollection(x)
    })
  }

  setStatusSelected(selected: Array<string>) {
    this.setState({
      statusSelected: selected
    })
  }

  setFilter(filter: string) {
    this.setState({
      filter: filter
    })
  }

  getBlocksNext(offset: number) {
    this.setState({
      'offset': offset
    }, () => {
      this.fetchMarketplace()
    })
  }

  setModal(modal: any) {
    this.setState({
      modal: modal
    })
  }

  modalClose() {
    this.setState({
      modal: undefined
    })
  }

  render() {
    return (
      <MarketplaceView
        open={this.state.open}
        openCarret={this.openCarret.bind(this)}
        status={this.state.status}
        statusOpen={this.statusOpen.bind(this)}
        statusPills={this.state.statusPills}
        statusSelected={this.state.statusSelected}
        setStatusSelected={this.setStatusSelected.bind(this)}
        selected={this.state.selected}
        setSelected={this.setSelected.bind(this)}
        active={this.props.collection}
        collection={this.state.collection}
        collectionOpen={this.collectionOpen.bind(this)}
        collections={this.state.collections}
        setActive={this.setActive.bind(this)}
        activeCollection={this.state.activeCollection}
        getBlocksNext={this.getBlocksNext.bind(this)}
        filter={this.state.filter}
        listings={this.state.listings}
        sold={this.state.sold}
        data={this.state.data}
        hasMore={this.state.hasMore}
        setFilter={this.setFilter.bind(this)}
        modal={this.state.modal}
        modalClose={this.modalClose.bind(this)}
        setModal={this.setModal.bind(this)}/>
    )
  }
}

export default Profile;

import React, { Component } from 'react'
import styles from './index.module.scss'

import CollectionElement from './CollectionElement'

import { darkmodeListener } from '../../theme';

type CollectionData = {
  id: string,
  text: string
  image: string
  verified: boolean
}

type CollectionsProps = {
  open: boolean
  onOpen: (...args: any[]) => void
  collections: Array<CollectionData>
  data: any
  setActive:(x?: any) => void
  active?: any
}
type CollectionsState = {
  filter: string
}

class Collections extends Component<CollectionsProps, CollectionsState> {
  state = {
    filter: ''
  }

  componentDidMount() {
    darkmodeListener(styles['theme-light'], styles['theme-dark']);
  }

  renderCarret() {
    if (this.props.open) {
      return `${styles.CollectionsCarret} ${styles.CollectionsCarretOpen}`
    } else {
      return `${styles.CollectionsCarret} ${styles.CollectionsCarretClose}`
    }
  }

  renderCollectionContianer() {
    if (this.props.open) {
      return `${styles.CollectionContainer} ${styles.CollectionOpen}`
    } else {
      return `${styles.CollectionContainer} ${styles.CollectionClosed}`
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      filter:  event.target.value
    })
  }

  collectionFilter() {
    if(this.state.filter == '') {
      return `${styles.CollectionFilterClear} ${styles.CollectionFilterInactive}`
    } else {
      return `${styles.CollectionFilterClear} ${styles.CollectionFilterActive}`
    }
  }

  clearFilter() {
    this.setState({
      filter: ''
    })
  }

  render() {
    return (
      <div className={styles.Collections}>
        <div className={styles.CollectionTopBar}>
          <div className={styles.CollectionsText}>
            COLLECTIONS
          </div>
          <div className={this.renderCarret()}
               onClick={this.props.onOpen.bind(this)}>
            <svg width={"8px"} height={"14px"} viewBox={"0 0 8 14"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
              <g stroke={"none"} strokeWidth={"1"} fill={"none"} fill-rule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                <g transform={"translate(-426.000000, -200.000000)"} stroke={"#999999"} strokeWidth={"2"}>
                  <g transform={"translate(20.000000, 165.000000)"}>
                    <g transform={"translate(410.000000, 42.000000) rotate(180.000000) translate(-410.000000, -42.000000) translate(407.000000, 36.000000)"}>
                      <polyline points={"0 12 6 6 0 0"}></polyline>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className={this.renderCollectionContianer()}>
        {this.props.open && 
          <div className={styles.CollectionPillContainer}>
            <input
              className={styles.CollectionInput}
              placeholder={"FILTER"}
              value={this.state.filter}
              onChange={this.handleChange.bind(this)}/>
            <div className={this.collectionFilter()}
                 onClick={this.clearFilter.bind(this)}>
              <svg width={"14px"} height={"14px"} viewBox={"0 0 14 14"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
                <g stroke={"none"} strokeWidth={"1"} fill={"none"} fillRule={"evenodd"} strokeLinecap={"round"} strokeLinejoin={"round"}>
                  <g transform={"translate(-329.000000, -602.000000)"} stroke={"#999999"} strokeWidth={"2"}>
                    <g transform={"translate(41.000000, 595.000000)"}>
                      <g transform={"translate(281.000000, 0.000000)"}>
                        <g transform={"translate(8.000000, 8.000000)"}>
                          <line x1={"12"} y1={"0"} x2={"0"} y2={"12"}></line>
                          <line x1={"0"} y1={"0"} x2={"12"} y2={"12"}></line>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className={styles.CollectionListed}>
            <CollectionElement
              text={'ALL'}
              image={undefined}
              onClick={() => { this.props.setActive(undefined) }}
              active={this.props.active == undefined}/>
            {this.props.collections.filter((x) => x && x.text && x.text.toLowerCase().includes(this.state.filter.toLowerCase())).sort((a: any, b: any) => a.text.localeCompare(b.text)).map((x: any) =>
              <CollectionElement
                text={x.text}
                image={x.image}
                verified={x.verified}
                onClick={() => { this.props.setActive(x) }}
                active={(this.props.active)?(this.props.active.contractAddress + '.' + this.props.active.contractName == x.contractAddress + '.' + x.contractName):false}/>
            )}
            </div>
          </div>
        }
        </div>
      </div>
    )
  }
}

export default  Collections
